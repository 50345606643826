/* eslint-disable no-console */
import { plainToInstance } from '@whispli/client/tenant/openapi/utils'
import type { Constructor } from '@whispli/types'

/**
 * Implements the VuexORM Model interface to facilitate migration from Vuex.
 * @deprecated Adapter supports migration of core-web legacy. VuexORM will be removed.
 */
export const withVuexORM = <T extends Constructor>(
  /** The super class to extend */
  SuperClass: T
) => class WithVuexORM extends SuperClass {
  static cache = Object.create(null)

  /**
   * @deprecated Avoid use of VuexORM. This compatibility layer allows use of existing VuexORM models to avoid
   * rewriting logic encapsulated there.
   * Eventually we'll have a complete Tenant API SDK and can remove VuexORM.
   */
  static insertOrUpdate<TData extends Record<string, any> = { id: string }>(input: { data: TData | TData[] }) {
    return {
      users: (Array.isArray(input.data) ? input.data : [ input.data ])
        .map(e => this.cache[e.id] = plainToInstance(this, { ...this.cache[e.id], ...e })),
    }
  }

  /**
   * @deprecated Avoid use of VuexORM. This compatibility layer allows use of existing VuexORM models to avoid
   * rewriting logic encapsulated there.
   * Eventually we'll have a complete Tenant API SDK and can remove VuexORM.
   */
  static update<TData extends Record<string, any> = { id: string }>(input: { data: TData | TData[] }) {
    return {
      users: (Array.isArray(input.data) ? input.data : [ input.data ])
        .map(e => this.cache[e.id] = plainToInstance(this, { ...this.cache[e.id], ...e })),
    }
  }

  /**
   * @deprecated Avoid use of VuexORM. This compatibility layer allows use of existing VuexORM models to avoid
   * rewriting logic encapsulated there.
   * Eventually we'll have a complete Tenant API SDK and can remove VuexORM.
   */
  static query() {
    return this
  }

  /**
   * @deprecated Avoid use of VuexORM. This compatibility layer allows use of existing VuexORM models to avoid
   * rewriting logic encapsulated there.
   * Eventually we'll have a complete Tenant API SDK and can remove VuexORM.
   */
  static with(relation: string) {
    return this
  }

  /**
   * @deprecated Avoid use of VuexORM. This compatibility layer allows use of existing VuexORM models to avoid
   * rewriting logic encapsulated there.
   * Eventually we'll have a complete Tenant API SDK and can remove VuexORM.
   */
  static find(id: string) {
    return this.cache[id] ?? null
  }
}
