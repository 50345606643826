export enum ERROR_ENUM {
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  TIMEOUT = 'TIMEOUT',
  UNPROCESSABLE = 'UNPROCESSABLE',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  CLIENT_CLOSED_REQUEST = 'CLIENT_CLOSED_REQUEST',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
}

export enum ERROR_STATUS_CODE_ENUM {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  TIMEOUT = 408,
  PAYLOAD_TOO_LARGE = 413,
  IDLE = 418,
  UNPROCESSABLE = 422,
  TOO_MANY_REQUESTS = 429,
  CLIENT_CLOSED_REQUEST = 499,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  /** @see https://developers.cloudflare.com/support/troubleshooting/cloudflare-errors/troubleshooting-cloudflare-5xx-errors/#error-522-connection-timed-out */
  CONNECTION_TIMED_OUT = 522,
  NO_CONTENT = 204
}

export enum ERROR_STATUS_CUSTOM_CODE_ENUM {
  UNKNOWN_ERROR = 1000,
  DRAFT_REPORT_NOT_VISIBLE_FOR_CASE_MANAGER = 1001,
  EMAIL_USED_BY_PULSE_USER = 1002,
  ACTIVE_WORKFLOW_REQUIRES_COMPLETION = 1003,
  UNAUTHORIZED_UNSUCCESSFUL_2FA = 1004,
  RESTRICTED_IP_ADDRESS = 1005,
  DELETED_REPORT = 1006,
  FILE_NOT_SCANNED = 1007,
  FILE_CONTAINS_VIRUS = 1008,
  TRANSLATIONS_BUILDING = 1009,
  TWO_FACTOR_AUTH_CODE_REQUIRED = 1403,
  EXTERNAL_USER_ACCESS_REVOKED = 1404,
  MAINTENANCE_MODE = 1503,
  FETCH_PAGE_DATA_FAILED = 1504,
}

export const ERRORS: ERROR_ENUM[] = Object.values(ERROR_ENUM)

export const ERROR_STATUS_CODES: ERROR_STATUS_CODE_ENUM[] =
  Object.values(ERROR_STATUS_CODE_ENUM) as ERROR_STATUS_CODE_ENUM[]

// These will need to be translated for the inbox if used in UI
export const ERROR_MESSAGES = {
  [ERROR_ENUM.INTERNAL_SERVER_ERROR]: 'Error',
  [ERROR_ENUM.UNAUTHORIZED]: 'Unauthorized',
  [ERROR_ENUM.FORBIDDEN]: 'Forbidden',
  [ERROR_ENUM.NOT_FOUND]: 'Not Found',
  [ERROR_ENUM.TIMEOUT]: 'Timed out',
  [ERROR_ENUM.TOO_MANY_REQUESTS]: 'Too Many Requests',
  [ERROR_ENUM.UNPROCESSABLE]: 'Unprocessable Entity',
}
