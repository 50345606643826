export interface CacheEntry {
  key: string
  etag: string
  data: Record<string, any>
  valid: boolean
}

export const X_APP_TIMESTAMP = 'x-app-timestamp' as const

const CACHE_KEY_PREFIX = 'http' as const

/**
 * @note Any endpoint included in this set will be requested with ?t={X_APP_TIMESTAMP}{locale} and cached in
 * disk-cache. Manual localStorage management not required.
 */
export const CACHED_BY_X_APP_TIMESTAMP = new Set([
  'action-types',
  'channel-statuses',
  'channel-types',
  'condition-types',
  'element-types',
  'landing-page-block-types',
  'notification-types',
  'timezones',
  'trigger-types',
  'workflow-statuses',
])

export const getXAppTimestamp = (): string | null =>
  localStorage.getItem(X_APP_TIMESTAMP)

/**
 * Set X-App-Timestamp (updated after successful Tenant API deployment) in localStorage.
 * @note This MUST BE called on every response
 *
 * @param {AxiosResponse} responseset
 * @return {void}
 */
export const setXAppTimestamp = (response: Pick<Response | { headers: Record<string, any> }, 'headers'>): void => {
  const current = response.headers instanceof Headers
    ? response.headers.get(X_APP_TIMESTAMP)
    : response.headers?.[X_APP_TIMESTAMP]
  const previous = getXAppTimestamp()

  if (current !== previous) {
    localStorage.setItem(X_APP_TIMESTAMP, current ?? '')
  }
}

export const clearCache = (): void => Object.keys(localStorage)
  .forEach((key) => key.startsWith(CACHE_KEY_PREFIX) && localStorage.removeItem(key))

/**
 * @return {string} The namespaced cache key
 */
const toKey = (...args: (string | undefined)[]): string =>
  `${CACHE_KEY_PREFIX}.${args.filter(Boolean).join('.')}`

/**
 * @return {string} The namespaced cache key from request URL
 */
export const getEntityName = (url: string, baseUrl: string, locale?: string): string =>
  toKey(url.replace(`${baseUrl}/`, ''), locale)

export const getLocalStorageItem = (key: string): CacheEntry =>
  JSON.parse(localStorage.getItem(key) ?? '')

export const setLocalStorageItem = (key: string, data: CacheEntry): void =>
  localStorage.setItem(key, JSON.stringify(data))

export * from './url'
