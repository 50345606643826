import { ERROR_STATUS_CODE_ENUM, ERROR_STATUS_CUSTOM_CODE_ENUM } from '@whispli/error/constants'
import memoizeWith from 'ramda/es/memoizeWith'
import mergeDeepRight from 'ramda/es/mergeDeepRight'
import { REQUEST_INIT_DEFAULTS } from '@whispli/client/constants'
import type { JSONResponse, RequestInitAny } from '@whispli/client/types'
import { MAINTENANCE_REDIRECT_URL } from '@whispli/types'

export const keyFn = (
  request: RequestInfo,
  config?: RequestInitAny,
): string => [
  (typeof request === 'object' ? request.url : request),
  config?.body,
  config?.locale,
]
  .filter(Boolean)
  .join('|')

/**
 * @note Implements fetch timeout
 * @see https://stackoverflow.com/posts/47250621/revisions
 * @see https://github.com/whatwg/fetch/issues/951
 */
export const fetchAny = async (
  request: RequestInfo,
  {
    locale,
    timeout,
    ...config
  }: RequestInitAny = {},
): Promise<Response> => {
  let id: number | null = null
  let init: RequestInitAny = { ...config }

  if (timeout && !config.signal) {
    const controller = new AbortController()
    init = {
      ...init,
      signal: controller.signal,
    }
    id = setTimeout(() => controller.abort(), timeout) as unknown as number
  }

  const response = await fetch(
    request,
    // @ts-ignore
    mergeDeepRight(REQUEST_INIT_DEFAULTS, init),
  )

  if (id) {
    clearTimeout(id)
  }

  if ((response?.status === ERROR_STATUS_CODE_ENUM.SERVICE_UNAVAILABLE)) {
    const data = await response.json()
    if (data?.code === ERROR_STATUS_CUSTOM_CODE_ENUM.MAINTENANCE_MODE) {
      window.location.replace(MAINTENANCE_REDIRECT_URL)
    }
  }

  return response
}
export const fetchAnyCached = memoizeWith(keyFn, fetchAny)

export const fetchJson = async <T extends Record<string, any>>(
  request: RequestInfo,
  init: RequestInitAny = {},
): Promise<T> => {
  const response = await fetchAny(request, init)
  return await response.json() as T
}
export const fetchJsonCached = memoizeWith(keyFn, fetchJson)

export const fetchJsonWithInfo = async <T extends Record<string, any>>(
  request: RequestInfo,
  init: RequestInitAny = {},
): Promise<JSONResponse<T>> => {
  let response
  try {
    response = await fetchAny(request, init)
    const json = await response.json()

    return {
      data: typeof json === 'object' && 'data' in json ? json.data : json,
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      ok: response.ok,
      response,
      json,
      request,
    }
  } catch (err) {
    return {
      data: null,
      status: response?.status || 500,
      statusText: response?.statusText || 'Unexpected error',
      headers: response?.headers,
      ok: false,
      json: { data: null },
      response: null,
      request,
    }
  }
}

export const fetchJsonWithInfoCached = memoizeWith(keyFn, fetchJsonWithInfo)

export const fetchJsonWithInfoCachedExpirable = (
  evictIn: number = 500,
  cacheKeyFn: ((...args: any[]) => string) = keyFn,
): ((request: RequestInfo, init?: RequestInit) => Promise<JSONResponse<any>>) => {
  const cache = Object.create(null)

  return async <T extends Record<string, any>>(
    request: RequestInfo,
    init?: RequestInit,
  ): Promise<JSONResponse<T>> => {
    const cacheKey = cacheKeyFn(request, init)
    const existingResult = cache[cacheKey]
    if (existingResult) {
      return existingResult
    }
    const result = fetchJsonWithInfo(request, init)
    cache[cacheKey] = result

    setTimeout(() => {
      delete cache[cacheKey]
    }, evictIn)

    return result as Promise<JSONResponse<T>>
  }
}

export const fetchJsonWithInfoCachedExpirableShort
  = fetchJsonWithInfoCachedExpirable(1_000) as <T extends Record<string, any>>(
    request: RequestInfo,
    init?: RequestInit
  ) => Promise<JSONResponse<T>>

export const fetchJsonWithInfoCachedExpirableLong
  = fetchJsonWithInfoCachedExpirable(30_000) as <T extends Record<string, any>>(
    request: RequestInfo,
    init?: RequestInit
  ) => Promise<JSONResponse<T>>

export const mergeSearchParams = (
  url: string = '',
  params: ConstructorParameters<typeof URLSearchParams>[0] = ''
) => {
  const _u = new URL(url)
  const baseUrl = _u.origin + _u.pathname
  const reducer = (a, [ k, v ]) => {
    if (v && typeof v === 'object') {
      return Object.entries(v).reduce((b, [ k2, v2 ]) => reducer(b, [ `${k}[${k2}]`, v2 ]), a)
    }

    if (v !== undefined) {
      a[k] = v
    }

    return a
  }

  const combinedParams = new URLSearchParams({
    ...Object.fromEntries(new URLSearchParams(_u.searchParams)),
    ...Object.fromEntries(new URLSearchParams(Object.entries(params).reduce(reducer, {}))),
  }).toString() ?? ''

  return `${baseUrl}${combinedParams.length ? `?${combinedParams}` : ''}`
}

// @see https://stackoverflow.com/questions/15638104/regex-for-matching-multiple-forward-slashes-in-url/15638147#15638147
const MULTI_FORWARD_SLASH_REGEX = /([^:]\/)\/+/g

export const getAbsoluteURL = (pathOrURL: string | URL, baseURL?: string | URL) => {
  try {
    return new URL(pathOrURL)
  } catch (err) {
    // Retry with baseURL
    return new URL(
      `${baseURL}/${pathOrURL}`
        .replace(MULTI_FORWARD_SLASH_REGEX, '$1')
    )
  }
}

export const ACCEPT_LANGUAGE = 'Accept-Language' as const

export const getLocale = (init: RequestInitAny): string => {
  const locale = init.headers instanceof Headers
    ? init.headers.get(ACCEPT_LANGUAGE)
    : init.headers?.[ACCEPT_LANGUAGE]

  if ((locale && locale !== '*') || typeof window === 'undefined') {
    return locale || ''
  }

  return (new URLSearchParams(window.location.search)).get('locale') ?? ''
}

// @note - Chrome can add form boundary to Content-Type header
export const isMultipartFormData = (headers: Headers): boolean =>
  (headers.get('Content-Type') ?? '').startsWith('multipart/form-data')
