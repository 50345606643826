import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import { InformantNotificationSettings } from './../../overrides/model/InformantNotificationSettings.js';
import { DashboardPreference } from './../../overrides/model/DashboardPreference.js';
import { Transform } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __Informant extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => Object)
  public readonly email!: string | null;
  @Expose()
  @Type(() => Object)
  public readonly username!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use accessCode
   */
  @Expose()
  @Type(() => Object)
  private readonly access_code!: string | null;

  public get accessCode() {
    return this.access_code
  }

  @Expose()
  @Type(() => String)
  public readonly timezone!: string;
  @Expose()
  @Type(() => String)
  public readonly locale!: string;
  /**
   * @deprecated For backwards-compatibility only. Use secondaryLocales
   */
  @Expose()
  @Type(() => String)
  private readonly secondary_locales!: string;

  public get secondaryLocales() {
    return this.secondary_locales
  }

  @Expose()
  @Type(() => Boolean)
  public readonly me!: boolean;
  /**
   * @deprecated For backwards-compatibility only. Use isGuest
   */
  @Expose()
  @Type(() => Boolean)
  private readonly is_guest!: boolean;

  public get isGuest() {
    return this.is_guest
  }

  /**
   * @deprecated For backwards-compatibility only. Use tutorialDismissed
   */
  @Expose()
  @Type(() => Boolean)
  private readonly tutorial_dismissed!: boolean;

  public get tutorialDismissed() {
    return this.tutorial_dismissed
  }

  /**
   * @deprecated For backwards-compatibility only. Use appDownloaded
   */
  @Expose()
  @Type(() => Boolean)
  private readonly app_downloaded!: boolean;

  public get appDownloaded() {
    return this.app_downloaded
  }

  /**
   * @deprecated For backwards-compatibility only. Use sendMessageOnEnter
   */
  @Expose()
  @Type(() => Boolean)
  private readonly send_message_on_enter!: boolean;

  public get sendMessageOnEnter() {
    return this.send_message_on_enter
  }

  /**
   * @deprecated For backwards-compatibility only. Use notificationSettings
   */
  @Type(() => InformantNotificationSettings)
  @Expose()
  private readonly notification_settings!: InformantNotificationSettings | null;

  public get notificationSettings() {
    return this.notification_settings
  }

  /**
   * @deprecated For backwards-compatibility only. Use guestConvertedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly guest_converted_at!: string | null;

  public get guestConvertedAt() {
    return this.guest_converted_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use dashboardPreferences
   */
  @Type(() => DashboardPreference)
  @Expose()
  private readonly dashboard_preferences?: DashboardPreference | null;

  public get dashboardPreferences() {
    return this.dashboard_preferences
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use deletedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly deleted_at!: string | null;

  public get deletedAt() {
    return this.deleted_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use isArchived
   * @default false
   */
  @Expose()
  @Type(() => Boolean)
  @Transform(({ obj }) => !!obj.deleted_at)
  private readonly is_archived!: boolean;

  public get isArchived() {
    return this.is_archived
  }
}

interface OptionalAlias {
  dashboardPreferences?: import("/builds/whispli/tenant-api-v2/whispli/packages/client/src/tenant/openapi/client/model/DashboardPreference").DashboardPreference | null | undefined;
}
export type Informant = Omit<__Informant, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'access_code' | 'secondary_locales' | 'is_guest' | 'tutorial_dismissed' | 'app_downloaded' | 'send_message_on_enter' | 'notification_settings' | 'guest_converted_at' | 'dashboard_preferences' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_archived' | 'dashboardPreferences'> & OptionalAlias
export const Informant = __Informant as any as Constructor<Informant>
