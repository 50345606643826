import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import type { MediaSimpleScanStatusEnum } from './../constants.js';
import type { Constructor } from '@whispli/types';

export class __MediaSimple extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  /**
   * @deprecated For backwards-compatibility only. Use collectionName
   */
  @Expose()
  @Type(() => String)
  private readonly collection_name!: string;

  public get collectionName() {
    return this.collection_name
  }

  @Expose()
  @Type(() => Object)
  public readonly uuid!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use fileName
   */
  @Expose()
  @Type(() => String)
  private readonly file_name!: string;

  public get fileName() {
    return this.file_name
  }

  @Expose()
  @Type(() => String)
  public readonly url!: string;
  /**
   * @deprecated For backwards-compatibility only. Use mimeType
   */
  @Expose()
  @Type(() => String)
  private readonly mime_type!: string;

  public get mimeType() {
    return this.mime_type
  }

  @Expose()
  @Type(() => String)
  public readonly disk!: string;
  /**
   * @deprecated For backwards-compatibility only. Use conversionsDisk
   */
  @Expose()
  @Type(() => Object)
  private readonly conversions_disk!: string | null;

  public get conversionsDisk() {
    return this.conversions_disk
  }

  /**
   * @deprecated For backwards-compatibility only. Use orderColumn
   */
  @Expose()
  @Type(() => Number)
  private readonly order_column!: number;

  public get orderColumn() {
    return this.order_column
  }

  @Expose()
  @Type(() => Object)
  public readonly size!: number | null;
  /**
   * @deprecated For backwards-compatibility only. Use scanStatus
   */
  @Type(() => String)
  @Expose()
  private readonly scan_status?: MediaSimpleScanStatusEnum | null;

  public get scanStatus() {
    return this.scan_status
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdByType
   */
  @Expose()
  @Type(() => Object)
  private readonly created_by_type!: string | null;

  public get createdByType() {
    return this.created_by_type
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdById
   */
  @Expose()
  @Type(() => Object)
  private readonly created_by_id!: string | null;

  public get createdById() {
    return this.created_by_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }
}

interface OptionalAlias {
  scanStatus?: any;
}
export type MediaSimple = Omit<__MediaSimple, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'collection_name' | 'file_name' | 'mime_type' | 'conversions_disk' | 'order_column' | 'scan_status' | 'created_by_type' | 'created_by_id' | 'created_at' | 'updated_at' | 'scanStatus'> & OptionalAlias
export const MediaSimple = __MediaSimple as any as Constructor<MediaSimple>
