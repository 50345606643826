import type { RequiredAbilities } from '@whispli/client/tenant/auth/types'

export enum USER_ROLE_ENUM {
  ADMIN = 'admin',
  ADMIN_RESTRICTED = 'restricted-admin',
  BUSINESS_ADMIN = 'business-administrator',
  CONFIGURATOR = 'configurator',
  MEMBER = 'member',
  EXTERNAL_USER = 'guest',
}

export enum MEMBERSHIPABLE_TYPE_ENUM {
  USERS = 'user',
  USER_GROUPS = 'user-group'
}

export const INDEX_PERMISSIONS: RequiredAbilities = []

export const ADMIN_INDEX_PERMISSIONS: RequiredAbilities = []

export const ADMIN_SETTINGS_PERMISSIONS: RequiredAbilities = []

export const ADMIN_READ_ANALYTICS_PERMISSIONS: RequiredAbilities = [
  {
    name: 'read-analytics',
    entityType: '*',
  },
]

export const ADMIN_MANAGE_ANALYTICS_PERMISSIONS: RequiredAbilities = [
  {
    name: 'manage-analytics',
    entityType: '*',
  },
]

export const ADMIN_EXECUTE_QUERY_ANALYTICS_PERMISSIONS: RequiredAbilities = [
  {
    name: 'execute-query-analytics',
    entityType: '*',
  },
]

export const ADMIN_ANALYTICS_CHARTS_INDEX_PERMISSIONS = ADMIN_MANAGE_ANALYTICS_PERMISSIONS

export const ADMIN_ANALYTICS_CHART_PERMISSIONS = ADMIN_MANAGE_ANALYTICS_PERMISSIONS

export const ADMIN_ANALYTICS_CHARTS_EDIT_PERMISSIONS = ADMIN_MANAGE_ANALYTICS_PERMISSIONS

export const ADMIN_ANALYTICS_DASHBOARDS_EDIT_PERMISSIONS = ADMIN_MANAGE_ANALYTICS_PERMISSIONS

export const ADMIN_ANALYTICS_DASHBOARDS_INDEX_PERMISSIONS = ADMIN_READ_ANALYTICS_PERMISSIONS

export const ADMIN_ANALYTICS_DASHBOARD_PERMISSIONS = ADMIN_READ_ANALYTICS_PERMISSIONS

export const ADMIN_AUTOMATIONS_AUTOMATION_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'automation',
  },
]

export const ADMIN_AUTOMATIONS_INDEX_PERMISSIONS = ADMIN_AUTOMATIONS_AUTOMATION_PERMISSIONS

export const ADMIN_CHANNELS_CHANNEL_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'channel',
  },
]

export const ADMIN_CHANNELS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'channel',
  },
]

export const ADMIN_HOME_INDEX_PERMISSIONS: RequiredAbilities = []

export const ADMIN_DELETED_PROJECTS_DELETED_PROJECT_PERMISSIONS: RequiredAbilities = [
  {
    name: 'access-deleted',
    entityType: 'project',
  },
]

export const ADMIN_DELETED_PROJECTS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: 'access-deleted',
    entityType: 'project',
  },
]

/** @deprecated Use ADMIN_DELETED_REPORTS_DELETED_REPORT_PERMISSIONS */
export const ADMIN_DELETED_WHISPERS_DELETED_WHISPER_PERMISSIONS: RequiredAbilities = [
  {
    name: 'access-deleted',
    entityType: 'whisper',
  },
]

export const ADMIN_DELETED_REPORTS_DELETED_REPORT_PERMISSIONS = ADMIN_DELETED_WHISPERS_DELETED_WHISPER_PERMISSIONS

export const ADMIN_DELETED_WHISPERS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: 'access-deleted',
    entityType: 'whisper',
  },
]

export const ADMIN_LOCKED_WHISPER_PERMISSIONS: RequiredAbilities = [
  {
    name: 'manage-locked-whisper',
    entityType: 'whisper',
  },
]

export const ADMIN_NOTIFICATIONS_INDEX_PERMISSIONS: RequiredAbilities = []

export const ADMIN_PAGES_PAGE_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'landing-page',
  },
]

export const ADMIN_PAGES_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'landing-page',
  },
]

export const ADMIN_PARTICIPANTS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: 'access',
    entityType: 'participant',
  },
]

export const ADMIN_PROJECTS_PROJECT_PERMISSIONS: RequiredAbilities = [
  {
    name: 'access',
    entityType: 'project',
  },
]

export const ADMIN_PROJECTS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: 'access',
    entityType: 'project',
  },
  {
    name: 'create',
    entityType: 'project',
  },
]

export const ADMIN_SETTINGS_INDEX_PERMISSIONS: RequiredAbilities = []

export const ADMIN_SETTINGS_AUDITS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'audit',
  },
]

export const ADMIN_SETTINGS_DATA_EXPORT_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: 'export-data',
    entityType: '*',
  },
]

export const ADMIN_SETTINGS_LANGUAGES_INDEX_PERMISSIONS: RequiredAbilities = []

export const ADMIN_SETTINGS_NOTIFICATIONS_INDEX_PERMISSIONS: RequiredAbilities = []

export const ADMIN_SETTINGS_ORGANIZATION_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'organization-setting',
  },
]

export const ADMIN_SETTINGS_PROFILE_INDEX_PERMISSIONS: RequiredAbilities = []

export const ADMIN_SETTINGS_RETENTION_POLICIES_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'retention-policy',
  },
]

export const ADMIN_SETTINGS_STATUSES_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'whisper-status',
  },
  {
    name: '*',
    entityType: 'project-status',
  },
]

export const ADMIN_SETTINGS_USER_GROUPS_USER_GROUP_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'user-group',
  },
]

export const ADMIN_SETTINGS_USER_GROUPS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'user-group',
  },
]

export const ADMIN_SETTINGS_USERS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'invitation',
  },
]

export const ADMIN_USER_GROUPS_USER_GROUP_PERMISSIONS: RequiredAbilities = []

export const ADMIN_USERS_USER_PERMISSIONS: RequiredAbilities = []

export const ACCESS_WHISPER = [
  {
    name: 'access',
    entityType: 'whisper',
  },
]

export const ADMIN_WHISPERS_WHISPER_PERMISSIONS: RequiredAbilities = ACCESS_WHISPER

export const ADMIN_WHISPERS_INDEX_PERMISSIONS: RequiredAbilities = ACCESS_WHISPER

export const ADMIN_WORKFLOWS_WORKFLOW_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'workflow',
  },
]

export const ADMIN_WORKFLOWS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'workflow',
  },
]

export const ADMIN_SETTINGS_TAGS_INDEX_PERMISSIONS: RequiredAbilities = [
  {
    name: '*',
    entityType: 'tag',
  },
]

export const PERMISSIONS: Record<string, RequiredAbilities> = {
  INDEX_PERMISSIONS,
  ADMIN_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_PERMISSIONS,
  ADMIN_READ_ANALYTICS_PERMISSIONS,
  ADMIN_MANAGE_ANALYTICS_PERMISSIONS,
  ADMIN_EXECUTE_QUERY_ANALYTICS_PERMISSIONS,
  ADMIN_ANALYTICS_CHARTS_INDEX_PERMISSIONS,
  ADMIN_ANALYTICS_CHART_PERMISSIONS,
  ADMIN_ANALYTICS_CHARTS_EDIT_PERMISSIONS,
  ADMIN_ANALYTICS_DASHBOARDS_EDIT_PERMISSIONS,
  ADMIN_ANALYTICS_DASHBOARDS_INDEX_PERMISSIONS,
  ADMIN_ANALYTICS_DASHBOARD_PERMISSIONS,
  ADMIN_AUTOMATIONS_AUTOMATION_PERMISSIONS,
  ADMIN_AUTOMATIONS_INDEX_PERMISSIONS,
  ADMIN_CHANNELS_CHANNEL_PERMISSIONS,
  ADMIN_CHANNELS_INDEX_PERMISSIONS,
  ADMIN_HOME_INDEX_PERMISSIONS,
  ADMIN_DELETED_PROJECTS_DELETED_PROJECT_PERMISSIONS,
  ADMIN_DELETED_PROJECTS_INDEX_PERMISSIONS,
  ADMIN_DELETED_WHISPERS_DELETED_WHISPER_PERMISSIONS,
  ADMIN_DELETED_WHISPERS_INDEX_PERMISSIONS,
  ADMIN_LOCKED_WHISPER_PERMISSIONS,
  ADMIN_NOTIFICATIONS_INDEX_PERMISSIONS,
  ADMIN_PAGES_PAGE_PERMISSIONS,
  ADMIN_PAGES_INDEX_PERMISSIONS,
  ADMIN_PROJECTS_PROJECT_PERMISSIONS,
  ADMIN_PROJECTS_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_AUDITS_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_DATA_EXPORT_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_LANGUAGES_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_NOTIFICATIONS_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_ORGANIZATION_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_PROFILE_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_RETENTION_POLICIES_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_STATUSES_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_USER_GROUPS_USER_GROUP_PERMISSIONS,
  ADMIN_SETTINGS_USER_GROUPS_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_USERS_INDEX_PERMISSIONS,
  ADMIN_USER_GROUPS_USER_GROUP_PERMISSIONS,
  ADMIN_USERS_USER_PERMISSIONS,
  ADMIN_WHISPERS_WHISPER_PERMISSIONS,
  ADMIN_WHISPERS_INDEX_PERMISSIONS,
  ADMIN_WORKFLOWS_WORKFLOW_PERMISSIONS,
  ADMIN_WORKFLOWS_INDEX_PERMISSIONS,
  ADMIN_SETTINGS_TAGS_INDEX_PERMISSIONS,
}

export enum AUTH_STRATEGY_ENUM {
  CASE_MANAGER = 'CaseManager',
  INFORMANT = 'Informant',
  GUEST = 'Guest',
  UNKNOWN = 'Unknown',
}

export const AUTH_STRATEGIES = Object.values(AUTH_STRATEGY_ENUM)
