import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __InformantNotificationSettings extends ResponseBody {
  /**
   * @deprecated For backwards-compatibility only. Use messageCreated
   */
  @Expose()
  @Type(() => Boolean)
  private readonly message_created!: boolean;

  public get messageCreated() {
    return this.message_created
  }

  /**
   * @deprecated For backwards-compatibility only. Use reportUpdated
   */
  @Expose()
  @Type(() => Boolean)
  private readonly whisper_updated!: boolean;

  public get reportUpdated() {
    return this.whisper_updated
  }
}
export type InformantNotificationSettings = Omit<__InformantNotificationSettings, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'message_created' | 'whisper_updated'>
export const InformantNotificationSettings = __InformantNotificationSettings as any as Constructor<InformantNotificationSettings>
