import type {QueryClientConfig} from '@tanstack/query-core'
import {QueryCache, QueryClient,} from '@tanstack/vue-query'
import {onError, shouldRetry} from '../../overrides/error'

const queryCache = new QueryCache({ onError })

const queryClientConfig: QueryClientConfig = {
  /** @note There is no other way to provide custom error handler. */
  queryCache,
  defaultOptions: {
    queries: {
      /**
       * @note Do not retry failed queries on mount.
       * Seems to be a defficiency in vue-query; the request is never aborted.
       */
      retryOnMount: false,

      onError,

      /**
       * @see https://tanstack.com/query/v4/docs/react/guides/testing#turn-off-retries
       */
      retry: (failureCount, error) => {
        if (import.meta.env.MODE !== 'test') {
          return false
        }

        return shouldRetry(failureCount, error)
      },
    },
  },
}

export const queryClient = (globalThis.__queryClient__ ||= new QueryClient(queryClientConfig))
