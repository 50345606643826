import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __DashboardPreference extends ResponseBody {
  /**
   * @deprecated For backwards-compatibility only. Use dashboardId
   */
  @Expose()
  @Type(() => Object)
  private readonly dashboard_id!: string | null;

  public get dashboardId() {
    return this.dashboard_id
  }
}
export type DashboardPreference = Omit<__DashboardPreference, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'dashboard_id'>
export const DashboardPreference = __DashboardPreference as any as Constructor<DashboardPreference>
