import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __LanguagePreference extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly locale!: string;
  /**
   * @deprecated For backwards-compatibility only. Use translationLocale
   */
  @Expose()
  @Type(() => String)
  private readonly translation_locale!: string;

  public get translationLocale() {
    return this.translation_locale
  }

  /**
   * @deprecated For backwards-compatibility only. Use ignoredTranslations
   */
  @Expose()
  @Type(() => String)
  private readonly ignored_translations!: string;

  public get ignoredTranslations() {
    return this.ignored_translations
  }
}
export type LanguagePreference = Omit<__LanguagePreference, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'translation_locale' | 'ignored_translations'>
export const LanguagePreference = __LanguagePreference as any as Constructor<LanguagePreference>
