import type { Route, RouteWithParams } from '@whispli/client/types'
import { toPath } from '@whispli/client/tenant'
import type { QueryParameters } from '@whispli/client/tenant/types'

export {
  deleteInformantSystemMessagesMessage as dismissNotification,
  getInformantChannels as channels,
  getInformantChannelsChannel as channel,
  getInformantMediasMedia as file,
  getInformantReportsReportRedactions as redactions,
  getInformantReportsReportSteps as steps,
  getInformantReportsReportStepsStepAnswers as answers,
  patchConversationsConversation as patchConversation,
  postInformantAnswerMedias as uploadFile,
  postInformantReportsReportStepsStepSubmissions as addSubmissionToStep,
} from '@whispli/client/tenant/openapi/client/paths'

/**
 * @see App\Http\Controllers\Whisperer\AuthController@login
 */
export const login: Route = {
  method: 'POST',
  path: toPath('/whisperer/auth/login'),
}

/**
 * @see App\Http\Controllers\Whisperer\AuthController@logout
 */
export const logout: Route = {
  method: 'POST',
  path: toPath('/whisperer/auth/logout'),
}

/**
 * @see App\Http\Controllers\Whisperer\AuthController@refresh
 */
export const refreshToken: Route = {
  method: 'POST',
  path: toPath('/whisperer/auth/refresh'),
}

/**
 * @see App\Http\Controllers\Whisperer\AuthController@verify
 */
export const verifyToken: Route = {
  method: 'GET',
  path: toPath('/whisperer/auth/verify'),
}

/**
 * @see App\Http\Controllers\Whisperer\AuthController@me
 */
export const viewer: Route = {
  method: 'GET',
  path: toPath('/whisperer/me'),
}

/**
 * @see App\Http\Controllers\Whisperer\WhispererController@store
 */
export const createInformant: Route = {
  method: 'POST',
  path: toPath('/whisperer/whisperers'),
}

/**
 * @see App\Http\Controllers\Whisperer\WhispererController@update
 */
export const updateInformant: RouteWithParams = {
  method: 'PATCH',
  path: (id: string) => toPath(`/whisperer/whisperers/${id}`),
}

export const deleteEmail: RouteWithParams = {
  method: 'DELETE',
  path: (id: string) => toPath(`/whisperer/whisperers/${id}/email`),
}

export const updateEmail: RouteWithParams = {
  method: 'PATCH',
  path: (id: string) => toPath(`/whisperer/whisperers/${id}/email`),
}

export const updateNotificationSettings: RouteWithParams = { ...updateInformant }

export const updatePassword: RouteWithParams = { ...updateInformant }

export const updatePrimaryLocale: RouteWithParams = { ...updateInformant }

export const updateSecondaryLocales: RouteWithParams = { ...updateInformant }

export const updateSubmitOnEnter: RouteWithParams = { ...updateInformant }

/**
 * @see App\Http\Controllers\Whisperer\SelectableUserController@index
 */
export const selectableUsers: RouteWithParams = {
  method: 'GET',
  path: (options: QueryParameters) => toPath('/whisperer/selectable-users', options),
}

/**
 * @see App\Http\Controllers\Whisperer\Auth\ForgotPasswordController@store
 */
export const forgotPassword: Route = {
  method: 'POST',
  path: toPath('/whisperer/forgot-password'),
}

/**
 * @see App\Http\Controllers\Whisperer\Auth\ResetPasswordController@show
 */
export const canResetPassword: RouteWithParams = {
  method: 'GET',
  path: (id: string, token: string): string => toPath(`/whisperer/reset-password?id=${id}&token=${token}`),
}

/**
 * @see App\Http\Controllers\Whisperer\Auth\ResetPasswordController@store
 */
export const resetPassword: Route = {
  method: 'POST',
  path: toPath('/whisperer/reset-password'),
}

/**
 * @see App\Http\Controllers\Whisperer\WhispererLinkController@show
 */
export const verifyInformantLink: RouteWithParams = {
  method: 'GET',
  path: (code: string): string => toPath(`/whisperer/whisperer-links/${code}`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhispererLinkController@store
 */
export const updateInformantLink: RouteWithParams = {
  method: 'POST',
  path: (): string => toPath('/whisperer/whisperer-links'),
}

/**
 * @see App\Http\Controllers\Whisperer\AuthTranslationController@store
 */
export const whispererAuthTranslations: Route = {
  method: 'POST',
  path: toPath('/whisperer/auth/translations'),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperController@index
 */
export const reports: RouteWithParams = {
  method: 'GET',
  path: (options: QueryParameters) => toPath('/v2/whisperer/whispers', options),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperController@show
 */
export const report: RouteWithParams = {
  method: 'GET',
  path: (reportId: string) => toPath(`/v2/whisperer/whispers/${reportId}`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperVersionedWhisperController@index
 * @see App\Http\Controllers\WhisperVersionedWhisperController@index
 */
export const reportVersions: RouteWithParams = {
  method: 'GET',
  path: (reportId: string): string => toPath(`/whisperer/whispers/${reportId}/versioned-whispers`),
}

/**
 */
export const conversationFiles: RouteWithParams = {
  method: 'GET',
  path: (reportId: string) => toPath(`/v2/whisperer/whispers/${reportId}/medias`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperFileController@show
 */
export const reportFile: RouteWithParams = {
  method: 'GET',
  path: (reportId: string, fileId: string): string => toPath(`/whisperer/whispers/${reportId}/files/${fileId}`),
}

/**
 * @see App\Http\Controllers\Whisperer\ElementFileController@show
 */
export const elementFile: RouteWithParams = {
  method: 'GET',
  path: (elementId: string, fileId: string): string => toPath(`/whisperer/elements/${elementId}/files/${fileId}`),
}

/**
 * @see App\Http\Controllers\Whisperer\FileMetaController@show
 */
export const fileMeta: RouteWithParams = {
  method: 'GET',
  path: (fileId: string) => toPath(`whisperer/files/${fileId}/meta`),
}

/**
 * @see App\Http\Controllers\Whisperer\DraftedWhisperController@store
 */
export const createDraftReport: Route = {
  method: 'POST',
  path: toPath('/whisperer/drafted-whispers'),
}

/**
 * @see App\Http\Controllers\Whisperer\SubmittedWhisperController@store
 */
export const createReport: Route = {
  method: 'POST',
  path: toPath('/whisperer/submitted-whispers'),
}

export const downloadReport: RouteWithParams = {
  method: 'POST',
  path: (id: string) => toPath(`/v2/whisperer/whispers/${id}/downloads`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperController@update
 */
export const updateReport: RouteWithParams = {
  method: 'PATCH',
  path: (reportId: string): string => toPath(`/whisperer/whispers/${reportId}`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperController@destroy
 */
export const deleteReport: RouteWithParams = {
  method: 'DELETE',
  path: (reportId: string): string => toPath(`/whisperer/whispers/${reportId}`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperFileController@store
 */
export const addFileToReport: RouteWithParams = {
  method: 'POST',
  path: (reportId: string) => toPath(`/whisperer/whispers/${reportId}/files`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperFileController@destroy
 */
export const deleteFileFromReport: RouteWithParams = {
  method: 'DELETE',
  path: (reportId: string, fileId: string) => toPath(`/whisperer/whispers/${reportId}/files/${fileId}`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperConversationController@index
 */
export const conversations: RouteWithParams = {
  method: 'GET',
  path: (reportId: string): string =>
    toPath(`/whisperer/whispers/${reportId}/conversations`),
}

/**
 * @see App\Http\Controllers\Whisperer\ConversationMessageController@index
 */
export const messages: RouteWithParams = {
  method: 'GET',
  path: (conversationId: string, options: QueryParameters) =>
    toPath(`/v2/whisperer/conversations/${conversationId}/messages`, options),
}

/**
 * @see App\Http\Controllers\Whisperer\ConversationMessageController@store
 */
export const addMessageToConversation: RouteWithParams = {
  method: 'POST',
  path: (conversationId: string): string =>
    toPath(`/whisperer/conversations/${conversationId}/messages`),
}

/**
 * @see App\Http\Controllers\Whisperer\ConversationDeletedMessageController@store
 */
export const deleteMessageFromConversation: RouteWithParams = {
  method: 'POST',
  path: (conversationId: string): string =>
    toPath(`/whisperer/conversations/${conversationId}/deleted-messages`),
}

export const submissions: RouteWithParams = {
  method: 'GET',
  path: (reportId: string, options): string => toPath(`/whisperer/submissibles/${reportId}/submissions`, options),
}

/**
 * @todo Tenant API Not implemented
 */
export const submission: RouteWithParams = {
  method: 'GET',
  path: (reportId: string, submissionId: string): string =>
    toPath(`/whisperer/whispers/${reportId}/submissions/${submissionId}`),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperSearchController@index
 */
export const search: RouteWithParams = {
  method: 'GET',
  path: (
    reportId: string,
    _term: string,
  ) => toPath(`/whisperer/whispers/${reportId}/search?_term=${_term}`),
}

export const crossPlatformLink: Route = {
  method: 'GET',
  path: toPath('/whisperer/mobile-deep-links'),
}

/**
 * @see App\Http\Controllers\Whisperer\WhisperQrCodeController@show
 */
export const reportCrossPlatformLink: RouteWithParams = {
  method: 'GET',
  path: (reportId: string) => toPath(`/whisperer/whispers/${reportId}/qr-codes`),
}
