export const DEFAULT_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

export const REQUEST_INIT_DEFAULTS: Partial<RequestInit> = {
  credentials: 'include',
  headers: DEFAULT_HEADERS,
  method: 'GET',
  mode: 'same-origin',
}
