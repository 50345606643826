import { ReportSimple as Base } from '@whispli/client/tenant/openapi/client/model/ReportSimple'

export class ReportSimple extends Base {
  get fullName() {
    return [ this.anonymousFirstName, this.anonymousLastName ]
      .filter(Boolean)
      .join(' ')
  }

  get label() {
    return this.reportLabel
  }
}
