import type { ResponseBody, EmptyResponseBody } from './types'
import { TokenError } from '@whispli/error'
import { getToken } from '@whispli/auth/jwt'
import type Cookies from 'js-cookie'
import { getAbsoluteURL } from '@whispli/client/utils'
import { ResponseError } from '@whispli/client/tenant/openapi/error'

/** Base REST Tenant API Client */
export class RESTClient {
  constructor(public baseURL: URL | string) {
    this.baseURL = baseURL instanceof URL || baseURL.startsWith('/')
      ? baseURL
      : new URL(baseURL)
  }

  async fetch<T = any>(
    pathname: string,
    init: Pick<RequestInit, 'body' | 'method'>,
  ): Promise<T | null> {
    const response = await fetch(
      this.url(pathname),
      {
        ...DEFAULT_INIT,
        ...init,
      },
    )

    const status = response?.status
    if (status === 204) {
      return null
    } else if (status >= 200 && status < 400) {
      try {
        return await response.json()
      } catch (err) {
        // Handle empty DELETE response body
        if (init.method === 'DELETE') {
          return null
        }

        throw err
      }
    }

    throw new ResponseError('Failed to fetch', { response })
  }

  protected url(pathname: string): string {
    return getAbsoluteURL(pathname, this.baseURL).toString()
  }

  get<T extends ResponseBody>(
    pathname: string,
    init?: RequestInit,
  ) {
    return this.fetch<T>(pathname, {
      ...init,
      method: 'GET',
    })
  }

  post<T extends ResponseBody>(
    pathname: string,
    data: Record<string, any> | string,
    init?: RequestInit,
  ) {
    return this.fetch<T>(pathname, {
      ...init,
      method: 'POST',
      body: JSON.stringify(data),
    })
  }

  patch<T extends ResponseBody>(
    pathname: string,
    data: Record<string, any>,
    init?: RequestInit,
  ) {
    return this.fetch<T>(pathname, {
      ...init,
      method: 'PATCH',
      body: JSON.stringify(data),
    })
  }

  put<T extends ResponseBody>(
    pathname: string,
    data: Record<string, any>,
    init?: RequestInit,
  ) {
    return this.fetch<T>(pathname, {
      ...init,
      method: 'PUT',
      body: JSON.stringify(data),
    })
  }

  delete<T extends ResponseBody | EmptyResponseBody = any>(
    pathname: string,
    init?: RequestInit,
  ) {
    return this.fetch<T>(pathname, {
      ...init,
      method: 'DELETE',
    })
  }

  protected async getToken(
    path: string,
    cookiePath?: string,
    /** The cookie namespace. Use the current User ID to prevent collision */
    cookieNamespace?: string,
    /** Token cookie options */
    options?: Cookies.CookieAttributes,
  ): Promise<string | never> {
    let token: string | null

    if (cookieNamespace) {
      token = await getToken(
        this.url(path),
        `${cookieNamespace}.${cookiePath}`,
        false,
        options,
      )
    } else {
      token = await getToken(
        this.url(path),
        null,
      )
    }

    if (!token) {
      throw new TokenError()
    }

    return token
  }
}

const DEFAULT_INIT: RequestInit = Object.freeze({
  credentials: 'same-origin' as const,
  headers: {
    'Accept': 'application/json',
    'Accept-Language': '*',
    'Content-Type': 'application/json',
  },
  method: 'GET' as const,
  mode: 'cors' as const,
})
