export enum TENANT_API_TYPES_ENUM {
  EmptyRequestBody = 'EmptyRequestBody',
  EmptyResponseBody = 'EmptyResponseBody',
  GetInformantConversationsConversationMessagesParamsData = 'GetInformantConversationsConversationMessagesParamsData',
  GetInformantConversationsConversationMessagesParamsPage = 'GetInformantConversationsConversationMessagesParamsPage',
  GetInformantConversationsConversationMessagesParamsSort = 'GetInformantConversationsConversationMessagesParamsSort',
  GetAuditsParamsData = 'GetAuditsParamsData',
  GetAuditsParamsPage = 'GetAuditsParamsPage',
  GetAuditsParamsSort = 'GetAuditsParamsSort',
  GetAutomationsParamsData = 'GetAutomationsParamsData',
  GetAutomationsParamsPage = 'GetAutomationsParamsPage',
  GetAutomationsParamsSort = 'GetAutomationsParamsSort',
  GetAutomationGroupsParamsData = 'GetAutomationGroupsParamsData',
  GetAutomationGroupsParamsPage = 'GetAutomationGroupsParamsPage',
  GetAutomationGroupsParamsSort = 'GetAutomationGroupsParamsSort',
  GetChannelsParamsData = 'GetChannelsParamsData',
  GetChannelsParamsPage = 'GetChannelsParamsPage',
  GetChannelsParamsSort = 'GetChannelsParamsSort',
  GetDeletedReportsParamsData = 'GetDeletedReportsParamsData',
  GetDeletedReportsParamsPage = 'GetDeletedReportsParamsPage',
  GetDeletedReportsParamsSort = 'GetDeletedReportsParamsSort',
  GetFilteredChannelsParamsFilter = 'GetFilteredChannelsParamsFilter',
  GetFilteredUsersParamsFilter = 'GetFilteredUsersParamsFilter',
  GetFilteredReportsParamsFilter = 'GetFilteredReportsParamsFilter',
  GetFilteredWorkflowsParamsFilter = 'GetFilteredWorkflowsParamsFilter',
  GetInvitationsParamsData = 'GetInvitationsParamsData',
  GetInvitationsParamsPage = 'GetInvitationsParamsPage',
  GetInvitationsParamsSort = 'GetInvitationsParamsSort',
  GetLandingPagesParamsData = 'GetLandingPagesParamsData',
  GetLandingPagesParamsPage = 'GetLandingPagesParamsPage',
  GetLandingPagesParamsSort = 'GetLandingPagesParamsSort',
  GetNotificationsParamsData = 'GetNotificationsParamsData',
  GetNotificationsParamsPage = 'GetNotificationsParamsPage',
  GetNotificationsParamsSort = 'GetNotificationsParamsSort',
  GetQueryConfigsParamsData = 'GetQueryConfigsParamsData',
  GetQueryConfigsParamsPage = 'GetQueryConfigsParamsPage',
  GetQueryConfigsParamsSort = 'GetQueryConfigsParamsSort',
  GetUsersParamsData = 'GetUsersParamsData',
  GetUsersParamsPage = 'GetUsersParamsPage',
  GetUsersParamsSort = 'GetUsersParamsSort',
  GetExternalUsersParamsData = 'GetExternalUsersParamsData',
  GetExternalUsersParamsPage = 'GetExternalUsersParamsPage',
  GetExternalUsersParamsSort = 'GetExternalUsersParamsSort',
  GetParticipantsParamsData = 'GetParticipantsParamsData',
  GetParticipantsParamsPage = 'GetParticipantsParamsPage',
  GetParticipantsParamsSort = 'GetParticipantsParamsSort',
  GetReportsReportActivitiesParamsData = 'GetReportsReportActivitiesParamsData',
  GetReportsReportActivitiesParamsPage = 'GetReportsReportActivitiesParamsPage',
  GetReportsReportActivitiesParamsSort = 'GetReportsReportActivitiesParamsSort',
  GetReportsReportMessagesParamsData = 'GetReportsReportMessagesParamsData',
  GetReportsReportMessagesParamsPage = 'GetReportsReportMessagesParamsPage',
  GetReportsReportMessagesParamsSort = 'GetReportsReportMessagesParamsSort',
  GetReportsReportNotesParamsData = 'GetReportsReportNotesParamsData',
  GetReportsReportNotesParamsPage = 'GetReportsReportNotesParamsPage',
  GetReportsReportNotesParamsSort = 'GetReportsReportNotesParamsSort',
  GetReportsReportFilesParamsData = 'GetReportsReportFilesParamsData',
  GetReportsReportFilesParamsPage = 'GetReportsReportFilesParamsPage',
  GetReportsReportFilesParamsSort = 'GetReportsReportFilesParamsSort',
  GetReportsReportMembershipsParamsData = 'GetReportsReportMembershipsParamsData',
  GetReportsReportMembershipsParamsPage = 'GetReportsReportMembershipsParamsPage',
  GetReportsReportMembershipsParamsSort = 'GetReportsReportMembershipsParamsSort',
  GetReportsReportMembershipsUserGroupsParamsData = 'GetReportsReportMembershipsUserGroupsParamsData',
  GetReportsReportMembershipsUserGroupsParamsPage = 'GetReportsReportMembershipsUserGroupsParamsPage',
  GetReportsReportMembershipsUserGroupsParamsSort = 'GetReportsReportMembershipsUserGroupsParamsSort',
  GetReportsReportMembershipsExternalUsersParamsData = 'GetReportsReportMembershipsExternalUsersParamsData',
  GetReportsReportMembershipsExternalUsersParamsPage = 'GetReportsReportMembershipsExternalUsersParamsPage',
  GetReportsReportMembershipsExternalUsersParamsSort = 'GetReportsReportMembershipsExternalUsersParamsSort',
  GetWorkflowsParamsData = 'GetWorkflowsParamsData',
  GetWorkflowsParamsPage = 'GetWorkflowsParamsPage',
  GetWorkflowsParamsSort = 'GetWorkflowsParamsSort',
  ReportVersionedReportRequestBodyAttributesProperties = 'ReportVersionedReportRequestBodyAttributesProperties',
  GetInformantConversationsConversationMessagesParams = 'GetInformantConversationsConversationMessagesParams',
  GetAuditsParams = 'GetAuditsParams',
  GetResetPasswordParams = 'GetResetPasswordParams',
  GetAutomationsParams = 'GetAutomationsParams',
  GetAutomationGroupsParams = 'GetAutomationGroupsParams',
  GetChannelsParams = 'GetChannelsParams',
  GetChannelsChannelExportedReportsParams = 'GetChannelsChannelExportedReportsParams',
  GetDataExportsDownloadParams = 'GetDataExportsDownloadParams',
  GetDeletedReportsParams = 'GetDeletedReportsParams',
  GetElementsElementFilesFileParams = 'GetElementsElementFilesFileParams',
  GetElementsElementFilesParams = 'GetElementsElementFilesParams',
  GetFilteredChannelsParams = 'GetFilteredChannelsParams',
  GetFilteredUsersParams = 'GetFilteredUsersParams',
  GetFilteredReportsParams = 'GetFilteredReportsParams',
  GetFilteredWorkflowsParams = 'GetFilteredWorkflowsParams',
  GetInvitationsParams = 'GetInvitationsParams',
  GetLandingPagesParams = 'GetLandingPagesParams',
  GetNotificationsParams = 'GetNotificationsParams',
  GetPublicMediasMediaDownloadParams = 'GetPublicMediasMediaDownloadParams',
  GetQueryConfigsParams = 'GetQueryConfigsParams',
  GetRetentionPoliciesParams = 'GetRetentionPoliciesParams',
  PostTagsParams = 'PostTagsParams',
  GetCustomTranslationsModelIdParams = 'GetCustomTranslationsModelIdParams',
  GetUsersUser2FaQrCodesParams = 'GetUsersUser2FaQrCodesParams',
  GetUsersParams = 'GetUsersParams',
  GetBundledTranslationsParams = 'GetBundledTranslationsParams',
  GetExternalUsersParams = 'GetExternalUsersParams',
  GetParticipantsParams = 'GetParticipantsParams',
  GetReportsReportActivitiesParams = 'GetReportsReportActivitiesParams',
  GetReportsParams = 'GetReportsParams',
  PostAuthGuestTokenParams = 'PostAuthGuestTokenParams',
  GetReportsLookupParams = 'GetReportsLookupParams',
  GetReportsReportMessagesParams = 'GetReportsReportMessagesParams',
  GetReportsReportNotesParams = 'GetReportsReportNotesParams',
  GetReportsReportFilesParams = 'GetReportsReportFilesParams',
  GetReportsReportMembershipsParams = 'GetReportsReportMembershipsParams',
  GetReportsReportMembershipsUserGroupsParams = 'GetReportsReportMembershipsUserGroupsParams',
  GetReportsReportMembershipsExternalUsersParams = 'GetReportsReportMembershipsExternalUsersParams',
  GetReportsReportSearchParams = 'GetReportsReportSearchParams',
  GetInformantResetPasswordParams = 'GetInformantResetPasswordParams',
  GetInformantReportsReportStepsParams = 'GetInformantReportsReportStepsParams',
  GetWorkflowsParams = 'GetWorkflowsParams',
  GetWorkflowsWorkflowExportedSubmissionsParams = 'GetWorkflowsWorkflowExportedSubmissionsParams',
  UnprocessableEntityErrors = 'UnprocessableEntityErrors',
  ElementLogicRequestBodyElementOptionsId = 'ElementLogicRequestBodyElementOptionsId',
  CustomTranslationRequestBodyAttributes = 'CustomTranslationRequestBodyAttributes',
  PostQueryConfigRequestBodyParameters = 'PostQueryConfigRequestBodyParameters',
  PutQueryConfigRequestBodyParameters = 'PutQueryConfigRequestBodyParameters',
  ReportVersionedReportRequestBodyAttributes = 'ReportVersionedReportRequestBodyAttributes',
  InformantUpdateRequestBodySecondaryLocales = 'InformantUpdateRequestBodySecondaryLocales',
  InformantUpdateRequestBodyNotificationSettings = 'InformantUpdateRequestBodyNotificationSettings',
  ActionAttributes = 'ActionAttributes',
  ActionSimpleAttributes = 'ActionSimpleAttributes',
  AuditOldValues = 'AuditOldValues',
  AuditNewValues = 'AuditNewValues',
  BundledTranslationsDataLandingPageBlock = 'BundledTranslationsDataLandingPageBlock',
  BundledTranslationsDataStep = 'BundledTranslationsDataStep',
  BundledTranslationsDataElement = 'BundledTranslationsDataElement',
  BundledTranslationsDataElementOption = 'BundledTranslationsDataElementOption',
  BundledTranslationsDataCountry = 'BundledTranslationsDataCountry',
  BundledTranslationsDataChannel = 'BundledTranslationsDataChannel',
  ChannelTranslations = 'ChannelTranslations',
  ChannelTranslationTranslations = 'ChannelTranslationTranslations',
  ChartConfiguration = 'ChartConfiguration',
  ConditionAttributes = 'ConditionAttributes',
  ConditionSimpleAttributes = 'ConditionSimpleAttributes',
  CustomDashboardConfigurationItemConfiguration = 'CustomDashboardConfigurationItemConfiguration',
  ElementDynamicOptionTranslations = 'ElementDynamicOptionTranslations',
  GroupActionSimpleAttributes = 'GroupActionSimpleAttributes',
  GroupConditionSimpleAttributes = 'GroupConditionSimpleAttributes',
  LandingPageExtraFeatureFlags = 'LandingPageExtraFeatureFlags',
  MessageMessageData = 'MessageMessageData',
  ParticipantConfigData = 'ParticipantConfigData',
  ProjectStatistics = 'ProjectStatistics',
  QueryConfigParameters = 'QueryConfigParameters',
  TranslationTranslations = 'TranslationTranslations',
  ReportStatistics = 'ReportStatistics',
  PostAuthForgotPasswordRequestBody = 'PostAuthForgotPasswordRequestBody',
  PostAuthResetPasswordRequestBody = 'PostAuthResetPasswordRequestBody',
  AuthRequestBody = 'AuthRequestBody',
  AutomationActionRequestBody = 'AutomationActionRequestBody',
  AutomationConditionRequestBody = 'AutomationConditionRequestBody',
  AutomationRequestBody = 'AutomationRequestBody',
  AutomationOperatorRequestBody = 'AutomationOperatorRequestBody',
  ChannelUpdateRequestBody = 'ChannelUpdateRequestBody',
  ChannelVisibleLandingPageBlockRequestBody = 'ChannelVisibleLandingPageBlockRequestBody',
  CommentUpdateRequestBody = 'CommentUpdateRequestBody',
  NotFound = 'NotFound',
  Unauthorized = 'Unauthorized',
  Forbidden = 'Forbidden',
  BadRequest = 'BadRequest',
  Conflict = 'Conflict',
  Unavailable = 'Unavailable',
  SuccessStatus = 'SuccessStatus',
  Accepted = 'Accepted',
  UnprocessableEntity = 'UnprocessableEntity',
  NotModified = 'NotModified',
  FilterSort = 'FilterSort',
  PaginationPage = 'PaginationPage',
  PolymorphicCreator = 'PolymorphicCreator',
  PolymorphicUpdater = 'PolymorphicUpdater',
  ConversationDeletedMessageRequestBody = 'ConversationDeletedMessageRequestBody',
  CustomDashboardCopyRequestBody = 'CustomDashboardCopyRequestBody',
  DataExportRequestBody = 'DataExportRequestBody',
  DuplicatedChannelRequestBody = 'DuplicatedChannelRequestBody',
  DuplicatedElementRequestBody = 'DuplicatedElementRequestBody',
  DuplicatedLandingPageRequestBody = 'DuplicatedLandingPageRequestBody',
  DuplicatedWorkflowRequestBody = 'DuplicatedWorkflowRequestBody',
  ElementLogicRequestBody = 'ElementLogicRequestBody',
  FormStepRequestBody = 'FormStepRequestBody',
  FormTranslationSettingRequestBody = 'FormTranslationSettingRequestBody',
  StackItemRequestBody = 'StackItemRequestBody',
  StackVersionItemRequestBody = 'StackVersionItemRequestBody',
  LandingPagePostRequestBody = 'LandingPagePostRequestBody',
  LandingPagePatchRequestBody = 'LandingPagePatchRequestBody',
  LandingPageVisibilityRequestBody = 'LandingPageVisibilityRequestBody',
  LandingPageVisibilityRequestBodyItem = 'LandingPageVisibilityRequestBodyItem',
  LandingPageTranslationSettingRequestBody = 'LandingPageTranslationSettingRequestBody',
  LiveLandingPageRequestBody = 'LiveLandingPageRequestBody',
  ProjectCommentRequestBody = 'ProjectCommentRequestBody',
  ProjectRequestBody = 'ProjectRequestBody',
  ProjectGroupMembershipRequestBody = 'ProjectGroupMembershipRequestBody',
  ProjectMembershipRequestBody = 'ProjectMembershipRequestBody',
  ProjectReportRequestBody = 'ProjectReportRequestBody',
  ProjectReportReportRequestBody = 'ProjectReportReportRequestBody',
  ReadNotificationRequestBody = 'ReadNotificationRequestBody',
  SamlUserInactiveRequestBody = 'SamlUserInactiveRequestBody',
  ScimAuthRequestBody = 'ScimAuthRequestBody',
  ScimAuthSuccessItem = 'ScimAuthSuccessItem',
  ScimTokenRevokedItem = 'ScimTokenRevokedItem',
  SeenNotificationRequestBody = 'SeenNotificationRequestBody',
  StepUpdateRequestBody = 'StepUpdateRequestBody',
  SubmissionRequestBody = 'SubmissionRequestBody',
  SubmissionAnswerRequestBody = 'SubmissionAnswerRequestBody',
  SubmittedReportRequestBody = 'SubmittedReportRequestBody',
  TimezoneItems = 'TimezoneItems',
  CustomTranslationRequestBody = 'CustomTranslationRequestBody',
  UserMfaRequestBody = 'UserMfaRequestBody',
  UserEmailRequestBody = 'UserEmailRequestBody',
  UserGroupRequestBody = 'UserGroupRequestBody',
  UserLanguagePreferenceRequestBody = 'UserLanguagePreferenceRequestBody',
  UserNotificationPreferenceRequestBody = 'UserNotificationPreferenceRequestBody',
  UserPasswordRequestBody = 'UserPasswordRequestBody',
  UserRolesRequestBody = 'UserRolesRequestBody',
  UserRoleRequestBody = 'UserRoleRequestBody',
  UserGroupUsersRequestBody = 'UserGroupUsersRequestBody',
  UserGroupUserRequestBody = 'UserGroupUserRequestBody',
  UserValidPasswordRequestBody = 'UserValidPasswordRequestBody',
  ReportListData = 'ReportListData',
  ReportListSort = 'ReportListSort',
  ReportListPage = 'ReportListPage',
  ReportListRequestBody = 'ReportListRequestBody',
  ConversationRequestBody = 'ConversationRequestBody',
  ReportGroupMembershipStoreRequestBody = 'ReportGroupMembershipStoreRequestBody',
  ReportMembershipRequestBody = 'ReportMembershipRequestBody',
  ReportRedactionRequestBody = 'ReportRedactionRequestBody',
  ReportStepSubmissionStoreRequestBody = 'ReportStepSubmissionStoreRequestBody',
  PutReportWorkflowRequestBody = 'PutReportWorkflowRequestBody',
  PostInformantConversationDeletedMessageRequestBody = 'PostInformantConversationDeletedMessageRequestBody',
  PostInformantSubmittedReportRequestBody = 'PostInformantSubmittedReportRequestBody',
  PatchInformantReportRequestBody = 'PatchInformantReportRequestBody',
  AbstractImageRequestBody = 'AbstractImageRequestBody',
  AcceptedInvitationRequestBody = 'AcceptedInvitationRequestBody',
  ChannelStoreRequestBody = 'ChannelStoreRequestBody',
  ConversationMessageRequestBody = 'ConversationMessageRequestBody',
  CustomChartsRequestBody = 'CustomChartsRequestBody',
  CustomDashboardDefaultRequestBody = 'CustomDashboardDefaultRequestBody',
  CustomDashboardMembershipRequestBody = 'CustomDashboardMembershipRequestBody',
  CustomDashboardRequestBody = 'CustomDashboardRequestBody',
  CustomDashboardStoreRequestBody = 'CustomDashboardStoreRequestBody',
  CustomDashboardUpdateRequestBody = 'CustomDashboardUpdateRequestBody',
  DraftedReportRequestBody = 'DraftedReportRequestBody',
  ElementOptionRequestBody = 'ElementOptionRequestBody',
  ElementOptionAutomationGroupRequestBody = 'ElementOptionAutomationGroupRequestBody',
  ElementOptionAutomationRequestBody = 'ElementOptionAutomationRequestBody',
  ElementOptionLogicRequestBody = 'ElementOptionLogicRequestBody',
  ElementPatchRequestBody = 'ElementPatchRequestBody',
  ElementStoreRequestBody = 'ElementStoreRequestBody',
  EmailedInformantLinkRequestBody = 'EmailedInformantLinkRequestBody',
  ExternalUserStoreRequestBody = 'ExternalUserStoreRequestBody',
  InvitationRequestBody = 'InvitationRequestBody',
  LandingPageBlockImageRequestBody = 'LandingPageBlockImageRequestBody',
  LandingPageBlockRequestBody = 'LandingPageBlockRequestBody',
  LandingPageLandingPageBlockRequestBody = 'LandingPageLandingPageBlockRequestBody',
  MediaFileRequestBody = 'MediaFileRequestBody',
  OrderRequestBody = 'OrderRequestBody',
  OrganizationSettingRequestBody = 'OrganizationSettingRequestBody',
  ParticipantRequestBody = 'ParticipantRequestBody',
  ProjectStatusStoreRequestBody = 'ProjectStatusStoreRequestBody',
  ProjectStatusUpdateRequestBody = 'ProjectStatusUpdateRequestBody',
  ProjectTagStoreRequestBody = 'ProjectTagStoreRequestBody',
  PostQueryConfigRequestBody = 'PostQueryConfigRequestBody',
  PutQueryConfigRequestBody = 'PutQueryConfigRequestBody',
  RetentionPolicyRequestBody = 'RetentionPolicyRequestBody',
  TagStoreRequestBody = 'TagStoreRequestBody',
  TranslationNameRequestBody = 'TranslationNameRequestBody',
  TranslationNameRequestBodyItem = 'TranslationNameRequestBodyItem',
  TranslationRequestBody = 'TranslationRequestBody',
  TranslationRequestBodyItem = 'TranslationRequestBodyItem',
  TranslationValueRequestBody = 'TranslationValueRequestBody',
  TranslationValueRequestBodyItem = 'TranslationValueRequestBodyItem',
  ReportUpdateRequestBody = 'ReportUpdateRequestBody',
  UserAutomationRequestBody = 'UserAutomationRequestBody',
  UserDashboardPreferencesRequestBody = 'UserDashboardPreferencesRequestBody',
  UserUpdateRequestBody = 'UserUpdateRequestBody',
  UserReportActionRequestBody = 'UserReportActionRequestBody',
  ReportExternalUserStoreRequestBody = 'ReportExternalUserStoreRequestBody',
  ReportExternalUserPermissionsItem = 'ReportExternalUserPermissionsItem',
  ReportExternalUserUpdateRequestBody = 'ReportExternalUserUpdateRequestBody',
  ReportParticipantPatchRequestBody = 'ReportParticipantPatchRequestBody',
  ReportParticipantStoreRequestBody = 'ReportParticipantStoreRequestBody',
  ReportProjectRequestBody = 'ReportProjectRequestBody',
  ReportProjectProjectRequestBody = 'ReportProjectProjectRequestBody',
  ReportStatusStoreRequestBody = 'ReportStatusStoreRequestBody',
  ReportStatusUpdateRequestBody = 'ReportStatusUpdateRequestBody',
  ReportTagStoreRequestBody = 'ReportTagStoreRequestBody',
  ReportVersionedReportRequestBody = 'ReportVersionedReportRequestBody',
  ReportReportWorkflowUpdateRequest = 'ReportReportWorkflowUpdateRequest',
  InformantAuthRequestBody = 'InformantAuthRequestBody',
  PostInformantDraftedReportRequestBody = 'PostInformantDraftedReportRequestBody',
  InformantEmailUpdateRequestBody = 'InformantEmailUpdateRequestBody',
  InformantGuestUpdateRequestBody = 'InformantGuestUpdateRequestBody',
  InformantLinkRequestBody = 'InformantLinkRequestBody',
  InformantPushTokenRequestBody = 'InformantPushTokenRequestBody',
  InformantStoreRequestBody = 'InformantStoreRequestBody',
  InformantUpdateRequestBody = 'InformantUpdateRequestBody',
  InformantInformantLinkRequestBody = 'InformantInformantLinkRequestBody',
  WorkflowStoreRequestBody = 'WorkflowStoreRequestBody',
  WorkflowUpdateRequestBody = 'WorkflowUpdateRequestBody',
  Action = 'Action',
  ActionItem = 'ActionItem',
  ActionItems = 'ActionItems',
  ActionSimple = 'ActionSimple',
  ActionType = 'ActionType',
  ActionTypeItems = 'ActionTypeItems',
  Anonymous = 'Anonymous',
  AnswerCountry = 'AnswerCountry',
  AnswerDate = 'AnswerDate',
  AnswerElementBooleanOption = 'AnswerElementBooleanOption',
  AnswerElementDynamicOption = 'AnswerElementDynamicOption',
  AnswerElementScaleOption = 'AnswerElementScaleOption',
  AnswerMedia = 'AnswerMedia',
  AnswerMediaItem = 'AnswerMediaItem',
  AnswerMediaItems = 'AnswerMediaItems',
  AnswerMediaSimple = 'AnswerMediaSimple',
  AnswerNumber = 'AnswerNumber',
  Answer = 'Answer',
  AnswerItem = 'AnswerItem',
  AnswerItems = 'AnswerItems',
  AnswerText = 'AnswerText',
  AsmDisabled = 'AsmDisabled',
  AsmDisabledItem = 'AsmDisabledItem',
  AuditEntity = 'AuditEntity',
  Audit = 'Audit',
  AuditItem = 'AuditItem',
  AuditItems = 'AuditItems',
  AuditTag = 'AuditTag',
  AuthCubeJs = 'AuthCubeJs',
  AuthCubeJsItem = 'AuthCubeJsItem',
  AuthDataExport = 'AuthDataExport',
  AuthDataExportItem = 'AuthDataExportItem',
  AuthExternalDataSource = 'AuthExternalDataSource',
  AuthExternalDataSourceItem = 'AuthExternalDataSourceItem',
  AuthFailure = 'AuthFailure',
  AuthFailureItem = 'AuthFailureItem',
  AuthHelpCenter = 'AuthHelpCenter',
  AuthHelpCenterItem = 'AuthHelpCenterItem',
  AuthPlatform = 'AuthPlatform',
  AuthPlatformItem = 'AuthPlatformItem',
  AuthSuccess = 'AuthSuccess',
  AuthSuccessItem = 'AuthSuccessItem',
  AuthTranslation = 'AuthTranslation',
  AuthTranslationItem = 'AuthTranslationItem',
  AutomationError = 'AutomationError',
  AutomationGroup = 'AutomationGroup',
  AutomationGroupItem = 'AutomationGroupItem',
  AutomationGroupItems = 'AutomationGroupItems',
  Automation = 'Automation',
  AutomationItem = 'AutomationItem',
  AutomationItems = 'AutomationItems',
  AutomationSimple = 'AutomationSimple',
  BundledTranslationsData = 'BundledTranslationsData',
  BundledTranslationsItem = 'BundledTranslationsItem',
  TranslationsBuilding = 'TranslationsBuilding',
  ChannelLandingPage = 'ChannelLandingPage',
  ChannelLocaleTranslation = 'ChannelLocaleTranslation',
  ChannelQrCode = 'ChannelQrCode',
  ChannelQrCodeItem = 'ChannelQrCodeItem',
  Channel = 'Channel',
  ChannelItem = 'ChannelItem',
  ChannelItems = 'ChannelItems',
  ChannelSimple = 'ChannelSimple',
  ChannelSimpleItem = 'ChannelSimpleItem',
  ChannelSimpleItems = 'ChannelSimpleItems',
  ChannelStatus = 'ChannelStatus',
  ChannelStatusItem = 'ChannelStatusItem',
  ChannelStatusItems = 'ChannelStatusItems',
  ChannelTranslation = 'ChannelTranslation',
  ChannelTranslationItem = 'ChannelTranslationItem',
  ChannelType = 'ChannelType',
  ChannelTypeItem = 'ChannelTypeItem',
  ChannelTypeItems = 'ChannelTypeItems',
  Chart = 'Chart',
  ChartItem = 'ChartItem',
  ChartItems = 'ChartItems',
  Comment = 'Comment',
  CommentItem = 'CommentItem',
  CommentItems = 'CommentItems',
  Condition = 'Condition',
  ConditionItem = 'ConditionItem',
  ConditionItems = 'ConditionItems',
  ConditionSimple = 'ConditionSimple',
  ConditionType = 'ConditionType',
  ConditionTypeItems = 'ConditionTypeItems',
  ConversationSimple = 'ConversationSimple',
  ConversationSimpleItem = 'ConversationSimpleItem',
  ConversationSimpleItems = 'ConversationSimpleItems',
  Country = 'Country',
  CountryItem = 'CountryItem',
  CountryItems = 'CountryItems',
  CustomDashboardConfigurationItem = 'CustomDashboardConfigurationItem',
  CustomDashboardConfiguration = 'CustomDashboardConfiguration',
  CustomDashboardCreatedBy = 'CustomDashboardCreatedBy',
  CustomDashboard = 'CustomDashboard',
  CustomDashboardItem = 'CustomDashboardItem',
  CustomDashboardItems = 'CustomDashboardItems',
  CustomLandingPage = 'CustomLandingPage',
  CustomLandingPageItem = 'CustomLandingPageItem',
  CustomLandingPageItems = 'CustomLandingPageItems',
  DashboardPreference = 'DashboardPreference',
  DataLookup = 'DataLookup',
  DataLookupItem = 'DataLookupItem',
  DataLookupItems = 'DataLookupItems',
  ElementDynamicOption = 'ElementDynamicOption',
  ElementOption = 'ElementOption',
  ElementOptionItem = 'ElementOptionItem',
  ElementOptionItems = 'ElementOptionItems',
  Element = 'Element',
  ElementItem = 'ElementItem',
  ElementItems = 'ElementItems',
  ElementSimple = 'ElementSimple',
  ElementType = 'ElementType',
  ElementTypeItems = 'ElementTypeItems',
  EntitySimple = 'EntitySimple',
  ExternalUser = 'ExternalUser',
  ExternalUserItem = 'ExternalUserItem',
  ExternalUserItems = 'ExternalUserItems',
  ExternalUserReportSimple = 'ExternalUserReportSimple',
  FeatureFlags = 'FeatureFlags',
  FeatureFlagItem = 'FeatureFlagItem',
  Filename = 'Filename',
  FilenameItem = 'FilenameItem',
  Form = 'Form',
  FormItem = 'FormItem',
  FormItems = 'FormItems',
  FormSimple = 'FormSimple',
  GroupActionSimple = 'GroupActionSimple',
  GroupAutomation = 'GroupAutomation',
  GroupAutomationItem = 'GroupAutomationItem',
  GroupAutomationItems = 'GroupAutomationItems',
  GroupConditionSimple = 'GroupConditionSimple',
  GuestInformant = 'GuestInformant',
  GuestInformantItem = 'GuestInformantItem',
  GuestInformantItems = 'GuestInformantItems',
  Invitation = 'Invitation',
  InvitationItem = 'InvitationItem',
  InvitationItems = 'InvitationItems',
  LandingPageBlock = 'LandingPageBlock',
  LandingPageBlockItem = 'LandingPageBlockItem',
  LandingPageBlockItems = 'LandingPageBlockItems',
  LandingPageBlockSimple = 'LandingPageBlockSimple',
  LandingPageBlockType = 'LandingPageBlockType',
  LandingPageBlockTypeItem = 'LandingPageBlockTypeItem',
  LandingPageBlockTypeItems = 'LandingPageBlockTypeItems',
  LandingPageExtra = 'LandingPageExtra',
  LandingPage = 'LandingPage',
  LandingPageItem = 'LandingPageItem',
  LandingPageItems = 'LandingPageItems',
  LandingPageSimple = 'LandingPageSimple',
  LandingPageSimpleItem = 'LandingPageSimpleItem',
  LandingPageSimpleItems = 'LandingPageSimpleItems',
  LanguagePreference = 'LanguagePreference',
  LanguagePreferenceItem = 'LanguagePreferenceItem',
  LanguagePreferenceItems = 'LanguagePreferenceItems',
  Locale = 'Locale',
  LocaleItem = 'LocaleItem',
  LocaleItems = 'LocaleItems',
  LocaleTranslation = 'LocaleTranslation',
  Logic = 'Logic',
  LogicItem = 'LogicItem',
  LogicItems = 'LogicItems',
  LogicTrigger = 'LogicTrigger',
  MediaMeta = 'MediaMeta',
  MediaMetaItem = 'MediaMetaItem',
  Media = 'Media',
  MediaVariant = 'MediaVariant',
  MediaItem = 'MediaItem',
  MediaItems = 'MediaItems',
  MediaSimple = 'MediaSimple',
  MembershipExternalUser = 'MembershipExternalUser',
  MembershipExternalUserItem = 'MembershipExternalUserItem',
  MembershipExternalUserItems = 'MembershipExternalUserItems',
  ReportGroupUserMembership = 'ReportGroupUserMembership',
  Membership = 'Membership',
  MembershipItem = 'MembershipItem',
  MembershipItems = 'MembershipItems',
  MembershipUserGroupWithUsers = 'MembershipUserGroupWithUsers',
  MembershipUserGroupWithUsersItem = 'MembershipUserGroupWithUsersItem',
  MembershipUserGroupWithUsersItems = 'MembershipUserGroupWithUsersItems',
  Mention = 'Mention',
  MentionItem = 'MentionItem',
  MentionItems = 'MentionItems',
  MessageMeta = 'MessageMeta',
  MessageMetaItem = 'MessageMetaItem',
  MessageMetaItems = 'MessageMetaItems',
  Message = 'Message',
  MessageItem = 'MessageItem',
  MessageItems = 'MessageItems',
  MessageSimple = 'MessageSimple',
  Note = 'Note',
  NoteItem = 'NoteItem',
  NoteItems = 'NoteItems',
  NotificationEntityMeta = 'NotificationEntityMeta',
  NotificationEntity = 'NotificationEntity',
  NotificationPreference = 'NotificationPreference',
  NotificationPreferenceItem = 'NotificationPreferenceItem',
  NotificationPreferenceItems = 'NotificationPreferenceItems',
  NotificationRedaction = 'NotificationRedaction',
  Notification = 'Notification',
  NotificationItem = 'NotificationItem',
  NotificationItems = 'NotificationItems',
  NotificationType = 'NotificationType',
  NotificationTypeItems = 'NotificationTypeItems',
  NotificationUser = 'NotificationUser',
  OrganizationSetting = 'OrganizationSetting',
  OrganizationSettingItem = 'OrganizationSettingItem',
  OrganizationSettingItems = 'OrganizationSettingItems',
  OrganizationSettingSimple = 'OrganizationSettingSimple',
  ParticipantConfig = 'ParticipantConfig',
  ParticipantConfigItem = 'ParticipantConfigItem',
  ParticipantConfigItems = 'ParticipantConfigItems',
  Participant = 'Participant',
  ParticipantItem = 'ParticipantItem',
  ParticipantItems = 'ParticipantItems',
  ParticipantSimple = 'ParticipantSimple',
  ParticipantSimpleItem = 'ParticipantSimpleItem',
  ParticipantSimpleItems = 'ParticipantSimpleItems',
  ParticipantReport = 'ParticipantReport',
  ParticipantReportItem = 'ParticipantReportItem',
  ParticipantReportItems = 'ParticipantReportItems',
  Project = 'Project',
  ProjectItem = 'ProjectItem',
  ProjectItems = 'ProjectItems',
  ProjectSimple = 'ProjectSimple',
  ProjectStatus = 'ProjectStatus',
  ProjectStatusItem = 'ProjectStatusItem',
  ProjectStatusItems = 'ProjectStatusItems',
  ProjectWorkflow = 'ProjectWorkflow',
  ProjectWorkflowItem = 'ProjectWorkflowItem',
  ProjectWorkflowItems = 'ProjectWorkflowItems',
  QrCode = 'QrCode',
  QrCodeItem = 'QrCodeItem',
  QueryConfig = 'QueryConfig',
  QueryConfigItem = 'QueryConfigItem',
  QueryConfigItems = 'QueryConfigItems',
  Redaction = 'Redaction',
  RedactionItem = 'RedactionItem',
  RedactionItems = 'RedactionItems',
  RetentionPolicy = 'RetentionPolicy',
  RetentionPolicyItem = 'RetentionPolicyItem',
  RetentionPolicyItems = 'RetentionPolicyItems',
  RetentionPolicyStatistics = 'RetentionPolicyStatistics',
  DeprecatedSearch = 'DeprecatedSearch',
  DeprecatedSearchItem = 'DeprecatedSearchItem',
  SearchResultAnswer = 'SearchResultAnswer',
  SearchResultFile = 'SearchResultFile',
  SearchResultMessage = 'SearchResultMessage',
  SimpleDashboard = 'SimpleDashboard',
  SimpleDashboardItem = 'SimpleDashboardItem',
  SimpleDashboardItems = 'SimpleDashboardItems',
  Stack = 'Stack',
  StackItem = 'StackItem',
  StackVersion = 'StackVersion',
  StackVersionItem = 'StackVersionItem',
  StackVersionItems = 'StackVersionItems',
  Step = 'Step',
  StepItem = 'StepItem',
  StepItems = 'StepItems',
  StepSimple = 'StepSimple',
  StepSummary = 'StepSummary',
  StepSummaryItem = 'StepSummaryItem',
  StepSummaryItems = 'StepSummaryItems',
  SubmissibleSummary = 'SubmissibleSummary',
  SubmissibleSummaryItem = 'SubmissibleSummaryItem',
  SubmissibleSummaryItems = 'SubmissibleSummaryItems',
  Submission = 'Submission',
  SubmissionItem = 'SubmissionItem',
  SubmissionItems = 'SubmissionItems',
  Success = 'Success',
  SuccessItem = 'SuccessItem',
  SystemUser = 'SystemUser',
  Tag = 'Tag',
  TagItem = 'TagItem',
  TagItems = 'TagItems',
  TagSimple = 'TagSimple',
  TagSimpleItem = 'TagSimpleItem',
  TagSimpleItems = 'TagSimpleItems',
  Translation = 'Translation',
  TranslationItem = 'TranslationItem',
  TranslationItems = 'TranslationItems',
  TranslationSetting = 'TranslationSetting',
  TriggerType = 'TriggerType',
  TriggerTypeItem = 'TriggerTypeItem',
  TriggerTypeItems = 'TriggerTypeItems',
  UserMfa = 'UserMfa',
  UserMfaItem = 'UserMfaItem',
  UserMfaItems = 'UserMfaItems',
  UserAbility = 'UserAbility',
  UserGroup = 'UserGroup',
  UserGroupItem = 'UserGroupItem',
  UserGroupItems = 'UserGroupItems',
  UserGroupSimple = 'UserGroupSimple',
  UserGroupSimpleItem = 'UserGroupSimpleItem',
  UserGroupSimpleItems = 'UserGroupSimpleItems',
  UserGroupWithUsers = 'UserGroupWithUsers',
  UserGroupWithUsersItem = 'UserGroupWithUsersItem',
  UserGroupWithUsersItems = 'UserGroupWithUsersItems',
  User = 'User',
  UserItem = 'UserItem',
  UserItems = 'UserItems',
  UserRole = 'UserRole',
  UserRoleItem = 'UserRoleItem',
  UserRoleItems = 'UserRoleItems',
  UserRoleSimple = 'UserRoleSimple',
  UserSimple = 'UserSimple',
  UserSimpleItem = 'UserSimpleItem',
  UserSimpleItems = 'UserSimpleItems',
  UserReportAction = 'UserReportAction',
  UserReportActionItem = 'UserReportActionItem',
  UserReportActionItems = 'UserReportActionItems',
  WebsocketAuth = 'WebsocketAuth',
  WebsocketAuthItem = 'WebsocketAuthItem',
  ReportActivityAttribute = 'ReportActivityAttribute',
  ReportActivity = 'ReportActivity',
  ReportActivityItem = 'ReportActivityItem',
  ReportActivityItems = 'ReportActivityItems',
  ReportConversationMeta = 'ReportConversationMeta',
  ReportConversationMetaItem = 'ReportConversationMetaItem',
  ReportConversation = 'ReportConversation',
  ReportConversationItem = 'ReportConversationItem',
  ReportConversationItems = 'ReportConversationItems',
  DeletedReport = 'DeletedReport',
  DeletedReportItem = 'DeletedReportItem',
  DeletedReportItems = 'DeletedReportItems',
  ReportExternalUserPermissions = 'ReportExternalUserPermissions',
  ReportExternalUser = 'ReportExternalUser',
  ReportExternalUserItem = 'ReportExternalUserItem',
  ReportExternalUserItems = 'ReportExternalUserItems',
  FilteredReport = 'FilteredReport',
  FilteredReportItem = 'FilteredReportItem',
  FilteredReportItems = 'FilteredReportItems',
  ReportMembership = 'ReportMembership',
  ReportMembershipItem = 'ReportMembershipItem',
  ReportMembershipItems = 'ReportMembershipItems',
  ReportMembershipSummary = 'ReportMembershipSummary',
  ReportMembershipSummaryItem = 'ReportMembershipSummaryItem',
  ReportMembershipDetails = 'ReportMembershipDetails',
  ReportMembershipType = 'ReportMembershipType',
  ReportMembershipTypeItem = 'ReportMembershipTypeItem',
  ReportMembershipTypeItems = 'ReportMembershipTypeItems',
  Report = 'Report',
  ReportItem = 'ReportItem',
  ReportItems = 'ReportItems',
  DeprecatedReportSearch = 'DeprecatedReportSearch',
  DeprecatedReportSearchItem = 'DeprecatedReportSearchItem',
  ReportSearchResults = 'ReportSearchResults',
  ReportSimple = 'ReportSimple',
  ReportStatus = 'ReportStatus',
  ReportStatusItem = 'ReportStatusItem',
  ReportStatusItems = 'ReportStatusItems',
  ReportStatusSimple = 'ReportStatusSimple',
  ReportSummary = 'ReportSummary',
  ReportSummaryItem = 'ReportSummaryItem',
  ReportSummaryItems = 'ReportSummaryItems',
  ReportVersion = 'ReportVersion',
  ReportVersionItem = 'ReportVersionItem',
  ReportVersionItems = 'ReportVersionItems',
  ReportWorkflow = 'ReportWorkflow',
  ReportWorkflowItem = 'ReportWorkflowItem',
  ReportWorkflowItems = 'ReportWorkflowItems',
  InformantLink = 'InformantLink',
  InformantLinkItem = 'InformantLinkItem',
  InformantLinkItems = 'InformantLinkItems',
  InformantNotificationSettings = 'InformantNotificationSettings',
  InformantPushToken = 'InformantPushToken',
  InformantPushTokenItem = 'InformantPushTokenItem',
  InformantPushTokenItems = 'InformantPushTokenItems',
  Informant = 'Informant',
  InformantItem = 'InformantItem',
  InformantItems = 'InformantItems',
  InformantSimple = 'InformantSimple',
  InformantReport = 'InformantReport',
  InformantReportItem = 'InformantReportItem',
  InformantReportItems = 'InformantReportItems',
  Workflow = 'Workflow',
  WorkflowItem = 'WorkflowItem',
  WorkflowItems = 'WorkflowItems',
  WorkflowSimple = 'WorkflowSimple',
  WorkflowSimpleItem = 'WorkflowSimpleItem',
  WorkflowSimpleItems = 'WorkflowSimpleItems',
  WorkflowStatus = 'WorkflowStatus',
  WorkflowStatusItem = 'WorkflowStatusItem',
  WorkflowStatusItems = 'WorkflowStatusItems'
}
export const TENANT_API_OPERATIONS = [
  "getInformantChannelsChannel",
  "getInformantConversationsConversationMessages",
  "postInformantConversationsConversationMessages",
  "deleteInformantSystemMessagesMessage",
  "getInformantReportsReport",
  "getInformantReports",
  "postInformantReportsReportDownloads",
  "getInformantReportsReportMedias",
  "getInformantReportsReportRedactions",
  "postInvitationsInvitationAccept",
  "getActionTypes",
  "postAnswerMedias",
  "postArchivedChannelsChannel",
  "deleteArchivedChannelsChannel",
  "postArchivedLandingPagesLandingPage",
  "deleteArchivedLandingPagesLandingPage",
  "postArchivedRetentionPoliciesRetentionPolicy",
  "deleteArchivedRetentionPoliciesRetentionPolicy",
  "postArchivedWorkflowsWorkflow",
  "deleteArchivedWorkflowsWorkflow",
  "getAudits",
  "getAuthAsmDisabled",
  "postAuthDataExport",
  "postForgotPassword",
  "getResetPassword",
  "postResetPassword",
  "postAuthLogin",
  "getMe",
  "postAuthLogout",
  "postAuthRefresh",
  "getAuthVerify",
  "getAuthGuestVerify",
  "postAuthAnalytics",
  "postAuthEnabled2Fa",
  "postAuthExternalDataSource",
  "postAuthPlatforms",
  "postAuthTranslations",
  "postAuthWebsockets",
  "postAutomationsAutomationActions",
  "putActionsAction",
  "deleteActionsAction",
  "postAutomationsAutomationConditions",
  "putConditionsCondition",
  "deleteConditionsCondition",
  "getAutomations",
  "postAutomations",
  "getAutomationsAutomation",
  "deleteAutomationsAutomation",
  "patchAutomationsAutomation",
  "postAutomationGroupsAutomationGroupActions",
  "deleteGroupActionsGroupAction",
  "patchGroupActionsGroupAction",
  "postAutomationGroupsAutomationGroupConditions",
  "deleteGroupConditionsGroupCondition",
  "patchGroupConditionsGroupCondition",
  "getAutomationGroups",
  "postAutomationGroups",
  "getAutomationGroupsAutomationGroup",
  "deleteAutomationGroupsAutomationGroup",
  "patchAutomationGroupsAutomationGroup",
  "patchAutomationsAutomationOperators",
  "putAutomationsAutomationTriggerTypesTriggerType",
  "getChannels",
  "postChannels",
  "getChannelsChannel",
  "patchChannelsChannel",
  "getChannelsChannelExportedReports",
  "getChannelsChannelLandingPageBlocks",
  "postChannelsChannelLandingPagesLandingPage",
  "deleteChannelsChannelLandingPagesLandingPage",
  "deleteChannelsChannelLandingPages",
  "postChannelsChannelMobileDeepLinks",
  "postChannelsChannelQrCodes",
  "getChannelStatuses",
  "postChannelsChannelTranslations",
  "getChannelTypes",
  "patchChannelsChannelVisibleLandingPageBlocks",
  "getChatChannels",
  "patchCommentsComment",
  "getConditionTypes",
  "patchConversationsConversation",
  "postConversationsConversationDeletedMessages",
  "postConversationsConversationInternalNotes",
  "deleteInternalNotesMessage",
  "patchInternalNotesMessage",
  "getConversationsConversationMessages",
  "postConversationsConversationMessages",
  "getCountries",
  "getAnalyticsCharts",
  "postAnalyticsCharts",
  "deleteAnalyticsChartsChart",
  "patchAnalyticsChartsChart",
  "postAnalyticsDashboardsDashboardCharts",
  "deleteAnalyticsDashboardsDashboardChartsChart",
  "patchAnalyticsDashboardsDashboardChartsChart",
  "getAnalyticsDashboards",
  "postAnalyticsDashboards",
  "getAnalyticsDashboardsDashboard",
  "deleteAnalyticsDashboardsDashboard",
  "patchAnalyticsDashboardsDashboard",
  "postAnalyticsDashboardsCopyDashboardCopy",
  "patchAnalyticsDashboardsDashboardDefault",
  "postAnalyticsDashboardsDashboardMemberships",
  "deleteAnalyticsDashboardsDashboardMembershipsMembershipableTypeMembershipableId",
  "getAnalyticsDashboardsDashboardPotentialUsers",
  "getAnalyticsDashboardsDashboardPotentialUserGroups",
  "getCustomLandingPages",
  "postDataExportsType",
  "getDataExportsDownload",
  "getDeletedProjects",
  "getDeletedProjectsId",
  "getDeletedReports",
  "getDeletedReportsId",
  "postDraftedReports",
  "postDuplicatedChannels",
  "postDuplicatedElements",
  "postDuplicatedLandingPages",
  "postDuplicatedWorkflows",
  "getElementsElementAutomations",
  "postElements",
  "deleteElementsElement",
  "patchElementsElement",
  "getElementsElementElementOptions",
  "postElementsElementElementOptions",
  "getElementsElementFilesFile",
  "deleteElementsElementFilesFile",
  "postElementsElementFiles",
  "getElementsElementFiles",
  "postElementsElementLogics",
  "deleteElementsElementLogics",
  "patchElementOptionsElementOptionActiveAutomations",
  "patchElementOptionsElementOptionActiveAutomationGroups",
  "deleteElementOptionsElementOption",
  "patchElementOptionsElementOption",
  "getElementOptionsElementOptionActiveLogics",
  "patchElementOptionsElementOptionActiveLogics",
  "patchElementOptionsElementOptionOrder",
  "patchElementsElementOrder",
  "patchElementsElementReorderToStep",
  "getElementTypes",
  "postEmailedInformantLinks",
  "postEndProjectStatusesProjectStatus",
  "deleteEndProjectStatusesProjectStatus",
  "postEndReportStatusesReportStatus",
  "deleteEndReportStatusesReportStatus",
  "getFeatureFlags",
  "getFilteredChannels",
  "getFilteredUsers",
  "getFilteredReports",
  "getFilteredWorkflows",
  "getForms",
  "getFormsForm",
  "deleteFormsForm",
  "postFormsFormElementOptionTranslations",
  "postFormsFormElementTranslations",
  "postFormsFormEnabledTranslations",
  "deleteFormsFormEnabledTranslations",
  "getFormsFormSteps",
  "postFormsFormSteps",
  "patchFormsFormStepOrders",
  "postFormsFormStepTranslations",
  "postFormsFormTranslationSettings",
  "deleteFormsFormTranslationSettings",
  "getStackVersions",
  "postStackVersions",
  "getAuthHelpCenter",
  "getHotlines",
  "postUsersUserInactive",
  "deleteUsersUserInactive",
  "getInternalForms",
  "getInvitations",
  "postInvitations",
  "getInvitationsInvitation",
  "deleteInvitationsInvitation",
  "patchLandingPageBlocksLandingPageBlock",
  "getLandingPageBlockImagesFilesFile",
  "postLandingPageBlocksLandingPageBlockImages",
  "deleteLandingPageBlocksLandingPageBlockImages",
  "patchLandingPageBlocksLandingPageBlockImages",
  "getLandingPageBlockTypes",
  "getLandingPages",
  "postLandingPages",
  "getLandingPagesLandingPage",
  "patchLandingPagesLandingPage",
  "postLandingPagesLandingPageEnabledTranslations",
  "deleteLandingPagesLandingPageEnabledTranslations",
  "postLandingPagesLandingPageLandingPageBlocks",
  "patchLandingPagesLandingPageLandingPageBlockVisibilities",
  "postLandingPagesLandingPageTranslations",
  "postLandingPagesLandingPageTranslationSettings",
  "deleteLandingPagesLandingPageTranslationSettings",
  "getLatestOrganizationImagesCollectionName",
  "getLiveChannels",
  "postLiveChannelsChannel",
  "deleteLiveChannelsChannel",
  "getLiveLandingPagesSlugAsString",
  "postLiveLandingPages",
  "deleteLiveLandingPagesLandingPage",
  "getLiveLandingPagesSlugT",
  "getLiveWorkflows",
  "postLiveWorkflowsWorkflow",
  "deleteLiveWorkflowsWorkflow",
  "getLocales",
  "postLockedReportsReport",
  "deleteLockedReportsReport",
  "getMediasMediaDownload",
  "getNotifications",
  "getNotificationTypes",
  "getOrganizationSettings",
  "patchOrganizationSettingsOrganizationSetting",
  "patchOrganizationSettingsOrganizationSettingLogos",
  "getPotentialUserGroups",
  "getPreviewLandingPagesSlugAsString",
  "postProjectsProjectComments",
  "getProjectsProjectComments",
  "getProjects",
  "postProjects",
  "getProjectsProject",
  "patchProjectsProject",
  "postProjectsProjectFiles",
  "deleteProjectsProjectFilesFile",
  "getProjectsProjectFilesFile",
  "deleteProjectsProjectGroupMembersUserGroup",
  "postProjectsProjectGroupMemberships",
  "deleteProjectsProjectMembersUser",
  "postProjectsProjectMemberships",
  "postProjectsProjectOwners",
  "patchProjectsProjectOwnersOwner",
  "getProjectsProjectPotentialMembers",
  "putProjectsProjectProjectStatusesProjectStatus",
  "getProjectsProjectProjectWorkflows",
  "putProjectsProjectRetentionPoliciesRetentionPolicy",
  "deleteProjectsProjectRetentionPolicies",
  "getProjectStatuses",
  "postProjectStatuses",
  "deleteProjectStatusesProjectStatus",
  "patchProjectStatusesProjectStatus",
  "patchProjectStatusesProjectStatusDefaults",
  "patchProjectStatusesProjectStatusOrder",
  "getProjectsProjectTags",
  "postProjectsProjectTags",
  "deleteProjectsProjectTagsTag",
  "getProjectsProjectReports",
  "postProjectsProjectReports",
  "deleteProjectsProjectReportsReport",
  "putProjectsProjectWorkflowsWorkflow",
  "getPublicChannels",
  "getPublicMediasMediaDownload",
  "getQueryConfigs",
  "postQueryConfigs",
  "getQueryConfigsQueryConfig",
  "putQueryConfigsQueryConfig",
  "deleteQueryConfigsQueryConfig",
  "postReadNotifications",
  "getRedactions",
  "getRetentionPolicies",
  "postRetentionPolicies",
  "deleteRetentionPoliciesRetentionPolicy",
  "getRoles",
  "postAuthSaml2UsersUserSamlIdInactive",
  "deleteAuthSaml2UsersUserSamlIdInactive",
  "postScimAuthToken",
  "postScimAuthTokenRefresh",
  "postScimAuthTokenRevoke",
  "getSearch",
  "postSeenNotifications",
  "getSelectableUsers",
  "getDashboards",
  "getStepsStepAutomations",
  "deleteStepsStep",
  "patchStepsStep",
  "getSubmissiblesSubmissibleFilesFile",
  "getSubmissiblesSubmissibleStepsStepAnswers",
  "getSubmissiblesSubmissibleSteps",
  "postSubmissiblesSubmissibleStepsStepSubmissions",
  "getSubmissiblesSubmissibleSubmissionsAnswers",
  "getSubmissiblesSubmissibleSubmissions",
  "getSubmissiblesSubmissibleSummary",
  "getSubmissionsSubmissionAnswers",
  "postSubmittedReports",
  "getTags",
  "postTags",
  "getTagsTag",
  "deleteTagsTag",
  "getTimezones",
  "getCustomTranslationsModelId",
  "postCustomTranslationsModelId",
  "getTriggerTypes",
  "getUnownedReportsReport",
  "getUsersUser2Fa",
  "postUsersUser2Fa",
  "deleteUsersUser2Fa",
  "getUsersUser2FaQrCodes",
  "patchUsersUserActiveAutomations",
  "deleteUsersUserAvatars",
  "patchUsersUserAvatars",
  "getUsers",
  "patchUsersUser",
  "getUsersUser",
  "patchUsersUserDashboardPreferences",
  "patchUsersUserEmail",
  "getUserGroups",
  "postUserGroups",
  "getUserGroupsUserGroup",
  "deleteUserGroupsUserGroup",
  "patchUserGroupsUserGroup",
  "patchUsersUserLanguagePreferences",
  "getUsersUserNotificationPreferences",
  "patchUsersUserNotificationPreferences",
  "putUsersUserPassword",
  "patchUsersUserRoles",
  "postUserGroupsUserGroupUsers",
  "deleteUserGroupsUserGroupUsersUser",
  "postUsersUserValidPassword",
  "getBundledTranslations",
  "getExternalUsers",
  "postExternalUsers",
  "deleteExternalUsersExternalUser",
  "getGuestMe",
  "patchMessagesMessage",
  "patchMessagesMessageReplies",
  "getParticipantsConfig",
  "getParticipants",
  "postParticipants",
  "getParticipantsParticipant",
  "deleteParticipantsParticipant",
  "patchParticipantsParticipant",
  "getProjectsProjectPotentialUserGroups",
  "getReportsReportActivities",
  "getReports",
  "postReports",
  "postReportsReportGenerateSummary",
  "getReportsReportConversationMeta",
  "postReportsReportExternalUsersExternalUserTokens",
  "postAuthGuestToken",
  "postReportsReportExternalUsers",
  "getReportsReportExternalUsersExternalUser",
  "deleteReportsReportExternalUsersExternalUser",
  "patchReportsReportExternalUsersExternalUser",
  "getReportsLookup",
  "getReportsReportMessages",
  "getReportsReportNotes",
  "getReportParticipantsParticipantReportFilesFile",
  "deleteReportParticipantsParticipantReportFilesFile",
  "postReportParticipantsParticipantReportFiles",
  "getReportsReportParticipants",
  "postReportsReportParticipants",
  "getReportParticipantsParticipantReport",
  "deleteReportParticipantsParticipantReport",
  "patchReportParticipantsParticipantReport",
  "getReportsReportPotentialExternalUsers",
  "getReportsReportPotentialUserGroups",
  "postReportsReportSnooze",
  "deleteReportsReportSnooze",
  "patchReportsReportSnooze",
  "getReportsReportActiveInformantLinks",
  "getReportsIdAvatar",
  "getReportsReport",
  "patchReportsReport",
  "postReportsReportConversations",
  "getReportsReportConversations",
  "getReportsReportFilesFile",
  "deleteReportsReportFilesFile",
  "postReportsReportFiles",
  "getReportsReportFiles",
  "deleteReportsReportGroupMembersUserGroup",
  "postReportsReportGroupMemberships",
  "deleteReportsReportMembersUser",
  "getReportsReportMemberships",
  "getReportsReportMembershipSummaries",
  "getReportsReportMembershipsUserGroups",
  "getReportsReportMembershipsExternalUsers",
  "postReportsReportMemberships",
  "postReportsReportOwners",
  "patchReportsReportOwnersOwner",
  "getReportsReportPotentialMembers",
  "postReportsReportProjects",
  "deleteReportsReportProjectsProject",
  "getReportsReportRedactions",
  "postReportsReportRedactions",
  "deleteReportsReportRedactionsRedaction",
  "putReportsReportRetentionPoliciesRetentionPolicy",
  "deleteReportsReportRetentionPolicies",
  "getReportsReportSearch",
  "getReportStatuses",
  "postReportStatuses",
  "deleteReportStatusesReportStatus",
  "patchReportStatusesReportStatus",
  "patchReportStatusesReportStatusDefaults",
  "patchReportStatusesReportStatusOrder",
  "getReportsReportSteps",
  "postReportsReportStepsStepSubmissions",
  "getReportsReportTags",
  "postReportsReportTags",
  "patchReportsReportTags",
  "deleteReportsReportTagsTag",
  "getReportsReportVersionedReports",
  "postReportsReportVersionedReports",
  "getReportsReportReportWorkflows",
  "patchReportsReportReportWorkflowsReportWorkflow",
  "putReportsReportWorkflowsWorkflow",
  "postInformantAnswerMedias",
  "postInformantForgotPassword",
  "getInformantResetPassword",
  "postInformantResetPassword",
  "postInformantAuthLogin",
  "getInformantMe",
  "postInformantAuthLogout",
  "postInformantAuthRefresh",
  "getInformantAuthVerify",
  "postInformantAuthTranslations",
  "postInformantAuthWebsockets",
  "getInformantChannels",
  "patchInformantConversationsConversation",
  "postInformantConversationsConversationDeletedMessages",
  "postInformantDraftedReports",
  "getInformantElementsElementFilesFile",
  "getInformantElementsElementFiles",
  "getInformantFilesFileMeta",
  "postInformantGuestInformant",
  "patchInformantGuestInformant",
  "getInformantMediasMedia",
  "getInformantMediasMediaDownload",
  "getInformantSelectableUsers",
  "getInformantSubmissiblesSubmissibleSubmissions",
  "getInformantSubmissiblesSubmissibleSummary",
  "postInformantSubmittedReports",
  "getInformantReportsReportStepsStepAnswers",
  "deleteInformantReportsReport",
  "patchInformantReportsReport",
  "getInformantReportsReportConversations",
  "getInformantReportsReportFilesFile",
  "getInformantReportsReportFiles",
  "postInformantReportsReportFiles",
  "deleteInformantReportsReportFilesFile",
  "getInformantMobileDeepLinks",
  "getInformantReportsReportQrCodes",
  "getInformantReportsReportSteps",
  "postInformantReportsReportStepsStepSubmissions",
  "getInformantReportsSummary",
  "getInformantReportsReportSummary",
  "getInformantReportsReportVersionedReports",
  "postInformantInformants",
  "patchInformantInformantsInformant",
  "deleteInformantInformantsInformantEmail",
  "patchInformantInformantsInformantEmail",
  "postInformantInformantLinks",
  "getInformantInformantLinksInformantLinkCode",
  "getInformantPushTokens",
  "postInformantPushTokens",
  "deleteInformantPushTokensInformantPushToken",
  "postInformantLinks",
  "getInformantLinksCode",
  "getWorkflows",
  "postWorkflows",
  "getWorkflowsWorkflow",
  "patchWorkflowsWorkflow",
  "getWorkflowsWorkflowExportedSubmissions",
  "getWorkflowStatuses"
] as const

    /**
     * Support __typename lookup by operationId. Request body type defined for all operations with:
     *   Status: 200
     *   Content-Type: application/json
     */
export const TENANT_API_OPERATIONS_RESPONSE_BODY_TYPE_MAP: Record<typeof TENANT_API_OPERATIONS[number], TENANT_API_TYPES_ENUM | null> = {
  "getInformantChannelsChannel": TENANT_API_TYPES_ENUM.ChannelItem,
  "getInformantConversationsConversationMessages": TENANT_API_TYPES_ENUM.MessageItems,
  "postInformantConversationsConversationMessages": null,
  "deleteInformantSystemMessagesMessage": null,
  "getInformantReportsReport": TENANT_API_TYPES_ENUM.InformantReportItem,
  "getInformantReports": TENANT_API_TYPES_ENUM.InformantReportItems,
  "postInformantReportsReportDownloads": null,
  "getInformantReportsReportMedias": TENANT_API_TYPES_ENUM.MediaItems,
  "getInformantReportsReportRedactions": TENANT_API_TYPES_ENUM.RedactionItems,
  "postInvitationsInvitationAccept": TENANT_API_TYPES_ENUM.UserItem,
  "getActionTypes": TENANT_API_TYPES_ENUM.ActionTypeItems,
  "postAnswerMedias": TENANT_API_TYPES_ENUM.AnswerMediaItem,
  "postArchivedChannelsChannel": TENANT_API_TYPES_ENUM.ChannelItem,
  "deleteArchivedChannelsChannel": TENANT_API_TYPES_ENUM.ChannelItem,
  "postArchivedLandingPagesLandingPage": TENANT_API_TYPES_ENUM.LandingPageItem,
  "deleteArchivedLandingPagesLandingPage": null,
  "postArchivedRetentionPoliciesRetentionPolicy": TENANT_API_TYPES_ENUM.RetentionPolicyItem,
  "deleteArchivedRetentionPoliciesRetentionPolicy": TENANT_API_TYPES_ENUM.RetentionPolicyItem,
  "postArchivedWorkflowsWorkflow": TENANT_API_TYPES_ENUM.WorkflowItem,
  "deleteArchivedWorkflowsWorkflow": TENANT_API_TYPES_ENUM.WorkflowItem,
  "getAudits": TENANT_API_TYPES_ENUM.AuditItems,
  "getAuthAsmDisabled": TENANT_API_TYPES_ENUM.AsmDisabledItem,
  "postAuthDataExport": TENANT_API_TYPES_ENUM.AuthDataExportItem,
  "postForgotPassword": null,
  "getResetPassword": TENANT_API_TYPES_ENUM.SuccessStatus,
  "postResetPassword": null,
  "postAuthLogin": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "getMe": TENANT_API_TYPES_ENUM.UserItem,
  "postAuthLogout": null,
  "postAuthRefresh": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "getAuthVerify": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "getAuthGuestVerify": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "postAuthAnalytics": TENANT_API_TYPES_ENUM.AuthCubeJsItem,
  "postAuthEnabled2Fa": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "postAuthExternalDataSource": TENANT_API_TYPES_ENUM.AuthExternalDataSourceItem,
  "postAuthPlatforms": TENANT_API_TYPES_ENUM.AuthPlatformItem,
  "postAuthTranslations": TENANT_API_TYPES_ENUM.AuthTranslationItem,
  "postAuthWebsockets": TENANT_API_TYPES_ENUM.WebsocketAuthItem,
  "postAutomationsAutomationActions": null,
  "putActionsAction": TENANT_API_TYPES_ENUM.ActionItem,
  "deleteActionsAction": null,
  "postAutomationsAutomationConditions": null,
  "putConditionsCondition": TENANT_API_TYPES_ENUM.ConditionItem,
  "deleteConditionsCondition": null,
  "getAutomations": TENANT_API_TYPES_ENUM.AutomationItems,
  "postAutomations": null,
  "getAutomationsAutomation": TENANT_API_TYPES_ENUM.AutomationItem,
  "deleteAutomationsAutomation": null,
  "patchAutomationsAutomation": TENANT_API_TYPES_ENUM.AutomationItem,
  "postAutomationGroupsAutomationGroupActions": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "deleteGroupActionsGroupAction": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "patchGroupActionsGroupAction": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "postAutomationGroupsAutomationGroupConditions": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "deleteGroupConditionsGroupCondition": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "patchGroupConditionsGroupCondition": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "getAutomationGroups": TENANT_API_TYPES_ENUM.AutomationGroupItems,
  "postAutomationGroups": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "getAutomationGroupsAutomationGroup": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "deleteAutomationGroupsAutomationGroup": null,
  "patchAutomationGroupsAutomationGroup": TENANT_API_TYPES_ENUM.AutomationGroupItem,
  "patchAutomationsAutomationOperators": TENANT_API_TYPES_ENUM.AutomationItem,
  "putAutomationsAutomationTriggerTypesTriggerType": TENANT_API_TYPES_ENUM.AutomationItem,
  "getChannels": TENANT_API_TYPES_ENUM.ChannelItems,
  "postChannels": TENANT_API_TYPES_ENUM.ChannelItem,
  "getChannelsChannel": TENANT_API_TYPES_ENUM.ChannelItem,
  "patchChannelsChannel": TENANT_API_TYPES_ENUM.ChannelItem,
  "getChannelsChannelExportedReports": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getChannelsChannelLandingPageBlocks": TENANT_API_TYPES_ENUM.ChannelItem,
  "postChannelsChannelLandingPagesLandingPage": TENANT_API_TYPES_ENUM.ChannelItem,
  "deleteChannelsChannelLandingPagesLandingPage": TENANT_API_TYPES_ENUM.ChannelItem,
  "deleteChannelsChannelLandingPages": TENANT_API_TYPES_ENUM.ChannelItem,
  "postChannelsChannelMobileDeepLinks": TENANT_API_TYPES_ENUM.ChannelQrCodeItem,
  "postChannelsChannelQrCodes": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getChannelStatuses": TENANT_API_TYPES_ENUM.ChannelStatusItems,
  "postChannelsChannelTranslations": TENANT_API_TYPES_ENUM.ChannelTranslationItem,
  "getChannelTypes": TENANT_API_TYPES_ENUM.ChannelTypeItems,
  "patchChannelsChannelVisibleLandingPageBlocks": TENANT_API_TYPES_ENUM.ChannelItem,
  "getChatChannels": TENANT_API_TYPES_ENUM.ChannelItems,
  "patchCommentsComment": TENANT_API_TYPES_ENUM.CommentItem,
  "getConditionTypes": TENANT_API_TYPES_ENUM.ConditionTypeItems,
  "patchConversationsConversation": TENANT_API_TYPES_ENUM.ReportConversationItem,
  "postConversationsConversationDeletedMessages": TENANT_API_TYPES_ENUM.MessageItem,
  "postConversationsConversationInternalNotes": null,
  "deleteInternalNotesMessage": null,
  "patchInternalNotesMessage": TENANT_API_TYPES_ENUM.NoteItem,
  "getConversationsConversationMessages": TENANT_API_TYPES_ENUM.MessageItems,
  "postConversationsConversationMessages": null,
  "getCountries": TENANT_API_TYPES_ENUM.CountryItems,
  "getAnalyticsCharts": TENANT_API_TYPES_ENUM.ChartItems,
  "postAnalyticsCharts": null,
  "deleteAnalyticsChartsChart": null,
  "patchAnalyticsChartsChart": TENANT_API_TYPES_ENUM.ChartItem,
  "postAnalyticsDashboardsDashboardCharts": TENANT_API_TYPES_ENUM.CustomDashboardItem,
  "deleteAnalyticsDashboardsDashboardChartsChart": TENANT_API_TYPES_ENUM.CustomDashboardItem,
  "patchAnalyticsDashboardsDashboardChartsChart": TENANT_API_TYPES_ENUM.CustomDashboardItem,
  "getAnalyticsDashboards": TENANT_API_TYPES_ENUM.CustomDashboardItems,
  "postAnalyticsDashboards": null,
  "getAnalyticsDashboardsDashboard": TENANT_API_TYPES_ENUM.CustomDashboardItem,
  "deleteAnalyticsDashboardsDashboard": null,
  "patchAnalyticsDashboardsDashboard": TENANT_API_TYPES_ENUM.CustomDashboardItem,
  "postAnalyticsDashboardsCopyDashboardCopy": null,
  "patchAnalyticsDashboardsDashboardDefault": TENANT_API_TYPES_ENUM.SuccessStatus,
  "postAnalyticsDashboardsDashboardMemberships": TENANT_API_TYPES_ENUM.MembershipItem,
  "deleteAnalyticsDashboardsDashboardMembershipsMembershipableTypeMembershipableId": null,
  "getAnalyticsDashboardsDashboardPotentialUsers": TENANT_API_TYPES_ENUM.UserItems,
  "getAnalyticsDashboardsDashboardPotentialUserGroups": TENANT_API_TYPES_ENUM.UserGroupItems,
  "getCustomLandingPages": TENANT_API_TYPES_ENUM.CustomLandingPageItems,
  "postDataExportsType": TENANT_API_TYPES_ENUM.SuccessItem,
  "getDataExportsDownload": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getDeletedProjects": TENANT_API_TYPES_ENUM.ProjectItems,
  "getDeletedProjectsId": TENANT_API_TYPES_ENUM.ProjectItem,
  "getDeletedReports": TENANT_API_TYPES_ENUM.DeletedReportItems,
  "getDeletedReportsId": TENANT_API_TYPES_ENUM.ReportItem,
  "postDraftedReports": null,
  "postDuplicatedChannels": null,
  "postDuplicatedElements": TENANT_API_TYPES_ENUM.ElementItem,
  "postDuplicatedLandingPages": null,
  "postDuplicatedWorkflows": null,
  "getElementsElementAutomations": TENANT_API_TYPES_ENUM.AutomationItems,
  "postElements": TENANT_API_TYPES_ENUM.ElementItem,
  "deleteElementsElement": null,
  "patchElementsElement": TENANT_API_TYPES_ENUM.ElementItem,
  "getElementsElementElementOptions": TENANT_API_TYPES_ENUM.ElementOptionItems,
  "postElementsElementElementOptions": TENANT_API_TYPES_ENUM.ElementOptionItem,
  "getElementsElementFilesFile": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "deleteElementsElementFilesFile": null,
  "postElementsElementFiles": null,
  "getElementsElementFiles": TENANT_API_TYPES_ENUM.MediaItems,
  "postElementsElementLogics": TENANT_API_TYPES_ENUM.LogicItem,
  "deleteElementsElementLogics": null,
  "patchElementOptionsElementOptionActiveAutomations": TENANT_API_TYPES_ENUM.SuccessItem,
  "patchElementOptionsElementOptionActiveAutomationGroups": TENANT_API_TYPES_ENUM.SuccessItem,
  "deleteElementOptionsElementOption": null,
  "patchElementOptionsElementOption": TENANT_API_TYPES_ENUM.ElementOptionItem,
  "getElementOptionsElementOptionActiveLogics": TENANT_API_TYPES_ENUM.LogicItems,
  "patchElementOptionsElementOptionActiveLogics": TENANT_API_TYPES_ENUM.SuccessItem,
  "patchElementOptionsElementOptionOrder": TENANT_API_TYPES_ENUM.ElementOptionItems,
  "patchElementsElementOrder": TENANT_API_TYPES_ENUM.ElementItems,
  "patchElementsElementReorderToStep": TENANT_API_TYPES_ENUM.StepItems,
  "getElementTypes": TENANT_API_TYPES_ENUM.ElementTypeItems,
  "postEmailedInformantLinks": TENANT_API_TYPES_ENUM.InformantLinkItem,
  "postEndProjectStatusesProjectStatus": TENANT_API_TYPES_ENUM.ProjectStatusItem,
  "deleteEndProjectStatusesProjectStatus": TENANT_API_TYPES_ENUM.ProjectStatusItem,
  "postEndReportStatusesReportStatus": TENANT_API_TYPES_ENUM.ReportStatusItem,
  "deleteEndReportStatusesReportStatus": TENANT_API_TYPES_ENUM.ReportStatusItem,
  "getFeatureFlags": TENANT_API_TYPES_ENUM.FeatureFlagItem,
  "getFilteredChannels": TENANT_API_TYPES_ENUM.ChannelSimpleItems,
  "getFilteredUsers": TENANT_API_TYPES_ENUM.UserSimpleItems,
  "getFilteredReports": TENANT_API_TYPES_ENUM.FilteredReportItems,
  "getFilteredWorkflows": TENANT_API_TYPES_ENUM.WorkflowSimpleItems,
  "getForms": TENANT_API_TYPES_ENUM.FormItems,
  "getFormsForm": TENANT_API_TYPES_ENUM.FormItem,
  "deleteFormsForm": null,
  "postFormsFormElementOptionTranslations": TENANT_API_TYPES_ENUM.FormItem,
  "postFormsFormElementTranslations": TENANT_API_TYPES_ENUM.FormItem,
  "postFormsFormEnabledTranslations": TENANT_API_TYPES_ENUM.FormItem,
  "deleteFormsFormEnabledTranslations": TENANT_API_TYPES_ENUM.FormItem,
  "getFormsFormSteps": TENANT_API_TYPES_ENUM.StepItems,
  "postFormsFormSteps": TENANT_API_TYPES_ENUM.StepItem,
  "patchFormsFormStepOrders": TENANT_API_TYPES_ENUM.FormItem,
  "postFormsFormStepTranslations": TENANT_API_TYPES_ENUM.FormItem,
  "postFormsFormTranslationSettings": TENANT_API_TYPES_ENUM.FormItem,
  "deleteFormsFormTranslationSettings": null,
  "getStackVersions": TENANT_API_TYPES_ENUM.StackItem,
  "postStackVersions": TENANT_API_TYPES_ENUM.StackItem,
  "getAuthHelpCenter": TENANT_API_TYPES_ENUM.AuthHelpCenterItem,
  "getHotlines": TENANT_API_TYPES_ENUM.ChannelItems,
  "postUsersUserInactive": TENANT_API_TYPES_ENUM.UserItem,
  "deleteUsersUserInactive": TENANT_API_TYPES_ENUM.UserItem,
  "getInternalForms": TENANT_API_TYPES_ENUM.ChannelItems,
  "getInvitations": TENANT_API_TYPES_ENUM.InvitationItems,
  "postInvitations": null,
  "getInvitationsInvitation": TENANT_API_TYPES_ENUM.InvitationItem,
  "deleteInvitationsInvitation": null,
  "patchLandingPageBlocksLandingPageBlock": TENANT_API_TYPES_ENUM.LandingPageBlockItem,
  "getLandingPageBlockImagesFilesFile": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "postLandingPageBlocksLandingPageBlockImages": TENANT_API_TYPES_ENUM.LandingPageBlockItem,
  "deleteLandingPageBlocksLandingPageBlockImages": null,
  "patchLandingPageBlocksLandingPageBlockImages": TENANT_API_TYPES_ENUM.LandingPageBlockItem,
  "getLandingPageBlockTypes": TENANT_API_TYPES_ENUM.LandingPageBlockTypeItems,
  "getLandingPages": TENANT_API_TYPES_ENUM.LandingPageSimpleItems,
  "postLandingPages": TENANT_API_TYPES_ENUM.LandingPageItem,
  "getLandingPagesLandingPage": TENANT_API_TYPES_ENUM.LandingPageItem,
  "patchLandingPagesLandingPage": TENANT_API_TYPES_ENUM.LandingPageItem,
  "postLandingPagesLandingPageEnabledTranslations": TENANT_API_TYPES_ENUM.LandingPageItem,
  "deleteLandingPagesLandingPageEnabledTranslations": TENANT_API_TYPES_ENUM.LandingPageItem,
  "postLandingPagesLandingPageLandingPageBlocks": null,
  "patchLandingPagesLandingPageLandingPageBlockVisibilities": TENANT_API_TYPES_ENUM.LandingPageBlockItems,
  "postLandingPagesLandingPageTranslations": TENANT_API_TYPES_ENUM.LandingPageItem,
  "postLandingPagesLandingPageTranslationSettings": TENANT_API_TYPES_ENUM.LandingPageItem,
  "deleteLandingPagesLandingPageTranslationSettings": null,
  "getLatestOrganizationImagesCollectionName": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getLiveChannels": TENANT_API_TYPES_ENUM.ChannelItems,
  "postLiveChannelsChannel": TENANT_API_TYPES_ENUM.ChannelItem,
  "deleteLiveChannelsChannel": null,
  "getLiveLandingPagesSlugAsString": TENANT_API_TYPES_ENUM.LandingPageItem,
  "postLiveLandingPages": TENANT_API_TYPES_ENUM.LandingPageItem,
  "deleteLiveLandingPagesLandingPage": null,
  "getLiveLandingPagesSlugT": null,
  "getLiveWorkflows": TENANT_API_TYPES_ENUM.WorkflowItems,
  "postLiveWorkflowsWorkflow": TENANT_API_TYPES_ENUM.WorkflowItem,
  "deleteLiveWorkflowsWorkflow": null,
  "getLocales": TENANT_API_TYPES_ENUM.LocaleItems,
  "postLockedReportsReport": TENANT_API_TYPES_ENUM.ReportItem,
  "deleteLockedReportsReport": TENANT_API_TYPES_ENUM.ReportItem,
  "getMediasMediaDownload": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getNotifications": TENANT_API_TYPES_ENUM.NotificationItems,
  "getNotificationTypes": TENANT_API_TYPES_ENUM.NotificationTypeItems,
  "getOrganizationSettings": TENANT_API_TYPES_ENUM.OrganizationSettingItems,
  "patchOrganizationSettingsOrganizationSetting": TENANT_API_TYPES_ENUM.OrganizationSettingItem,
  "patchOrganizationSettingsOrganizationSettingLogos": TENANT_API_TYPES_ENUM.OrganizationSettingItem,
  "getPotentialUserGroups": TENANT_API_TYPES_ENUM.UserGroupItems,
  "getPreviewLandingPagesSlugAsString": TENANT_API_TYPES_ENUM.LandingPageItem,
  "postProjectsProjectComments": null,
  "getProjectsProjectComments": TENANT_API_TYPES_ENUM.CommentItems,
  "getProjects": TENANT_API_TYPES_ENUM.ProjectItems,
  "postProjects": TENANT_API_TYPES_ENUM.ProjectItem,
  "getProjectsProject": TENANT_API_TYPES_ENUM.ProjectItem,
  "patchProjectsProject": TENANT_API_TYPES_ENUM.ProjectItem,
  "postProjectsProjectFiles": null,
  "deleteProjectsProjectFilesFile": null,
  "getProjectsProjectFilesFile": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "deleteProjectsProjectGroupMembersUserGroup": null,
  "postProjectsProjectGroupMemberships": TENANT_API_TYPES_ENUM.ProjectItem,
  "deleteProjectsProjectMembersUser": null,
  "postProjectsProjectMemberships": TENANT_API_TYPES_ENUM.ProjectItem,
  "postProjectsProjectOwners": TENANT_API_TYPES_ENUM.ProjectItem,
  "patchProjectsProjectOwnersOwner": TENANT_API_TYPES_ENUM.ProjectItem,
  "getProjectsProjectPotentialMembers": TENANT_API_TYPES_ENUM.UserSimpleItems,
  "putProjectsProjectProjectStatusesProjectStatus": TENANT_API_TYPES_ENUM.ProjectItem,
  "getProjectsProjectProjectWorkflows": TENANT_API_TYPES_ENUM.ProjectWorkflowItems,
  "putProjectsProjectRetentionPoliciesRetentionPolicy": TENANT_API_TYPES_ENUM.ProjectItem,
  "deleteProjectsProjectRetentionPolicies": TENANT_API_TYPES_ENUM.ProjectItem,
  "getProjectStatuses": TENANT_API_TYPES_ENUM.ProjectStatusItems,
  "postProjectStatuses": null,
  "deleteProjectStatusesProjectStatus": null,
  "patchProjectStatusesProjectStatus": TENANT_API_TYPES_ENUM.ProjectStatusItem,
  "patchProjectStatusesProjectStatusDefaults": TENANT_API_TYPES_ENUM.ProjectStatusItem,
  "patchProjectStatusesProjectStatusOrder": TENANT_API_TYPES_ENUM.ProjectStatusItems,
  "getProjectsProjectTags": TENANT_API_TYPES_ENUM.TagSimpleItems,
  "postProjectsProjectTags": TENANT_API_TYPES_ENUM.ProjectItem,
  "deleteProjectsProjectTagsTag": null,
  "getProjectsProjectReports": TENANT_API_TYPES_ENUM.ProjectItem,
  "postProjectsProjectReports": TENANT_API_TYPES_ENUM.ProjectItem,
  "deleteProjectsProjectReportsReport": TENANT_API_TYPES_ENUM.ProjectItem,
  "putProjectsProjectWorkflowsWorkflow": null,
  "getPublicChannels": TENANT_API_TYPES_ENUM.ChannelItems,
  "getPublicMediasMediaDownload": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getQueryConfigs": TENANT_API_TYPES_ENUM.QueryConfigItems,
  "postQueryConfigs": null,
  "getQueryConfigsQueryConfig": TENANT_API_TYPES_ENUM.QueryConfigItem,
  "putQueryConfigsQueryConfig": TENANT_API_TYPES_ENUM.QueryConfigItem,
  "deleteQueryConfigsQueryConfig": null,
  "postReadNotifications": TENANT_API_TYPES_ENUM.NotificationItems,
  "getRedactions": TENANT_API_TYPES_ENUM.RedactionItems,
  "getRetentionPolicies": TENANT_API_TYPES_ENUM.RetentionPolicyItems,
  "postRetentionPolicies": null,
  "deleteRetentionPoliciesRetentionPolicy": null,
  "getRoles": TENANT_API_TYPES_ENUM.UserRoleItems,
  "postAuthSaml2UsersUserSamlIdInactive": TENANT_API_TYPES_ENUM.UserItem,
  "deleteAuthSaml2UsersUserSamlIdInactive": TENANT_API_TYPES_ENUM.UserItem,
  "postScimAuthToken": TENANT_API_TYPES_ENUM.ScimAuthSuccessItem,
  "postScimAuthTokenRefresh": TENANT_API_TYPES_ENUM.ScimAuthSuccessItem,
  "postScimAuthTokenRevoke": TENANT_API_TYPES_ENUM.ScimTokenRevokedItem,
  "getSearch": TENANT_API_TYPES_ENUM.DeprecatedSearchItem,
  "postSeenNotifications": TENANT_API_TYPES_ENUM.NotificationItems,
  "getSelectableUsers": TENANT_API_TYPES_ENUM.UserSimpleItems,
  "getDashboards": TENANT_API_TYPES_ENUM.SimpleDashboardItems,
  "getStepsStepAutomations": TENANT_API_TYPES_ENUM.AutomationItems,
  "deleteStepsStep": null,
  "patchStepsStep": TENANT_API_TYPES_ENUM.StepItem,
  "getSubmissiblesSubmissibleFilesFile": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getSubmissiblesSubmissibleStepsStepAnswers": TENANT_API_TYPES_ENUM.SubmissionItem,
  "getSubmissiblesSubmissibleSteps": TENANT_API_TYPES_ENUM.StepItems,
  "postSubmissiblesSubmissibleStepsStepSubmissions": null,
  "getSubmissiblesSubmissibleSubmissionsAnswers": TENANT_API_TYPES_ENUM.SubmissionItems,
  "getSubmissiblesSubmissibleSubmissions": TENANT_API_TYPES_ENUM.SubmissionItems,
  "getSubmissiblesSubmissibleSummary": TENANT_API_TYPES_ENUM.SubmissibleSummaryItem,
  "getSubmissionsSubmissionAnswers": TENANT_API_TYPES_ENUM.AnswerItems,
  "postSubmittedReports": TENANT_API_TYPES_ENUM.ReportItem,
  "getTags": TENANT_API_TYPES_ENUM.TagItems,
  "postTags": null,
  "getTagsTag": TENANT_API_TYPES_ENUM.TagItem,
  "deleteTagsTag": null,
  "getTimezones": TENANT_API_TYPES_ENUM.TimezoneItems,
  "getCustomTranslationsModelId": TENANT_API_TYPES_ENUM.TranslationItem,
  "postCustomTranslationsModelId": TENANT_API_TYPES_ENUM.TranslationItem,
  "getTriggerTypes": TENANT_API_TYPES_ENUM.TriggerTypeItems,
  "getUnownedReportsReport": TENANT_API_TYPES_ENUM.ReportItem,
  "getUsersUser2Fa": TENANT_API_TYPES_ENUM.UserMfaItem,
  "postUsersUser2Fa": null,
  "deleteUsersUser2Fa": null,
  "getUsersUser2FaQrCodes": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "patchUsersUserActiveAutomations": TENANT_API_TYPES_ENUM.SuccessItem,
  "deleteUsersUserAvatars": null,
  "patchUsersUserAvatars": TENANT_API_TYPES_ENUM.UserItem,
  "getUsers": TENANT_API_TYPES_ENUM.UserItems,
  "patchUsersUser": TENANT_API_TYPES_ENUM.UserItem,
  "getUsersUser": TENANT_API_TYPES_ENUM.UserItem,
  "patchUsersUserDashboardPreferences": TENANT_API_TYPES_ENUM.UserItem,
  "patchUsersUserEmail": TENANT_API_TYPES_ENUM.UserItem,
  "getUserGroups": TENANT_API_TYPES_ENUM.UserGroupItems,
  "postUserGroups": null,
  "getUserGroupsUserGroup": TENANT_API_TYPES_ENUM.UserGroupItem,
  "deleteUserGroupsUserGroup": null,
  "patchUserGroupsUserGroup": TENANT_API_TYPES_ENUM.UserGroupItem,
  "patchUsersUserLanguagePreferences": TENANT_API_TYPES_ENUM.LanguagePreferenceItem,
  "getUsersUserNotificationPreferences": TENANT_API_TYPES_ENUM.NotificationPreferenceItems,
  "patchUsersUserNotificationPreferences": TENANT_API_TYPES_ENUM.NotificationPreferenceItems,
  "putUsersUserPassword": TENANT_API_TYPES_ENUM.UserItem,
  "patchUsersUserRoles": TENANT_API_TYPES_ENUM.UserItem,
  "postUserGroupsUserGroupUsers": TENANT_API_TYPES_ENUM.UserGroupItem,
  "deleteUserGroupsUserGroupUsersUser": TENANT_API_TYPES_ENUM.UserGroupItem,
  "postUsersUserValidPassword": null,
  "getBundledTranslations": TENANT_API_TYPES_ENUM.BundledTranslationsItem,
  "getExternalUsers": TENANT_API_TYPES_ENUM.ExternalUserItems,
  "postExternalUsers": null,
  "deleteExternalUsersExternalUser": null,
  "getGuestMe": TENANT_API_TYPES_ENUM.ExternalUserItem,
  "patchMessagesMessage": TENANT_API_TYPES_ENUM.MessageItem,
  "patchMessagesMessageReplies": TENANT_API_TYPES_ENUM.MessageItem,
  "getParticipantsConfig": TENANT_API_TYPES_ENUM.ParticipantConfigItems,
  "getParticipants": TENANT_API_TYPES_ENUM.ParticipantItems,
  "postParticipants": null,
  "getParticipantsParticipant": TENANT_API_TYPES_ENUM.ParticipantItem,
  "deleteParticipantsParticipant": null,
  "patchParticipantsParticipant": TENANT_API_TYPES_ENUM.ParticipantItem,
  "getProjectsProjectPotentialUserGroups": TENANT_API_TYPES_ENUM.UserGroupSimpleItems,
  "getReportsReportActivities": TENANT_API_TYPES_ENUM.ReportActivityItems,
  "getReports": TENANT_API_TYPES_ENUM.ReportItems,
  "postReports": TENANT_API_TYPES_ENUM.ReportItems,
  "postReportsReportGenerateSummary": TENANT_API_TYPES_ENUM.ReportItem,
  "getReportsReportConversationMeta": TENANT_API_TYPES_ENUM.ReportConversationMetaItem,
  "postReportsReportExternalUsersExternalUserTokens": null,
  "postAuthGuestToken": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "postReportsReportExternalUsers": TENANT_API_TYPES_ENUM.ReportExternalUserItem,
  "getReportsReportExternalUsersExternalUser": TENANT_API_TYPES_ENUM.ReportExternalUserItem,
  "deleteReportsReportExternalUsersExternalUser": null,
  "patchReportsReportExternalUsersExternalUser": TENANT_API_TYPES_ENUM.ReportExternalUserItem,
  "getReportsLookup": TENANT_API_TYPES_ENUM.DataLookupItems,
  "getReportsReportMessages": TENANT_API_TYPES_ENUM.MessageItems,
  "getReportsReportNotes": TENANT_API_TYPES_ENUM.NoteItems,
  "getReportParticipantsParticipantReportFilesFile": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "deleteReportParticipantsParticipantReportFilesFile": null,
  "postReportParticipantsParticipantReportFiles": TENANT_API_TYPES_ENUM.ParticipantReportItem,
  "getReportsReportParticipants": TENANT_API_TYPES_ENUM.ParticipantReportItems,
  "postReportsReportParticipants": null,
  "getReportParticipantsParticipantReport": TENANT_API_TYPES_ENUM.ParticipantReportItem,
  "deleteReportParticipantsParticipantReport": null,
  "patchReportParticipantsParticipantReport": TENANT_API_TYPES_ENUM.ParticipantReportItem,
  "getReportsReportPotentialExternalUsers": TENANT_API_TYPES_ENUM.ExternalUserItems,
  "getReportsReportPotentialUserGroups": TENANT_API_TYPES_ENUM.UserGroupSimpleItems,
  "postReportsReportSnooze": TENANT_API_TYPES_ENUM.UserReportActionItem,
  "deleteReportsReportSnooze": null,
  "patchReportsReportSnooze": TENANT_API_TYPES_ENUM.UserReportActionItem,
  "getReportsReportActiveInformantLinks": TENANT_API_TYPES_ENUM.InformantLinkItem,
  "getReportsIdAvatar": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getReportsReport": TENANT_API_TYPES_ENUM.ReportItem,
  "patchReportsReport": TENANT_API_TYPES_ENUM.ReportItem,
  "postReportsReportConversations": null,
  "getReportsReportConversations": TENANT_API_TYPES_ENUM.ReportConversationItems,
  "getReportsReportFilesFile": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "deleteReportsReportFilesFile": null,
  "postReportsReportFiles": null,
  "getReportsReportFiles": TENANT_API_TYPES_ENUM.MediaItems,
  "deleteReportsReportGroupMembersUserGroup": null,
  "postReportsReportGroupMemberships": TENANT_API_TYPES_ENUM.ReportItem,
  "deleteReportsReportMembersUser": null,
  "getReportsReportMemberships": TENANT_API_TYPES_ENUM.ReportMembershipItems,
  "getReportsReportMembershipSummaries": TENANT_API_TYPES_ENUM.ReportMembershipSummaryItem,
  "getReportsReportMembershipsUserGroups": TENANT_API_TYPES_ENUM.MembershipUserGroupWithUsersItems,
  "getReportsReportMembershipsExternalUsers": TENANT_API_TYPES_ENUM.ReportMembershipItems,
  "postReportsReportMemberships": TENANT_API_TYPES_ENUM.ReportItem,
  "postReportsReportOwners": TENANT_API_TYPES_ENUM.ReportItem,
  "patchReportsReportOwnersOwner": TENANT_API_TYPES_ENUM.ReportItem,
  "getReportsReportPotentialMembers": TENANT_API_TYPES_ENUM.UserSimpleItems,
  "postReportsReportProjects": TENANT_API_TYPES_ENUM.ReportItem,
  "deleteReportsReportProjectsProject": TENANT_API_TYPES_ENUM.ReportItem,
  "getReportsReportRedactions": TENANT_API_TYPES_ENUM.RedactionItems,
  "postReportsReportRedactions": null,
  "deleteReportsReportRedactionsRedaction": null,
  "putReportsReportRetentionPoliciesRetentionPolicy": TENANT_API_TYPES_ENUM.ReportItem,
  "deleteReportsReportRetentionPolicies": TENANT_API_TYPES_ENUM.ReportItem,
  "getReportsReportSearch": TENANT_API_TYPES_ENUM.DeprecatedReportSearchItem,
  "getReportStatuses": TENANT_API_TYPES_ENUM.ReportStatusItems,
  "postReportStatuses": null,
  "deleteReportStatusesReportStatus": null,
  "patchReportStatusesReportStatus": TENANT_API_TYPES_ENUM.ReportStatusItem,
  "patchReportStatusesReportStatusDefaults": TENANT_API_TYPES_ENUM.ReportStatusItem,
  "patchReportStatusesReportStatusOrder": TENANT_API_TYPES_ENUM.ReportStatusItems,
  "getReportsReportSteps": TENANT_API_TYPES_ENUM.StepItems,
  "postReportsReportStepsStepSubmissions": null,
  "getReportsReportTags": TENANT_API_TYPES_ENUM.TagSimpleItems,
  "postReportsReportTags": TENANT_API_TYPES_ENUM.ReportItem,
  "patchReportsReportTags": TENANT_API_TYPES_ENUM.ReportItem,
  "deleteReportsReportTagsTag": null,
  "getReportsReportVersionedReports": TENANT_API_TYPES_ENUM.ReportVersionItems,
  "postReportsReportVersionedReports": null,
  "getReportsReportReportWorkflows": TENANT_API_TYPES_ENUM.ReportWorkflowItems,
  "patchReportsReportReportWorkflowsReportWorkflow": TENANT_API_TYPES_ENUM.ReportWorkflowItem,
  "putReportsReportWorkflowsWorkflow": null,
  "postInformantAnswerMedias": TENANT_API_TYPES_ENUM.AnswerMediaItem,
  "postInformantForgotPassword": null,
  "getInformantResetPassword": TENANT_API_TYPES_ENUM.SuccessStatus,
  "postInformantResetPassword": null,
  "postInformantAuthLogin": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "getInformantMe": TENANT_API_TYPES_ENUM.InformantItem,
  "postInformantAuthLogout": null,
  "postInformantAuthRefresh": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "getInformantAuthVerify": TENANT_API_TYPES_ENUM.AuthSuccessItem,
  "postInformantAuthTranslations": TENANT_API_TYPES_ENUM.AuthTranslationItem,
  "postInformantAuthWebsockets": TENANT_API_TYPES_ENUM.WebsocketAuthItem,
  "getInformantChannels": TENANT_API_TYPES_ENUM.ChannelItems,
  "patchInformantConversationsConversation": null,
  "postInformantConversationsConversationDeletedMessages": null,
  "postInformantDraftedReports": TENANT_API_TYPES_ENUM.InformantReportItem,
  "getInformantElementsElementFilesFile": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getInformantElementsElementFiles": TENANT_API_TYPES_ENUM.MediaItems,
  "getInformantFilesFileMeta": TENANT_API_TYPES_ENUM.MediaMetaItem,
  "postInformantGuestInformant": null,
  "patchInformantGuestInformant": TENANT_API_TYPES_ENUM.InformantItem,
  "getInformantMediasMedia": TENANT_API_TYPES_ENUM.MediaItem,
  "getInformantMediasMediaDownload": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getInformantSelectableUsers": TENANT_API_TYPES_ENUM.UserItems,
  "getInformantSubmissiblesSubmissibleSubmissions": TENANT_API_TYPES_ENUM.SubmissionItems,
  "getInformantSubmissiblesSubmissibleSummary": TENANT_API_TYPES_ENUM.SubmissibleSummaryItem,
  "postInformantSubmittedReports": TENANT_API_TYPES_ENUM.InformantReportItem,
  "getInformantReportsReportStepsStepAnswers": TENANT_API_TYPES_ENUM.SubmissionItem,
  "deleteInformantReportsReport": null,
  "patchInformantReportsReport": TENANT_API_TYPES_ENUM.InformantReportItem,
  "getInformantReportsReportConversations": TENANT_API_TYPES_ENUM.ConversationSimpleItems,
  "getInformantReportsReportFilesFile": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getInformantReportsReportFiles": TENANT_API_TYPES_ENUM.MediaItems,
  "postInformantReportsReportFiles": null,
  "deleteInformantReportsReportFilesFile": null,
  "getInformantMobileDeepLinks": TENANT_API_TYPES_ENUM.QrCodeItem,
  "getInformantReportsReportQrCodes": TENANT_API_TYPES_ENUM.QrCodeItem,
  "getInformantReportsReportSteps": TENANT_API_TYPES_ENUM.StepItems,
  "postInformantReportsReportStepsStepSubmissions": null,
  "getInformantReportsSummary": TENANT_API_TYPES_ENUM.ReportSummaryItems,
  "getInformantReportsReportSummary": TENANT_API_TYPES_ENUM.ReportSummaryItem,
  "getInformantReportsReportVersionedReports": TENANT_API_TYPES_ENUM.ReportVersionItems,
  "postInformantInformants": TENANT_API_TYPES_ENUM.InformantItem,
  "patchInformantInformantsInformant": TENANT_API_TYPES_ENUM.InformantItem,
  "deleteInformantInformantsInformantEmail": null,
  "patchInformantInformantsInformantEmail": TENANT_API_TYPES_ENUM.InformantItem,
  "postInformantInformantLinks": TENANT_API_TYPES_ENUM.InformantLinkItem,
  "getInformantInformantLinksInformantLinkCode": TENANT_API_TYPES_ENUM.InformantLinkItem,
  "getInformantPushTokens": TENANT_API_TYPES_ENUM.InformantPushTokenItems,
  "postInformantPushTokens": null,
  "deleteInformantPushTokensInformantPushToken": null,
  "postInformantLinks": null,
  "getInformantLinksCode": TENANT_API_TYPES_ENUM.InformantLinkItem,
  "getWorkflows": TENANT_API_TYPES_ENUM.WorkflowItems,
  "postWorkflows": TENANT_API_TYPES_ENUM.WorkflowItem,
  "getWorkflowsWorkflow": TENANT_API_TYPES_ENUM.WorkflowItem,
  "patchWorkflowsWorkflow": TENANT_API_TYPES_ENUM.WorkflowItem,
  "getWorkflowsWorkflowExportedSubmissions": TENANT_API_TYPES_ENUM.EmptyResponseBody,
  "getWorkflowStatuses": TENANT_API_TYPES_ENUM.WorkflowStatusItems
}

export const PARAMS_TYPE = 'paramsType' as const

    /**
     * Support __typename lookup by method.path. Avoids expensive rewrite of `fetchFn` calls.
     * Params Type defined for all operations with:
     *   Status: 200
     *   Content-Type: application/json
     *   Content-Type: multipart/form-data
     */
export const TENANT_API_PATH_PARAMS_TYPE_MAP: Record<'get' | 'post' | 'put' | 'delete' | 'patch', Record<string, TENANT_API_TYPES_ENUM | null>> = {
  "delete": {
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/memberships/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/enabled-translations$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translation-settings$": null,
    "^/v3/archived-retention-policies/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/push-tokens/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/group-members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/group-members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+/email$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/retention-policies$": null,
    "^/v3/retention-policies/[a-zA-Z0-9-_]+$": null,
    "^/v3/auth/saml2/users/[a-zA-Z0-9-_]+/inactive$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/end-project-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/system-messages/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/retention-policies$": null,
    "^/v3/group-conditions/[a-zA-Z0-9-_]+$": null,
    "^/v3/end-whisper-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/enabled-translations$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/translation-settings$": null,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/user-groups/[a-zA-Z0-9-_]+/users/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/projects/[a-zA-Z0-9-_]+$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/external-users/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/members/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/group-actions/[a-zA-Z0-9-_]+$": null,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": null,
    "^/v3/participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/logics$": null,
    "^/v3/invitations/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/internal-notes/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/charts/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags/[a-zA-Z0-9-_]+$": null,
    "^/v3/conditions/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/locked-whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/inactive$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/avatars$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+$": null,
    "^/v3/actions/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+$": null,
    "^/v3/steps/[a-zA-Z0-9-_]+$": null,
    "^/v3/tags/[a-zA-Z0-9-_]+$": null
  },
  "patch": {
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/landing-page-block-visibilities$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-automation-groups$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-automations$": null,
    "^/v3/organization-settings/[a-zA-Z0-9-_]+/logos$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/whisper-workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/visible-landing-page-blocks$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts/[a-zA-Z0-9-_]+$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-logics$": null,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": null,
    "^/v3/organization-settings/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": null,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+/defaults$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/default$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+/defaults$": null,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+$": null,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+/order$": null,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+/email$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+/order$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/notification-preferences$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+/order$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+/operators$": null,
    "^/v3/group-conditions/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/dashboard-preferences$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/reorder-to-step$": null,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/language-preferences$": null,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/owners/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/active-automations$": null,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/conversations/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/owners/[a-zA-Z0-9-_]+$": null,
    "^/v3/group-actions/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/guest-whisperer$": null,
    "^/v3/messages/[a-zA-Z0-9-_]+/replies$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+$": null,
    "^/v3/internal-notes/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/charts/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/order$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/step-orders$": null,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": null,
    "^/v3/workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/avatars$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/comments/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+$": null,
    "^/v3/messages/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/email$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/roles$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/steps/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+$": null
  },
  "post": {
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/deleted-messages$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+/tokens$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/messages$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/enabled-translations$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translation-settings$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+/conditions$": null,
    "^/v3/archived-retention-policies/[a-zA-Z0-9-_]+$": null,
    "^/v3/conversations/[a-zA-Z0-9-_]+/deleted-messages$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/landing-page-blocks$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/memberships$": null,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+/actions$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/conversations/[a-zA-Z0-9-_]+/internal-notes$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/copy$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translations$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/element-option-translations$": null,
    "^/v3/conversations/[a-zA-Z0-9-_]+/messages$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/auth/saml2/users/[a-zA-Z0-9-_]+/inactive$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/downloads$": null,
    "^/v3/archived-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+/conditions$": null,
    "^/v3/end-project-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/group-memberships$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/mobile-deep-links$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/element-options$": null,
    "^/v3/end-whisper-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/group-memberships$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+/actions$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/element-translations$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/enabled-translations$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/translation-settings$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/generate-summary$": null,
    "^/v3/invitations/[a-zA-Z0-9-_]+/accept$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/translations$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/memberships$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversations$": null,
    "^/v3/archived-workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/step-translations$": null,
    "^/v3/custom-translations/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/participants$": null,
    "^/v3/whisperer/auth/translations$": null,
    "^/v3/whisperer/submitted-whispers$": null,
    "^/v3/user-groups/[a-zA-Z0-9-_]+/users$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships$": null,
    "^/v3/archived-channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/comments$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions$": null,
    "^/v3/whisperer/forgot-password$": null,
    "^/v3/whisperer/auth/websockets$": null,
    "^/v3/whisperer/drafted-whispers$": null,
    "^/v3/whisperer/guest-whisperer$": null,
    "^/v3/whisperer/whisperer-links$": null,
    "^/v3/auth/external-data-source$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/qr-codes$": null,
    "^/v3/duplicated-landing-pages$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/valid-password$": null,
    "^/v3/whisperer/reset-password$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/logics$": null,
    "^/v3/emailed-whisperer-links$": null,
    "^/v3/live-workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/owners$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/projects$": null,
    "^/v3/whisperer/answer-medias$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/files$": null,
    "^/scim/auth/token/refresh$": null,
    "^/v3/whisperer/auth/refresh$": null,
    "^/v3/analytics/dashboards$": null,
    "^/v3/duplicated-workflows$": null,
    "^/v3/live-channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/locked-whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags$": null,
    "^/scim/auth/token/revoke$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/owners$": null,
    "^/v3/whisperer/auth/logout$": null,
    "^/v3/whisperer/whisperers$": null,
    "^/v3/whisperer/push-tokens$": null,
    "^/v3/duplicated-channels$": null,
    "^/v3/duplicated-elements$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/whisperer/auth/login$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/inactive$": null,
    "^/v3/read-notifications$": null,
    "^/v3/retention-policies$": null,
    "^/v3/seen-notifications$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": null,
    "^/v3/auth/translations$": null,
    "^/v3/automation-groups$": null,
    "^/v3/live-landing-pages$": null,
    "^/v3/submitted-whispers$": null,
    "^/v3/analytics/charts$": null,
    "^/v3/data-exports/[a-zA-Z0-9-_]+$": null,
    "^/v3/project-statuses$": null,
    "^/v3/auth/data-export$": null,
    "^/v3/forgot-password$": null,
    "^/v3/auth/enabled-2fa$": null,
    "^/v3/auth/websockets$": null,
    "^/v3/drafted-whispers$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/steps$": null,
    "^/v3/auth/guest/token$": TENANT_API_TYPES_ENUM.PostAuthGuestTokenParams,
    "^/v3/whisper-statuses$": null,
    "^/v3/whisperer-links$": null,
    "^/v3/reset-password$": null,
    "^/v3/auth/analytics$": null,
    "^/v3/auth/platforms$": null,
    "^/v3/stack-versions$": null,
    "^/scim/auth/token$": null,
    "^/v3/external-users$": null,
    "^/v3/answer-medias$": null,
    "^/v3/landing-pages$": null,
    "^/v3/query-configs$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": null,
    "^/v3/participants$": null,
    "^/v3/auth/refresh$": null,
    "^/v3/automations$": null,
    "^/v3/invitations$": null,
    "^/v3/auth/logout$": null,
    "^/v3/user-groups$": null,
    "^/v3/auth/login$": null,
    "^/v3/workflows$": null,
    "^/v3/channels$": null,
    "^/v3/elements$": null,
    "^/v3/projects$": null,
    "^/v3/whispers$": null,
    "^/v3/tags$": TENANT_API_TYPES_ENUM.PostTagsParams
  },
  "get": {
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/potential-user-groups$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/submissibles/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/messages$": TENANT_API_TYPES_ENUM.GetInformantConversationsConversationMessagesParams,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/potential-users$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/submissions-answers$": null,
    "^/v3/whisperer/whisperer-links/[a-zA-Z0-9-_]+$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-logics$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/answers$": null,
    "^/v3/whisperer/submissibles/[a-zA-Z0-9-_]+/summary$": null,
    "^/v3/latest-organization-images/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/answers$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships/external-users$": TENANT_API_TYPES_ENUM.GetReportsReportMembershipsExternalUsersParams,
    "^/v3/workflows/[a-zA-Z0-9-_]+/exported-submissions$": TENANT_API_TYPES_ENUM.GetWorkflowsWorkflowExportedSubmissionsParams,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-external-users$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/conversations$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/potential-user-groups$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships/user-groups$": TENANT_API_TYPES_ENUM.GetReportsReportMembershipsUserGroupsParams,
    "^/v3/conversations/[a-zA-Z0-9-_]+/messages$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/active-whisperer-links$": null,
    "^/v3/whisperer/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/redactions$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-page-blocks$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/notification-preferences$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-user-groups$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/membership-summaries$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-page-block-images/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/preview-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/potential-members$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/project-workflows$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/exported-whispers$": TENANT_API_TYPES_ENUM.GetChannelsChannelExportedReportsParams,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/summary$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/element-options$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversation-meta$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-members$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": null,
    "^/v3/whisperer/elements/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/qr-codes$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/summary$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/medias$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps$": null,
    "^/v3/submissions/[a-zA-Z0-9-_]+/answers$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/whisper-workflows$": null,
    "^/v3/whisperer/medias/[a-zA-Z0-9-_]+/download$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps$": TENANT_API_TYPES_ENUM.GetInformantReportsReportStepsParams,
    "^/v3/elements/[a-zA-Z0-9-_]+/automations$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversations$": null,
    "^/v3/public-medias/[a-zA-Z0-9-_]+/download$": TENANT_API_TYPES_ENUM.GetPublicMediasMediaDownloadParams,
    "^/v3/custom-translations/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.GetCustomTranslationsModelIdParams,
    "^/v3/whispers/[a-zA-Z0-9-_]+/participants$": null,
    "^/v3/whisperer/channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.GetElementsElementFilesFileParams,
    "^/v3/projects/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships$": TENANT_API_TYPES_ENUM.GetReportsReportMembershipsParams,
    "^/v3/whisperer/selectable-users$": null,
    "^/v3/whisperer/mobile-deep-links$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/comments$": null,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": null,
    "^/v3/participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/activities$": TENANT_API_TYPES_ENUM.GetReportsReportActivitiesParams,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions$": null,
    "^/v3/whisperer/whispers/summary$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/reset-password$": TENANT_API_TYPES_ENUM.GetInformantResetPasswordParams,
    "^/v3/whisperer/files/[a-zA-Z0-9-_]+/meta$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+$": null,
    "^/v3/invitations/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-page-block-types$": null,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+/t$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/messages$": TENANT_API_TYPES_ENUM.GetReportsReportMessagesParams,
    "^/v3/elements/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.GetElementsElementFilesParams,
    "^/v3/organization-settings$": null,
    "^/v3/steps/[a-zA-Z0-9-_]+/automations$": null,
    "^/v3/unowned-whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/medias/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/dashboards$": null,
    "^/v3/data-exports/download$": TENANT_API_TYPES_ENUM.GetDataExportsDownloadParams,
    "^/v3/medias/[a-zA-Z0-9-_]+/download$": null,
    "^/v3/potential-user-groups$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa-qr-codes$": TENANT_API_TYPES_ENUM.GetUsersUser2FaQrCodesParams,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/bundled-translations$": TENANT_API_TYPES_ENUM.GetBundledTranslationsParams,
    "^/v3/whispers/[a-zA-Z0-9-_]+/search$": TENANT_API_TYPES_ENUM.GetReportsReportSearchParams,
    "^/v3/whisperer/auth/verify$": null,
    "^/v3/whisperer/push-tokens$": null,
    "^/v3/custom-landing-pages$": null,
    "^/v3/participants-config$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/notes$": TENANT_API_TYPES_ENUM.GetReportsReportNotesParams,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.GetReportsReportFilesParams,
    "^/v3/whispers/[a-zA-Z0-9-_]+/steps$": null,
    "^/v3/whisperer-links/[a-zA-Z0-9-_]+$": null,
    "^/v3/deleted-projects/[a-zA-Z0-9-_]+$": null,
    "^/v3/filtered-workflows$": TENANT_API_TYPES_ENUM.GetFilteredWorkflowsParams,
    "^/v3/notification-types$": null,
    "^/v3/retention-policies$": TENANT_API_TYPES_ENUM.GetRetentionPoliciesParams,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": null,
    "^/v3/whisperer/channels$": null,
    "^/v3/workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers$": null,
    "^/v3/automation-groups$": TENANT_API_TYPES_ENUM.GetAutomationGroupsParams,
    "^/v3/deleted-whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/filtered-channels$": TENANT_API_TYPES_ENUM.GetFilteredChannelsParams,
    "^/v3/workflow-statuses$": null,
    "^/v3/auth/asm/disabled$": null,
    "^/v3/auth/guest/verify$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/channel-statuses$": null,
    "^/v3/analytics/charts$": null,
    "^/v3/deleted-projects$": null,
    "^/v3/filtered-whispers$": TENANT_API_TYPES_ENUM.GetFilteredReportsParams,
    "^/v3/projects/[a-zA-Z0-9-_]+$": null,
    "^/v3/project-statuses$": null,
    "^/v3/selectable-users$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/avatar$": null,
    "^/v3/condition-types$": null,
    "^/v3/deleted-whispers$": TENANT_API_TYPES_ENUM.GetDeletedReportsParams,
    "^/v3/forms/[a-zA-Z0-9-_]+/steps$": null,
    "^/v3/auth/help-center$": null,
    "^/v3/public-channels$": null,
    "^/v3/whisper-statuses$": null,
    "^/v3/reset-password$": TENANT_API_TYPES_ENUM.GetResetPasswordParams,
    "^/v3/filtered-users$": TENANT_API_TYPES_ENUM.GetFilteredUsersParams,
    "^/v3/stack-versions$": null,
    "^/v3/internal-forms$": null,
    "^/v3/live-workflows$": null,
    "^/v3/notifications$": TENANT_API_TYPES_ENUM.GetNotificationsParams,
    "^/v3/external-users$": TENANT_API_TYPES_ENUM.GetExternalUsersParams,
    "^/v3/whispers-lookup$": TENANT_API_TYPES_ENUM.GetReportsLookupParams,
    "^/v3/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/channel-types$": null,
    "^/v3/chat-channels$": null,
    "^/v3/element-types$": null,
    "^/v3/feature-flags$": null,
    "^/v3/landing-pages$": TENANT_API_TYPES_ENUM.GetLandingPagesParams,
    "^/v3/live-channels$": null,
    "^/v3/query-configs$": TENANT_API_TYPES_ENUM.GetQueryConfigsParams,
    "^/v3/trigger-types$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": null,
    "^/v3/participants$": TENANT_API_TYPES_ENUM.GetParticipantsParams,
    "^/v3/action-types$": null,
    "^/v3/automations$": TENANT_API_TYPES_ENUM.GetAutomationsParams,
    "^/v3/invitations$": TENANT_API_TYPES_ENUM.GetInvitationsParams,
    "^/v3/whisperer/me$": null,
    "^/v3/auth/verify$": null,
    "^/v3/redactions$": null,
    "^/v3/dashboards$": null,
    "^/v3/user-groups$": null,
    "^/v3/countries$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+$": null,
    "^/v3/timezones$": null,
    "^/v3/users/[a-zA-Z0-9-_]+$": null,
    "^/v3/workflows$": TENANT_API_TYPES_ENUM.GetWorkflowsParams,
    "^/v3/channels$": TENANT_API_TYPES_ENUM.GetChannelsParams,
    "^/v3/hotlines$": null,
    "^/v3/projects$": null,
    "^/v3/locales$": null,
    "^/v3/tags/[a-zA-Z0-9-_]+$": null,
    "^/v3/guest/me$": null,
    "^/v3/whispers$": TENANT_API_TYPES_ENUM.GetReportsParams,
    "^/v3/audits$": TENANT_API_TYPES_ENUM.GetAuditsParams,
    "^/v3/search$": null,
    "^/v3/forms$": null,
    "^/v3/roles$": null,
    "^/v3/users$": TENANT_API_TYPES_ENUM.GetUsersParams,
    "^/v3/tags$": null,
    "^/v3/me$": null
  },
  "put": {
    "^/v3/projects/[a-zA-Z0-9-_]+/retention-policies/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/retention-policies/[a-zA-Z0-9-_]+$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+/trigger-types/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/project-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": null,
    "^/v3/conditions/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/password$": null,
    "^/v3/actions/[a-zA-Z0-9-_]+$": null
  }
}

export const REQUEST_BODY_TYPE = 'requestBodyType' as const

    /**
     * Support __typename lookup by method.path. Avoids expensive rewrite of `fetchFn` calls.
     * Request Body Type defined for all operations with:
     *   Status: 200
     *   Content-Type: application/json
     *   Content-Type: multipart/form-data
     */
export const TENANT_API_PATH_REQUEST_BODY_TYPE_MAP: Record<'get' | 'post' | 'put' | 'delete' | 'patch', Record<string, TENANT_API_TYPES_ENUM | null>> = {
  "delete": {
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/memberships/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/enabled-translations$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translation-settings$": null,
    "^/v3/archived-retention-policies/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/push-tokens/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/group-members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/group-members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+/email$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/retention-policies$": null,
    "^/v3/retention-policies/[a-zA-Z0-9-_]+$": null,
    "^/v3/auth/saml2/users/[a-zA-Z0-9-_]+/inactive$": TENANT_API_TYPES_ENUM.SamlUserInactiveRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/end-project-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/system-messages/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/retention-policies$": null,
    "^/v3/group-conditions/[a-zA-Z0-9-_]+$": null,
    "^/v3/end-whisper-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/enabled-translations$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/translation-settings$": null,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/user-groups/[a-zA-Z0-9-_]+/users/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/projects/[a-zA-Z0-9-_]+$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/external-users/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/members/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/group-actions/[a-zA-Z0-9-_]+$": null,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": null,
    "^/v3/participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/logics$": null,
    "^/v3/invitations/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/internal-notes/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/charts/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags/[a-zA-Z0-9-_]+$": null,
    "^/v3/conditions/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/locked-whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/inactive$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/avatars$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+$": null,
    "^/v3/actions/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+$": null,
    "^/v3/steps/[a-zA-Z0-9-_]+$": null,
    "^/v3/tags/[a-zA-Z0-9-_]+$": null
  },
  "patch": {
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/landing-page-block-visibilities$": TENANT_API_TYPES_ENUM.LandingPageVisibilityRequestBody,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-automation-groups$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-automations$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/organization-settings/[a-zA-Z0-9-_]+/logos$": TENANT_API_TYPES_ENUM.AbstractImageRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/whisper-workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportReportWorkflowUpdateRequest,
    "^/v3/channels/[a-zA-Z0-9-_]+/visible-landing-page-blocks$": TENANT_API_TYPES_ENUM.ChannelVisibleLandingPageBlockRequestBody,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CustomDashboardUpdateRequestBody,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-logics$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": TENANT_API_TYPES_ENUM.LandingPageBlockImageRequestBody,
    "^/v3/organization-settings/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.OrganizationSettingRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportExternalUserUpdateRequestBody,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+/defaults$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/default$": TENANT_API_TYPES_ENUM.CustomDashboardDefaultRequestBody,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportParticipantPatchRequestBody,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+/defaults$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.LandingPageBlockRequestBody,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+/order$": TENANT_API_TYPES_ENUM.OrderRequestBody,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+/email$": TENANT_API_TYPES_ENUM.InformantEmailUpdateRequestBody,
    "^/v3/element-options/[a-zA-Z0-9-_]+/order$": TENANT_API_TYPES_ENUM.OrderRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/notification-preferences$": TENANT_API_TYPES_ENUM.UserNotificationPreferenceRequestBody,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationRequestBody,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+/order$": TENANT_API_TYPES_ENUM.OrderRequestBody,
    "^/v3/automations/[a-zA-Z0-9-_]+/operators$": TENANT_API_TYPES_ENUM.AutomationOperatorRequestBody,
    "^/v3/group-conditions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationConditionRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/dashboard-preferences$": TENANT_API_TYPES_ENUM.UserDashboardPreferencesRequestBody,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CustomDashboardRequestBody,
    "^/v3/elements/[a-zA-Z0-9-_]+/reorder-to-step$": TENANT_API_TYPES_ENUM.OrderRequestBody,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectStatusUpdateRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/language-preferences$": TENANT_API_TYPES_ENUM.UserLanguagePreferenceRequestBody,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.InformantUpdateRequestBody,
    "^/v3/element-options/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ElementOptionRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/owners/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/active-automations$": TENANT_API_TYPES_ENUM.UserAutomationRequestBody,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportStatusUpdateRequestBody,
    "^/v3/conversations/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/owners/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/group-actions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationActionRequestBody,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.LandingPagePatchRequestBody,
    "^/v3/participants/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ParticipantRequestBody,
    "^/v3/whisperer/guest-whisperer$": TENANT_API_TYPES_ENUM.InformantGuestUpdateRequestBody,
    "^/v3/messages/[a-zA-Z0-9-_]+/replies$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.PatchInformantReportRequestBody,
    "^/v3/automations/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationRequestBody,
    "^/v3/internal-notes/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ConversationMessageRequestBody,
    "^/v3/analytics/charts/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CustomChartsRequestBody,
    "^/v3/elements/[a-zA-Z0-9-_]+/order$": TENANT_API_TYPES_ENUM.OrderRequestBody,
    "^/v3/forms/[a-zA-Z0-9-_]+/step-orders$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.UserGroupRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": TENANT_API_TYPES_ENUM.UserReportActionRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": TENANT_API_TYPES_ENUM.ReportTagStoreRequestBody,
    "^/v3/workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.WorkflowUpdateRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/avatars$": TENANT_API_TYPES_ENUM.AbstractImageRequestBody,
    "^/v3/channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelUpdateRequestBody,
    "^/v3/comments/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CommentUpdateRequestBody,
    "^/v3/elements/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ElementPatchRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectRequestBody,
    "^/v3/messages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/email$": TENANT_API_TYPES_ENUM.UserEmailRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/roles$": TENANT_API_TYPES_ENUM.UserRolesRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportUpdateRequestBody,
    "^/v3/steps/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.StepUpdateRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.UserUpdateRequestBody
  },
  "post": {
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/deleted-messages$": TENANT_API_TYPES_ENUM.PostInformantConversationDeletedMessageRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+/tokens$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": TENANT_API_TYPES_ENUM.SubmissionRequestBody,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/messages$": TENANT_API_TYPES_ENUM.ConversationMessageRequestBody,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/enabled-translations$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translation-settings$": TENANT_API_TYPES_ENUM.LandingPageTranslationSettingRequestBody,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": TENANT_API_TYPES_ENUM.ReportStepSubmissionStoreRequestBody,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+/conditions$": TENANT_API_TYPES_ENUM.AutomationConditionRequestBody,
    "^/v3/archived-retention-policies/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/conversations/[a-zA-Z0-9-_]+/deleted-messages$": TENANT_API_TYPES_ENUM.ConversationDeletedMessageRequestBody,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/landing-page-blocks$": TENANT_API_TYPES_ENUM.LandingPageLandingPageBlockRequestBody,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaFileRequestBody,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/memberships$": TENANT_API_TYPES_ENUM.CustomDashboardMembershipRequestBody,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": TENANT_API_TYPES_ENUM.AbstractImageRequestBody,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+/actions$": TENANT_API_TYPES_ENUM.AutomationActionRequestBody,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/conversations/[a-zA-Z0-9-_]+/internal-notes$": TENANT_API_TYPES_ENUM.ConversationMessageRequestBody,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/copy$": TENANT_API_TYPES_ENUM.CustomDashboardCopyRequestBody,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translations$": TENANT_API_TYPES_ENUM.TranslationRequestBody,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts$": TENANT_API_TYPES_ENUM.CustomDashboardStoreRequestBody,
    "^/v3/forms/[a-zA-Z0-9-_]+/element-option-translations$": TENANT_API_TYPES_ENUM.TranslationValueRequestBody,
    "^/v3/conversations/[a-zA-Z0-9-_]+/messages$": TENANT_API_TYPES_ENUM.ConversationMessageRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": TENANT_API_TYPES_ENUM.ReportStepSubmissionStoreRequestBody,
    "^/v3/auth/saml2/users/[a-zA-Z0-9-_]+/inactive$": TENANT_API_TYPES_ENUM.SamlUserInactiveRequestBody,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/downloads$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/archived-landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/automations/[a-zA-Z0-9-_]+/conditions$": TENANT_API_TYPES_ENUM.AutomationConditionRequestBody,
    "^/v3/end-project-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/group-memberships$": TENANT_API_TYPES_ENUM.ProjectGroupMembershipRequestBody,
    "^/v3/channels/[a-zA-Z0-9-_]+/mobile-deep-links$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/elements/[a-zA-Z0-9-_]+/element-options$": TENANT_API_TYPES_ENUM.ElementOptionRequestBody,
    "^/v3/end-whisper-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/group-memberships$": TENANT_API_TYPES_ENUM.ReportGroupMembershipStoreRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": TENANT_API_TYPES_ENUM.ReportVersionedReportRequestBody,
    "^/v3/automations/[a-zA-Z0-9-_]+/actions$": TENANT_API_TYPES_ENUM.AutomationActionRequestBody,
    "^/v3/forms/[a-zA-Z0-9-_]+/element-translations$": TENANT_API_TYPES_ENUM.TranslationRequestBody,
    "^/v3/forms/[a-zA-Z0-9-_]+/enabled-translations$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/forms/[a-zA-Z0-9-_]+/translation-settings$": TENANT_API_TYPES_ENUM.FormTranslationSettingRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/generate-summary$": null,
    "^/v3/invitations/[a-zA-Z0-9-_]+/accept$": TENANT_API_TYPES_ENUM.AcceptedInvitationRequestBody,
    "^/v3/channels/[a-zA-Z0-9-_]+/translations$": TENANT_API_TYPES_ENUM.TranslationNameRequestBody,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaFileRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/memberships$": TENANT_API_TYPES_ENUM.ProjectMembershipRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users$": TENANT_API_TYPES_ENUM.ReportExternalUserStoreRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversations$": TENANT_API_TYPES_ENUM.ConversationRequestBody,
    "^/v3/archived-workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/forms/[a-zA-Z0-9-_]+/step-translations$": TENANT_API_TYPES_ENUM.TranslationNameRequestBody,
    "^/v3/custom-translations/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CustomTranslationRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/participants$": TENANT_API_TYPES_ENUM.ReportParticipantStoreRequestBody,
    "^/v3/whisperer/auth/translations$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whisperer/submitted-whispers$": TENANT_API_TYPES_ENUM.PostInformantSubmittedReportRequestBody,
    "^/v3/user-groups/[a-zA-Z0-9-_]+/users$": TENANT_API_TYPES_ENUM.UserGroupUsersRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships$": TENANT_API_TYPES_ENUM.ReportMembershipRequestBody,
    "^/v3/archived-channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/comments$": TENANT_API_TYPES_ENUM.ProjectCommentRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions$": TENANT_API_TYPES_ENUM.ReportRedactionRequestBody,
    "^/v3/whisperer/forgot-password$": TENANT_API_TYPES_ENUM.PostAuthForgotPasswordRequestBody,
    "^/v3/whisperer/auth/websockets$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whisperer/drafted-whispers$": TENANT_API_TYPES_ENUM.PostInformantDraftedReportRequestBody,
    "^/v3/whisperer/guest-whisperer$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whisperer/whisperer-links$": TENANT_API_TYPES_ENUM.InformantInformantLinkRequestBody,
    "^/v3/auth/external-data-source$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/channels/[a-zA-Z0-9-_]+/qr-codes$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/duplicated-landing-pages$": TENANT_API_TYPES_ENUM.DuplicatedLandingPageRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers$": TENANT_API_TYPES_ENUM.ProjectReportRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/valid-password$": TENANT_API_TYPES_ENUM.UserValidPasswordRequestBody,
    "^/v3/whisperer/reset-password$": TENANT_API_TYPES_ENUM.PostAuthResetPasswordRequestBody,
    "^/v3/elements/[a-zA-Z0-9-_]+/logics$": TENANT_API_TYPES_ENUM.ElementLogicRequestBody,
    "^/v3/emailed-whisperer-links$": TENANT_API_TYPES_ENUM.EmailedInformantLinkRequestBody,
    "^/v3/live-workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/owners$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/projects$": TENANT_API_TYPES_ENUM.ReportProjectRequestBody,
    "^/v3/whisperer/answer-medias$": TENANT_API_TYPES_ENUM.MediaFileRequestBody,
    "^/v3/elements/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaFileRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaFileRequestBody,
    "^/scim/auth/token/refresh$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whisperer/auth/refresh$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/analytics/dashboards$": TENANT_API_TYPES_ENUM.CustomDashboardRequestBody,
    "^/v3/duplicated-workflows$": TENANT_API_TYPES_ENUM.DuplicatedWorkflowRequestBody,
    "^/v3/live-channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/locked-whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags$": TENANT_API_TYPES_ENUM.ProjectTagStoreRequestBody,
    "^/scim/auth/token/revoke$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": TENANT_API_TYPES_ENUM.UserReportActionRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/owners$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whisperer/auth/logout$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whisperer/whisperers$": TENANT_API_TYPES_ENUM.InformantStoreRequestBody,
    "^/v3/whisperer/push-tokens$": TENANT_API_TYPES_ENUM.InformantPushTokenRequestBody,
    "^/v3/duplicated-channels$": TENANT_API_TYPES_ENUM.DuplicatedChannelRequestBody,
    "^/v3/duplicated-elements$": TENANT_API_TYPES_ENUM.DuplicatedElementRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaFileRequestBody,
    "^/v3/whisperer/auth/login$": TENANT_API_TYPES_ENUM.InformantAuthRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/inactive$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/read-notifications$": TENANT_API_TYPES_ENUM.ReadNotificationRequestBody,
    "^/v3/retention-policies$": TENANT_API_TYPES_ENUM.RetentionPolicyRequestBody,
    "^/v3/seen-notifications$": TENANT_API_TYPES_ENUM.SeenNotificationRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": TENANT_API_TYPES_ENUM.ReportTagStoreRequestBody,
    "^/v3/auth/translations$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/automation-groups$": TENANT_API_TYPES_ENUM.AutomationRequestBody,
    "^/v3/live-landing-pages$": TENANT_API_TYPES_ENUM.LiveLandingPageRequestBody,
    "^/v3/submitted-whispers$": TENANT_API_TYPES_ENUM.SubmittedReportRequestBody,
    "^/v3/analytics/charts$": TENANT_API_TYPES_ENUM.CustomChartsRequestBody,
    "^/v3/data-exports/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.DataExportRequestBody,
    "^/v3/project-statuses$": TENANT_API_TYPES_ENUM.ProjectStatusStoreRequestBody,
    "^/v3/auth/data-export$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/forgot-password$": TENANT_API_TYPES_ENUM.PostAuthForgotPasswordRequestBody,
    "^/v3/auth/enabled-2fa$": TENANT_API_TYPES_ENUM.AuthRequestBody,
    "^/v3/auth/websockets$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/drafted-whispers$": TENANT_API_TYPES_ENUM.DraftedReportRequestBody,
    "^/v3/forms/[a-zA-Z0-9-_]+/steps$": TENANT_API_TYPES_ENUM.FormStepRequestBody,
    "^/v3/auth/guest/token$": null,
    "^/v3/whisper-statuses$": TENANT_API_TYPES_ENUM.ReportStatusStoreRequestBody,
    "^/v3/whisperer-links$": TENANT_API_TYPES_ENUM.InformantLinkRequestBody,
    "^/v3/reset-password$": TENANT_API_TYPES_ENUM.PostAuthResetPasswordRequestBody,
    "^/v3/auth/analytics$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/auth/platforms$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/stack-versions$": TENANT_API_TYPES_ENUM.StackItemRequestBody,
    "^/scim/auth/token$": TENANT_API_TYPES_ENUM.ScimAuthRequestBody,
    "^/v3/external-users$": TENANT_API_TYPES_ENUM.ExternalUserStoreRequestBody,
    "^/v3/answer-medias$": TENANT_API_TYPES_ENUM.MediaFileRequestBody,
    "^/v3/landing-pages$": TENANT_API_TYPES_ENUM.LandingPagePostRequestBody,
    "^/v3/query-configs$": TENANT_API_TYPES_ENUM.PostQueryConfigRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": TENANT_API_TYPES_ENUM.UserMfaRequestBody,
    "^/v3/participants$": TENANT_API_TYPES_ENUM.ParticipantRequestBody,
    "^/v3/auth/refresh$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/automations$": TENANT_API_TYPES_ENUM.AutomationRequestBody,
    "^/v3/invitations$": TENANT_API_TYPES_ENUM.InvitationRequestBody,
    "^/v3/auth/logout$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/user-groups$": TENANT_API_TYPES_ENUM.UserGroupRequestBody,
    "^/v3/auth/login$": TENANT_API_TYPES_ENUM.AuthRequestBody,
    "^/v3/workflows$": TENANT_API_TYPES_ENUM.WorkflowStoreRequestBody,
    "^/v3/channels$": TENANT_API_TYPES_ENUM.ChannelStoreRequestBody,
    "^/v3/elements$": TENANT_API_TYPES_ENUM.ElementStoreRequestBody,
    "^/v3/projects$": TENANT_API_TYPES_ENUM.ProjectRequestBody,
    "^/v3/whispers$": TENANT_API_TYPES_ENUM.ReportListRequestBody,
    "^/v3/tags$": TENANT_API_TYPES_ENUM.TagStoreRequestBody
  },
  "get": {
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/potential-user-groups$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/submissibles/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/messages$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/potential-users$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/submissions-answers$": null,
    "^/v3/whisperer/whisperer-links/[a-zA-Z0-9-_]+$": null,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-logics$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/answers$": null,
    "^/v3/whisperer/submissibles/[a-zA-Z0-9-_]+/summary$": null,
    "^/v3/latest-organization-images/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/answers$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships/external-users$": null,
    "^/v3/workflows/[a-zA-Z0-9-_]+/exported-submissions$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-external-users$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/conversations$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/potential-user-groups$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships/user-groups$": null,
    "^/v3/conversations/[a-zA-Z0-9-_]+/messages$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/active-whisperer-links$": null,
    "^/v3/whisperer/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/redactions$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-page-blocks$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/notification-preferences$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-user-groups$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/membership-summaries$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-page-block-images/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/preview-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/potential-members$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/project-workflows$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/exported-whispers$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/summary$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/element-options$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversation-meta$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-members$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": null,
    "^/v3/whisperer/elements/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/qr-codes$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/summary$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/medias$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps$": null,
    "^/v3/submissions/[a-zA-Z0-9-_]+/answers$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/whisper-workflows$": null,
    "^/v3/whisperer/medias/[a-zA-Z0-9-_]+/download$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/automations$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversations$": null,
    "^/v3/public-medias/[a-zA-Z0-9-_]+/download$": null,
    "^/v3/custom-translations/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/participants$": null,
    "^/v3/whisperer/channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships$": null,
    "^/v3/whisperer/selectable-users$": null,
    "^/v3/whisperer/mobile-deep-links$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/comments$": null,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": null,
    "^/v3/participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/activities$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions$": null,
    "^/v3/whisperer/whispers/summary$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/reset-password$": null,
    "^/v3/whisperer/files/[a-zA-Z0-9-_]+/meta$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+$": null,
    "^/v3/invitations/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-page-block-types$": null,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+/t$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/messages$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/organization-settings$": null,
    "^/v3/steps/[a-zA-Z0-9-_]+/automations$": null,
    "^/v3/unowned-whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/medias/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/dashboards$": null,
    "^/v3/data-exports/download$": null,
    "^/v3/medias/[a-zA-Z0-9-_]+/download$": null,
    "^/v3/potential-user-groups$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa-qr-codes$": null,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/bundled-translations$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/search$": null,
    "^/v3/whisperer/auth/verify$": null,
    "^/v3/whisperer/push-tokens$": null,
    "^/v3/custom-landing-pages$": null,
    "^/v3/participants-config$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/notes$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/steps$": null,
    "^/v3/whisperer-links/[a-zA-Z0-9-_]+$": null,
    "^/v3/deleted-projects/[a-zA-Z0-9-_]+$": null,
    "^/v3/filtered-workflows$": null,
    "^/v3/notification-types$": null,
    "^/v3/retention-policies$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": null,
    "^/v3/whisperer/channels$": null,
    "^/v3/workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers$": null,
    "^/v3/automation-groups$": null,
    "^/v3/deleted-whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/filtered-channels$": null,
    "^/v3/workflow-statuses$": null,
    "^/v3/auth/asm/disabled$": null,
    "^/v3/auth/guest/verify$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/channel-statuses$": null,
    "^/v3/analytics/charts$": null,
    "^/v3/deleted-projects$": null,
    "^/v3/filtered-whispers$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+$": null,
    "^/v3/project-statuses$": null,
    "^/v3/selectable-users$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/avatar$": null,
    "^/v3/condition-types$": null,
    "^/v3/deleted-whispers$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/steps$": null,
    "^/v3/auth/help-center$": null,
    "^/v3/public-channels$": null,
    "^/v3/whisper-statuses$": null,
    "^/v3/reset-password$": null,
    "^/v3/filtered-users$": null,
    "^/v3/stack-versions$": null,
    "^/v3/internal-forms$": null,
    "^/v3/live-workflows$": null,
    "^/v3/notifications$": null,
    "^/v3/external-users$": null,
    "^/v3/whispers-lookup$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/channel-types$": null,
    "^/v3/chat-channels$": null,
    "^/v3/element-types$": null,
    "^/v3/feature-flags$": null,
    "^/v3/landing-pages$": null,
    "^/v3/live-channels$": null,
    "^/v3/query-configs$": null,
    "^/v3/trigger-types$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": null,
    "^/v3/participants$": null,
    "^/v3/action-types$": null,
    "^/v3/automations$": null,
    "^/v3/invitations$": null,
    "^/v3/whisperer/me$": null,
    "^/v3/auth/verify$": null,
    "^/v3/redactions$": null,
    "^/v3/dashboards$": null,
    "^/v3/user-groups$": null,
    "^/v3/countries$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+$": null,
    "^/v3/timezones$": null,
    "^/v3/users/[a-zA-Z0-9-_]+$": null,
    "^/v3/workflows$": null,
    "^/v3/channels$": null,
    "^/v3/hotlines$": null,
    "^/v3/projects$": null,
    "^/v3/locales$": null,
    "^/v3/tags/[a-zA-Z0-9-_]+$": null,
    "^/v3/guest/me$": null,
    "^/v3/whispers$": null,
    "^/v3/audits$": null,
    "^/v3/search$": null,
    "^/v3/forms$": null,
    "^/v3/roles$": null,
    "^/v3/users$": null,
    "^/v3/tags$": null,
    "^/v3/me$": null
  },
  "put": {
    "^/v3/projects/[a-zA-Z0-9-_]+/retention-policies/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/retention-policies/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/automations/[a-zA-Z0-9-_]+/trigger-types/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/project-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyRequestBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.PutReportWorkflowRequestBody,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.PutQueryConfigRequestBody,
    "^/v3/conditions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationConditionRequestBody,
    "^/v3/users/[a-zA-Z0-9-_]+/password$": TENANT_API_TYPES_ENUM.UserPasswordRequestBody,
    "^/v3/actions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationActionRequestBody
  }
}

export const RESPONSE_BODY_TYPE = 'responseBodyType' as const

    /**
     * Support __typename lookup by method.path. Avoids expensive rewrite of `fetchFn` calls.
     * Response Body Type defined for all operations with:
     *   Status: 200
     *   Content-Type: application/json
     *   Content-Type: multipart/form-data
     */
export const TENANT_API_PATH_RESPONSE_BODY_TYPE_MAP: Record<'get' | 'post' | 'put' | 'delete' | 'patch', Record<string, TENANT_API_TYPES_ENUM | null>> = {
  "delete": {
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/memberships/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/enabled-translations$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translation-settings$": null,
    "^/v3/archived-retention-policies/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.RetentionPolicyItem,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CustomDashboardItem,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": null,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/push-tokens/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/group-members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/group-members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+/email$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/retention-policies$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/retention-policies/[a-zA-Z0-9-_]+$": null,
    "^/v3/auth/saml2/users/[a-zA-Z0-9-_]+/inactive$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/end-project-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectStatusItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/system-messages/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/retention-policies$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/group-conditions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/end-whisper-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportStatusItem,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/enabled-translations$": TENANT_API_TYPES_ENUM.FormItem,
    "^/v3/forms/[a-zA-Z0-9-_]+/translation-settings$": null,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/user-groups/[a-zA-Z0-9-_]+/users/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.UserGroupItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/projects/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/element-options/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/members/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.WorkflowItem,
    "^/v3/external-users/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/members/[a-zA-Z0-9-_]+$": null,
    "^/v3/archived-channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/group-actions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": null,
    "^/v3/participants/[a-zA-Z0-9-_]+$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": null,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/logics$": null,
    "^/v3/invitations/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/internal-notes/[a-zA-Z0-9-_]+$": null,
    "^/v3/analytics/charts/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags/[a-zA-Z0-9-_]+$": null,
    "^/v3/conditions/[a-zA-Z0-9-_]+$": null,
    "^/v3/live-channels/[a-zA-Z0-9-_]+$": null,
    "^/v3/locked-whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/inactive$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/users/[a-zA-Z0-9-_]+/avatars$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+$": null,
    "^/v3/actions/[a-zA-Z0-9-_]+$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+$": null,
    "^/v3/steps/[a-zA-Z0-9-_]+$": null,
    "^/v3/tags/[a-zA-Z0-9-_]+$": null
  },
  "patch": {
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/landing-page-block-visibilities$": TENANT_API_TYPES_ENUM.LandingPageBlockItems,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-automation-groups$": TENANT_API_TYPES_ENUM.SuccessItem,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-automations$": TENANT_API_TYPES_ENUM.SuccessItem,
    "^/v3/organization-settings/[a-zA-Z0-9-_]+/logos$": TENANT_API_TYPES_ENUM.OrganizationSettingItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/whisper-workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportWorkflowItem,
    "^/v3/channels/[a-zA-Z0-9-_]+/visible-landing-page-blocks$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CustomDashboardItem,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-logics$": TENANT_API_TYPES_ENUM.SuccessItem,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": TENANT_API_TYPES_ENUM.LandingPageBlockItem,
    "^/v3/organization-settings/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.OrganizationSettingItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportExternalUserItem,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+/defaults$": TENANT_API_TYPES_ENUM.ProjectStatusItem,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/default$": TENANT_API_TYPES_ENUM.SuccessStatus,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ParticipantReportItem,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+/defaults$": TENANT_API_TYPES_ENUM.ReportStatusItem,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+$": null,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.LandingPageBlockItem,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+/order$": TENANT_API_TYPES_ENUM.ProjectStatusItems,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+/email$": TENANT_API_TYPES_ENUM.InformantItem,
    "^/v3/element-options/[a-zA-Z0-9-_]+/order$": TENANT_API_TYPES_ENUM.ElementOptionItems,
    "^/v3/users/[a-zA-Z0-9-_]+/notification-preferences$": TENANT_API_TYPES_ENUM.NotificationPreferenceItems,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+/order$": TENANT_API_TYPES_ENUM.ReportStatusItems,
    "^/v3/automations/[a-zA-Z0-9-_]+/operators$": TENANT_API_TYPES_ENUM.AutomationItem,
    "^/v3/group-conditions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/users/[a-zA-Z0-9-_]+/dashboard-preferences$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CustomDashboardItem,
    "^/v3/elements/[a-zA-Z0-9-_]+/reorder-to-step$": TENANT_API_TYPES_ENUM.StepItems,
    "^/v3/project-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectStatusItem,
    "^/v3/users/[a-zA-Z0-9-_]+/language-preferences$": TENANT_API_TYPES_ENUM.LanguagePreferenceItem,
    "^/v3/whisperer/whisperers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.InformantItem,
    "^/v3/element-options/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ElementOptionItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/owners/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/users/[a-zA-Z0-9-_]+/active-automations$": TENANT_API_TYPES_ENUM.SuccessItem,
    "^/v3/whisper-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportStatusItem,
    "^/v3/conversations/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportConversationItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/owners/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/group-actions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/participants/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ParticipantItem,
    "^/v3/whisperer/guest-whisperer$": TENANT_API_TYPES_ENUM.InformantItem,
    "^/v3/messages/[a-zA-Z0-9-_]+/replies$": TENANT_API_TYPES_ENUM.MessageItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.InformantReportItem,
    "^/v3/automations/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationItem,
    "^/v3/internal-notes/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.NoteItem,
    "^/v3/analytics/charts/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChartItem,
    "^/v3/elements/[a-zA-Z0-9-_]+/order$": TENANT_API_TYPES_ENUM.ElementItems,
    "^/v3/forms/[a-zA-Z0-9-_]+/step-orders$": TENANT_API_TYPES_ENUM.FormItem,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.UserGroupItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": TENANT_API_TYPES_ENUM.UserReportActionItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.WorkflowItem,
    "^/v3/users/[a-zA-Z0-9-_]+/avatars$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/comments/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CommentItem,
    "^/v3/elements/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ElementItem,
    "^/v3/projects/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/messages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.MessageItem,
    "^/v3/users/[a-zA-Z0-9-_]+/email$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/users/[a-zA-Z0-9-_]+/roles$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/steps/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.StepItem,
    "^/v3/users/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.UserItem
  },
  "post": {
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/deleted-messages$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+/tokens$": null,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/messages$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/enabled-translations$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translation-settings$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+/conditions$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/archived-retention-policies/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.RetentionPolicyItem,
    "^/v3/conversations/[a-zA-Z0-9-_]+/deleted-messages$": TENANT_API_TYPES_ENUM.MessageItem,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/landing-page-blocks$": null,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.ParticipantReportItem,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/memberships$": TENANT_API_TYPES_ENUM.MembershipItem,
    "^/v3/landing-page-blocks/[a-zA-Z0-9-_]+/images$": TENANT_API_TYPES_ENUM.LandingPageBlockItem,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+/actions$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/conversations/[a-zA-Z0-9-_]+/internal-notes$": null,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/copy$": null,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+/translations$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/charts$": TENANT_API_TYPES_ENUM.CustomDashboardItem,
    "^/v3/forms/[a-zA-Z0-9-_]+/element-option-translations$": TENANT_API_TYPES_ENUM.FormItem,
    "^/v3/conversations/[a-zA-Z0-9-_]+/messages$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/submissions$": null,
    "^/v3/auth/saml2/users/[a-zA-Z0-9-_]+/inactive$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/downloads$": null,
    "^/v3/archived-landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/automations/[a-zA-Z0-9-_]+/conditions$": null,
    "^/v3/end-project-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectStatusItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/group-memberships$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/channels/[a-zA-Z0-9-_]+/mobile-deep-links$": TENANT_API_TYPES_ENUM.ChannelQrCodeItem,
    "^/v3/elements/[a-zA-Z0-9-_]+/element-options$": TENANT_API_TYPES_ENUM.ElementOptionItem,
    "^/v3/end-whisper-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportStatusItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/group-memberships$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": null,
    "^/v3/automations/[a-zA-Z0-9-_]+/actions$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/element-translations$": TENANT_API_TYPES_ENUM.FormItem,
    "^/v3/forms/[a-zA-Z0-9-_]+/enabled-translations$": TENANT_API_TYPES_ENUM.FormItem,
    "^/v3/forms/[a-zA-Z0-9-_]+/translation-settings$": TENANT_API_TYPES_ENUM.FormItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/generate-summary$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/invitations/[a-zA-Z0-9-_]+/accept$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/channels/[a-zA-Z0-9-_]+/translations$": TENANT_API_TYPES_ENUM.ChannelTranslationItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/memberships$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users$": TENANT_API_TYPES_ENUM.ReportExternalUserItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversations$": null,
    "^/v3/archived-workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.WorkflowItem,
    "^/v3/forms/[a-zA-Z0-9-_]+/step-translations$": TENANT_API_TYPES_ENUM.FormItem,
    "^/v3/custom-translations/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.TranslationItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/participants$": null,
    "^/v3/whisperer/auth/translations$": TENANT_API_TYPES_ENUM.AuthTranslationItem,
    "^/v3/whisperer/submitted-whispers$": TENANT_API_TYPES_ENUM.InformantReportItem,
    "^/v3/user-groups/[a-zA-Z0-9-_]+/users$": TENANT_API_TYPES_ENUM.UserGroupItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/archived-channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/comments$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions$": null,
    "^/v3/whisperer/forgot-password$": null,
    "^/v3/whisperer/auth/websockets$": TENANT_API_TYPES_ENUM.WebsocketAuthItem,
    "^/v3/whisperer/drafted-whispers$": TENANT_API_TYPES_ENUM.InformantReportItem,
    "^/v3/whisperer/guest-whisperer$": null,
    "^/v3/whisperer/whisperer-links$": TENANT_API_TYPES_ENUM.InformantLinkItem,
    "^/v3/auth/external-data-source$": TENANT_API_TYPES_ENUM.AuthExternalDataSourceItem,
    "^/v3/channels/[a-zA-Z0-9-_]+/qr-codes$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/duplicated-landing-pages$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/users/[a-zA-Z0-9-_]+/valid-password$": null,
    "^/v3/whisperer/reset-password$": null,
    "^/v3/elements/[a-zA-Z0-9-_]+/logics$": TENANT_API_TYPES_ENUM.LogicItem,
    "^/v3/emailed-whisperer-links$": TENANT_API_TYPES_ENUM.InformantLinkItem,
    "^/v3/live-workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.WorkflowItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/owners$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/projects$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/whisperer/answer-medias$": TENANT_API_TYPES_ENUM.AnswerMediaItem,
    "^/v3/elements/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/projects/[a-zA-Z0-9-_]+/files$": null,
    "^/scim/auth/token/refresh$": TENANT_API_TYPES_ENUM.ScimAuthSuccessItem,
    "^/v3/whisperer/auth/refresh$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/analytics/dashboards$": null,
    "^/v3/duplicated-workflows$": null,
    "^/v3/live-channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/locked-whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/scim/auth/token/revoke$": TENANT_API_TYPES_ENUM.ScimTokenRevokedItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/snooze$": TENANT_API_TYPES_ENUM.UserReportActionItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/owners$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/whisperer/auth/logout$": null,
    "^/v3/whisperer/whisperers$": TENANT_API_TYPES_ENUM.InformantItem,
    "^/v3/whisperer/push-tokens$": null,
    "^/v3/duplicated-channels$": null,
    "^/v3/duplicated-elements$": TENANT_API_TYPES_ENUM.ElementItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files$": null,
    "^/v3/whisperer/auth/login$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/users/[a-zA-Z0-9-_]+/inactive$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/read-notifications$": TENANT_API_TYPES_ENUM.NotificationItems,
    "^/v3/retention-policies$": null,
    "^/v3/seen-notifications$": TENANT_API_TYPES_ENUM.NotificationItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/auth/translations$": TENANT_API_TYPES_ENUM.AuthTranslationItem,
    "^/v3/automation-groups$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/live-landing-pages$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/submitted-whispers$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/analytics/charts$": null,
    "^/v3/data-exports/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.SuccessItem,
    "^/v3/project-statuses$": null,
    "^/v3/auth/data-export$": TENANT_API_TYPES_ENUM.AuthDataExportItem,
    "^/v3/forgot-password$": null,
    "^/v3/auth/enabled-2fa$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/auth/websockets$": TENANT_API_TYPES_ENUM.WebsocketAuthItem,
    "^/v3/drafted-whispers$": null,
    "^/v3/forms/[a-zA-Z0-9-_]+/steps$": TENANT_API_TYPES_ENUM.StepItem,
    "^/v3/auth/guest/token$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/whisper-statuses$": null,
    "^/v3/whisperer-links$": null,
    "^/v3/reset-password$": null,
    "^/v3/auth/analytics$": TENANT_API_TYPES_ENUM.AuthCubeJsItem,
    "^/v3/auth/platforms$": TENANT_API_TYPES_ENUM.AuthPlatformItem,
    "^/v3/stack-versions$": TENANT_API_TYPES_ENUM.StackItem,
    "^/scim/auth/token$": TENANT_API_TYPES_ENUM.ScimAuthSuccessItem,
    "^/v3/external-users$": null,
    "^/v3/answer-medias$": TENANT_API_TYPES_ENUM.AnswerMediaItem,
    "^/v3/landing-pages$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/query-configs$": null,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": null,
    "^/v3/participants$": null,
    "^/v3/auth/refresh$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/automations$": null,
    "^/v3/invitations$": null,
    "^/v3/auth/logout$": null,
    "^/v3/user-groups$": null,
    "^/v3/auth/login$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/workflows$": TENANT_API_TYPES_ENUM.WorkflowItem,
    "^/v3/channels$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/elements$": TENANT_API_TYPES_ENUM.ElementItem,
    "^/v3/projects$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/whispers$": TENANT_API_TYPES_ENUM.ReportItems,
    "^/v3/tags$": null
  },
  "get": {
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/potential-user-groups$": TENANT_API_TYPES_ENUM.UserGroupItems,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/whisperer/submissibles/[a-zA-Z0-9-_]+/submissions$": TENANT_API_TYPES_ENUM.SubmissionItems,
    "^/v3/whisperer/conversations/[a-zA-Z0-9-_]+/messages$": TENANT_API_TYPES_ENUM.MessageItems,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+/potential-users$": TENANT_API_TYPES_ENUM.UserItems,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/submissions-answers$": TENANT_API_TYPES_ENUM.SubmissionItems,
    "^/v3/whisperer/whisperer-links/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.InformantLinkItem,
    "^/v3/element-options/[a-zA-Z0-9-_]+/active-logics$": TENANT_API_TYPES_ENUM.LogicItems,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/answers$": TENANT_API_TYPES_ENUM.SubmissionItem,
    "^/v3/whisperer/submissibles/[a-zA-Z0-9-_]+/summary$": TENANT_API_TYPES_ENUM.SubmissibleSummaryItem,
    "^/v3/latest-organization-images/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/external-users/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportExternalUserItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps/[a-zA-Z0-9-_]+/answers$": TENANT_API_TYPES_ENUM.SubmissionItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": TENANT_API_TYPES_ENUM.ReportVersionItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships/external-users$": TENANT_API_TYPES_ENUM.ReportMembershipItems,
    "^/v3/workflows/[a-zA-Z0-9-_]+/exported-submissions$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/whisper-participants/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ParticipantReportItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-external-users$": TENANT_API_TYPES_ENUM.ExternalUserItems,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/conversations$": TENANT_API_TYPES_ENUM.ConversationSimpleItems,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/submissions$": TENANT_API_TYPES_ENUM.SubmissionItems,
    "^/v3/projects/[a-zA-Z0-9-_]+/potential-user-groups$": TENANT_API_TYPES_ENUM.UserGroupSimpleItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships/user-groups$": TENANT_API_TYPES_ENUM.MembershipUserGroupWithUsersItems,
    "^/v3/conversations/[a-zA-Z0-9-_]+/messages$": TENANT_API_TYPES_ENUM.MessageItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/active-whisperer-links$": TENANT_API_TYPES_ENUM.InformantLinkItem,
    "^/v3/whisperer/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/redactions$": TENANT_API_TYPES_ENUM.RedactionItems,
    "^/v3/channels/[a-zA-Z0-9-_]+/landing-page-blocks$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/users/[a-zA-Z0-9-_]+/notification-preferences$": TENANT_API_TYPES_ENUM.NotificationPreferenceItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-user-groups$": TENANT_API_TYPES_ENUM.UserGroupSimpleItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/membership-summaries$": TENANT_API_TYPES_ENUM.ReportMembershipSummaryItem,
    "^/v3/automation-groups/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationGroupItem,
    "^/v3/landing-page-block-images/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/preview-landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/potential-members$": TENANT_API_TYPES_ENUM.UserSimpleItems,
    "^/v3/projects/[a-zA-Z0-9-_]+/project-workflows$": TENANT_API_TYPES_ENUM.ProjectWorkflowItems,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/channels/[a-zA-Z0-9-_]+/exported-whispers$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/summary$": TENANT_API_TYPES_ENUM.SubmissibleSummaryItem,
    "^/v3/elements/[a-zA-Z0-9-_]+/element-options$": TENANT_API_TYPES_ENUM.ElementOptionItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversation-meta$": TENANT_API_TYPES_ENUM.ReportConversationMetaItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/potential-members$": TENANT_API_TYPES_ENUM.UserSimpleItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/versioned-whispers$": TENANT_API_TYPES_ENUM.ReportVersionItems,
    "^/v3/whisperer/elements/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaItems,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/qr-codes$": TENANT_API_TYPES_ENUM.QrCodeItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/summary$": TENANT_API_TYPES_ENUM.ReportSummaryItem,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/medias$": TENANT_API_TYPES_ENUM.MediaItems,
    "^/v3/analytics/dashboards/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.CustomDashboardItem,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/submissibles/[a-zA-Z0-9-_]+/steps$": TENANT_API_TYPES_ENUM.StepItems,
    "^/v3/submissions/[a-zA-Z0-9-_]+/answers$": TENANT_API_TYPES_ENUM.AnswerItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/whisper-workflows$": TENANT_API_TYPES_ENUM.ReportWorkflowItems,
    "^/v3/whisperer/medias/[a-zA-Z0-9-_]+/download$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaItems,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+/steps$": TENANT_API_TYPES_ENUM.StepItems,
    "^/v3/elements/[a-zA-Z0-9-_]+/automations$": TENANT_API_TYPES_ENUM.AutomationItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/conversations$": TENANT_API_TYPES_ENUM.ReportConversationItems,
    "^/v3/public-medias/[a-zA-Z0-9-_]+/download$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/custom-translations/[a-zA-Z0-9-_]+/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.TranslationItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/participants$": TENANT_API_TYPES_ENUM.ParticipantReportItems,
    "^/v3/whisperer/channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/elements/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/projects/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/whispers/[a-zA-Z0-9-_]+/memberships$": TENANT_API_TYPES_ENUM.ReportMembershipItems,
    "^/v3/whisperer/selectable-users$": TENANT_API_TYPES_ENUM.UserItems,
    "^/v3/whisperer/mobile-deep-links$": TENANT_API_TYPES_ENUM.QrCodeItem,
    "^/v3/landing-pages/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.LandingPageItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/comments$": TENANT_API_TYPES_ENUM.CommentItems,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.QueryConfigItem,
    "^/v3/participants/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ParticipantItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/activities$": TENANT_API_TYPES_ENUM.ReportActivityItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/redactions$": TENANT_API_TYPES_ENUM.RedactionItems,
    "^/v3/whisperer/whispers/summary$": TENANT_API_TYPES_ENUM.ReportSummaryItems,
    "^/v3/whisperer/whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.InformantReportItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/whispers$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/whisperer/reset-password$": TENANT_API_TYPES_ENUM.SuccessStatus,
    "^/v3/whisperer/files/[a-zA-Z0-9-_]+/meta$": TENANT_API_TYPES_ENUM.MediaMetaItem,
    "^/v3/automations/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationItem,
    "^/v3/invitations/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.InvitationItem,
    "^/v3/landing-page-block-types$": TENANT_API_TYPES_ENUM.LandingPageBlockTypeItems,
    "^/v3/live-landing-pages/[a-zA-Z0-9-_]+/t$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/messages$": TENANT_API_TYPES_ENUM.MessageItems,
    "^/v3/elements/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaItems,
    "^/v3/organization-settings$": TENANT_API_TYPES_ENUM.OrganizationSettingItems,
    "^/v3/steps/[a-zA-Z0-9-_]+/automations$": TENANT_API_TYPES_ENUM.AutomationItems,
    "^/v3/unowned-whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/whisperer/medias/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.MediaItem,
    "^/v3/analytics/dashboards$": TENANT_API_TYPES_ENUM.CustomDashboardItems,
    "^/v3/data-exports/download$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/medias/[a-zA-Z0-9-_]+/download$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/potential-user-groups$": TENANT_API_TYPES_ENUM.UserGroupItems,
    "^/v3/projects/[a-zA-Z0-9-_]+/tags$": TENANT_API_TYPES_ENUM.TagSimpleItems,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa-qr-codes$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/user-groups/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.UserGroupItem,
    "^/v3/bundled-translations$": TENANT_API_TYPES_ENUM.BundledTranslationsItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/search$": TENANT_API_TYPES_ENUM.DeprecatedReportSearchItem,
    "^/v3/whisperer/auth/verify$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/whisperer/push-tokens$": TENANT_API_TYPES_ENUM.InformantPushTokenItems,
    "^/v3/custom-landing-pages$": TENANT_API_TYPES_ENUM.CustomLandingPageItems,
    "^/v3/participants-config$": TENANT_API_TYPES_ENUM.ParticipantConfigItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/notes$": TENANT_API_TYPES_ENUM.NoteItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/files$": TENANT_API_TYPES_ENUM.MediaItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/steps$": TENANT_API_TYPES_ENUM.StepItems,
    "^/v3/whisperer-links/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.InformantLinkItem,
    "^/v3/deleted-projects/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/filtered-workflows$": TENANT_API_TYPES_ENUM.WorkflowSimpleItems,
    "^/v3/notification-types$": TENANT_API_TYPES_ENUM.NotificationTypeItems,
    "^/v3/retention-policies$": TENANT_API_TYPES_ENUM.RetentionPolicyItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/tags$": TENANT_API_TYPES_ENUM.TagSimpleItems,
    "^/v3/whisperer/channels$": TENANT_API_TYPES_ENUM.ChannelItems,
    "^/v3/workflows/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.WorkflowItem,
    "^/v3/whisperer/whispers$": TENANT_API_TYPES_ENUM.InformantReportItems,
    "^/v3/automation-groups$": TENANT_API_TYPES_ENUM.AutomationGroupItems,
    "^/v3/deleted-whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/filtered-channels$": TENANT_API_TYPES_ENUM.ChannelSimpleItems,
    "^/v3/workflow-statuses$": TENANT_API_TYPES_ENUM.WorkflowStatusItems,
    "^/v3/auth/asm/disabled$": TENANT_API_TYPES_ENUM.AsmDisabledItem,
    "^/v3/auth/guest/verify$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/channels/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ChannelItem,
    "^/v3/channel-statuses$": TENANT_API_TYPES_ENUM.ChannelStatusItems,
    "^/v3/analytics/charts$": TENANT_API_TYPES_ENUM.ChartItems,
    "^/v3/deleted-projects$": TENANT_API_TYPES_ENUM.ProjectItems,
    "^/v3/filtered-whispers$": TENANT_API_TYPES_ENUM.FilteredReportItems,
    "^/v3/projects/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/project-statuses$": TENANT_API_TYPES_ENUM.ProjectStatusItems,
    "^/v3/selectable-users$": TENANT_API_TYPES_ENUM.UserSimpleItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+/avatar$": TENANT_API_TYPES_ENUM.EmptyResponseBody,
    "^/v3/condition-types$": TENANT_API_TYPES_ENUM.ConditionTypeItems,
    "^/v3/deleted-whispers$": TENANT_API_TYPES_ENUM.DeletedReportItems,
    "^/v3/forms/[a-zA-Z0-9-_]+/steps$": TENANT_API_TYPES_ENUM.StepItems,
    "^/v3/auth/help-center$": TENANT_API_TYPES_ENUM.AuthHelpCenterItem,
    "^/v3/public-channels$": TENANT_API_TYPES_ENUM.ChannelItems,
    "^/v3/whisper-statuses$": TENANT_API_TYPES_ENUM.ReportStatusItems,
    "^/v3/reset-password$": TENANT_API_TYPES_ENUM.SuccessStatus,
    "^/v3/filtered-users$": TENANT_API_TYPES_ENUM.UserSimpleItems,
    "^/v3/stack-versions$": TENANT_API_TYPES_ENUM.StackItem,
    "^/v3/internal-forms$": TENANT_API_TYPES_ENUM.ChannelItems,
    "^/v3/live-workflows$": TENANT_API_TYPES_ENUM.WorkflowItems,
    "^/v3/notifications$": TENANT_API_TYPES_ENUM.NotificationItems,
    "^/v3/external-users$": TENANT_API_TYPES_ENUM.ExternalUserItems,
    "^/v3/whispers-lookup$": TENANT_API_TYPES_ENUM.DataLookupItems,
    "^/v3/whispers/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/channel-types$": TENANT_API_TYPES_ENUM.ChannelTypeItems,
    "^/v3/chat-channels$": TENANT_API_TYPES_ENUM.ChannelItems,
    "^/v3/element-types$": TENANT_API_TYPES_ENUM.ElementTypeItems,
    "^/v3/feature-flags$": TENANT_API_TYPES_ENUM.FeatureFlagItem,
    "^/v3/landing-pages$": TENANT_API_TYPES_ENUM.LandingPageSimpleItems,
    "^/v3/live-channels$": TENANT_API_TYPES_ENUM.ChannelItems,
    "^/v3/query-configs$": TENANT_API_TYPES_ENUM.QueryConfigItems,
    "^/v3/trigger-types$": TENANT_API_TYPES_ENUM.TriggerTypeItems,
    "^/v3/users/[a-zA-Z0-9-_]+/2fa$": TENANT_API_TYPES_ENUM.UserMfaItem,
    "^/v3/participants$": TENANT_API_TYPES_ENUM.ParticipantItems,
    "^/v3/action-types$": TENANT_API_TYPES_ENUM.ActionTypeItems,
    "^/v3/automations$": TENANT_API_TYPES_ENUM.AutomationItems,
    "^/v3/invitations$": TENANT_API_TYPES_ENUM.InvitationItems,
    "^/v3/whisperer/me$": TENANT_API_TYPES_ENUM.InformantItem,
    "^/v3/auth/verify$": TENANT_API_TYPES_ENUM.AuthSuccessItem,
    "^/v3/redactions$": TENANT_API_TYPES_ENUM.RedactionItems,
    "^/v3/dashboards$": TENANT_API_TYPES_ENUM.SimpleDashboardItems,
    "^/v3/user-groups$": TENANT_API_TYPES_ENUM.UserGroupItems,
    "^/v3/countries$": TENANT_API_TYPES_ENUM.CountryItems,
    "^/v3/forms/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.FormItem,
    "^/v3/timezones$": TENANT_API_TYPES_ENUM.TimezoneItems,
    "^/v3/users/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/workflows$": TENANT_API_TYPES_ENUM.WorkflowItems,
    "^/v3/channels$": TENANT_API_TYPES_ENUM.ChannelItems,
    "^/v3/hotlines$": TENANT_API_TYPES_ENUM.ChannelItems,
    "^/v3/projects$": TENANT_API_TYPES_ENUM.ProjectItems,
    "^/v3/locales$": TENANT_API_TYPES_ENUM.LocaleItems,
    "^/v3/tags/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.TagItem,
    "^/v3/guest/me$": TENANT_API_TYPES_ENUM.ExternalUserItem,
    "^/v3/whispers$": TENANT_API_TYPES_ENUM.ReportItems,
    "^/v3/audits$": TENANT_API_TYPES_ENUM.AuditItems,
    "^/v3/search$": TENANT_API_TYPES_ENUM.DeprecatedSearchItem,
    "^/v3/forms$": TENANT_API_TYPES_ENUM.FormItems,
    "^/v3/roles$": TENANT_API_TYPES_ENUM.UserRoleItems,
    "^/v3/users$": TENANT_API_TYPES_ENUM.UserItems,
    "^/v3/tags$": TENANT_API_TYPES_ENUM.TagItems,
    "^/v3/me$": TENANT_API_TYPES_ENUM.UserItem
  },
  "put": {
    "^/v3/projects/[a-zA-Z0-9-_]+/retention-policies/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/whispers/[a-zA-Z0-9-_]+/retention-policies/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ReportItem,
    "^/v3/automations/[a-zA-Z0-9-_]+/trigger-types/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.AutomationItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/project-statuses/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ProjectItem,
    "^/v3/projects/[a-zA-Z0-9-_]+/workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/whispers/[a-zA-Z0-9-_]+/workflows/[a-zA-Z0-9-_]+$": null,
    "^/v3/query-configs/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.QueryConfigItem,
    "^/v3/conditions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ConditionItem,
    "^/v3/users/[a-zA-Z0-9-_]+/password$": TENANT_API_TYPES_ENUM.UserItem,
    "^/v3/actions/[a-zA-Z0-9-_]+$": TENANT_API_TYPES_ENUM.ActionItem
  }
}

export enum GetAuditsParamsDataEventEnum {
  ARCHIVED = 'archived',
  AUTOMATION_ACTION_CREATED = 'automation-action-created',
  AUTOMATION_ACTION_DELETED = 'automation-action-deleted',
  AUTOMATION_ACTION_UPDATED = 'automation-action-updated',
  AUTOMATION_CONDITION_CREATED = 'automation-condition-created',
  AUTOMATION_CONDITION_DELETED = 'automation-condition-deleted',
  AUTOMATION_CONDITION_UPDATED = 'automation-condition-updated',
  AUTOMATION_CREATED = 'automation-created',
  AUTOMATION_DELETED = 'automation-deleted',
  AUTOMATION_GROUP_ACTION_CREATED = 'automation-group-action-created',
  AUTOMATION_GROUP_ACTION_DELETED = 'automation-group-action-deleted',
  AUTOMATION_GROUP_ACTION_UPDATED = 'automation-group-action-updated',
  AUTOMATION_GROUP_CONDITION_CREATED = 'automation-group-condition-created',
  AUTOMATION_GROUP_CONDITION_DELETED = 'automation-group-condition-deleted',
  AUTOMATION_GROUP_CONDITION_UPDATED = 'automation-group-condition-updated',
  AUTOMATION_GROUP_CREATED = 'automation-group-created',
  AUTOMATION_GROUP_DELETED = 'automation-group-deleted',
  AUTOMATION_GROUP_UPDATED = 'automation-group-updated',
  AUTOMATION_UPDATED = 'automation-updated',
  CREATED = 'created',
  DELETED = 'deleted',
  EXPORTED = 'exported',
  EXTERNAL_USER_ACCESSED = 'external-user-accessed',
  EXTERNAL_USER_CREATED = 'external-user-created',
  EXTERNAL_USER_INVITED = 'external-user-invited',
  EXTERNAL_USER_REVOKED = 'external-user-revoked',
  EXTERNAL_USER_UPDATED = 'external-user-updated',
  READ = 'read',
  REDACTION_CANCELED = 'redaction-canceled',
  REDACTION_EXECUTED = 'redaction-executed',
  REDACTION_REMINDER_SENT = 'redaction-reminder-sent',
  REDACTION_SCHEDULED = 'redaction-scheduled',
  RESTORED = 'restored',
  RETENTION_POLICY_EXECUTED = 'retention-policy-executed',
  SEARCHED = 'searched',
  SUBMITTED = 'submitted',
  UPDATED = 'updated',
  USER_ROLES_UPDATED = 'user-roles-updated'
}

export enum GetAutomationsParamsDataAutomationTypeEnum {
  EVENT = 'event',
  GROUP_EVENT = 'group-event',
  TIME = 'time'
}

export enum GetParticipantsParamsSortColumnEnum {
  NAME = 'name',
  CREATED_AT = 'created_at',
  CREATED_VIA = 'created_via',
  REPORT_COUNT = 'report_count'
}

export enum GetParticipantsParamsSortDirectionEnum {
  ASC = 'asc',
  DESC = 'desc'
}

export enum GetReportsReportActivitiesParamsDataCategoryEnum {
  ACTIVITY_MEMBER_ADDED = 'ACTIVITY_MEMBER_ADDED',
  ACTIVITY_MEMBER_REMOVED = 'ACTIVITY_MEMBER_REMOVED',
  ACTIVITY_NEW_REPORT_VERSION = 'ACTIVITY_NEW_REPORT_VERSION',
  ACTIVITY_OWNER_CHANGED = 'ACTIVITY_OWNER_CHANGED',
  ACTIVITY_PROJECT_ATTACHED_TO_REPORT = 'ACTIVITY_PROJECT_ATTACHED_TO_REPORT',
  ACTIVITY_PROJECT_DETACHED_TO_REPORT = 'ACTIVITY_PROJECT_DETACHED_TO_REPORT',
  ACTIVITY_REPORT_ARCHIVED = 'ACTIVITY_REPORT_ARCHIVED',
  ACTIVITY_REPORT_LOCKED = 'ACTIVITY_REPORT_LOCKED',
  ACTIVITY_REPORT_UNARCHIVED = 'ACTIVITY_REPORT_UNARCHIVED',
  ACTIVITY_REPORT_UNLOCKED = 'ACTIVITY_REPORT_UNLOCKED',
  ACTIVITY_STATUS_CHANGED = 'ACTIVITY_STATUS_CHANGED',
  ACTIVITY_TAG_ADDED = 'ACTIVITY_TAG_ADDED',
  ACTIVITY_TAG_REMOVED = 'ACTIVITY_TAG_REMOVED',
  ACTIVITY_WORKFLOW_ASSIGNED = 'ACTIVITY_WORKFLOW_ASSIGNED',
  ACTIVITY_WORKFLOW_DEACTIVATED = 'ACTIVITY_WORKFLOW_DEACTIVATED',
  CONVERSATION_FILE_DELETED = 'CONVERSATION_FILE_DELETED',
  CONVERSATION_FILE_UPLOADED = 'CONVERSATION_FILE_UPLOADED',
  INFORMANT_REPORT_DOWNLOAD_SUCCESS = 'INFORMANT_REPORT_DOWNLOAD_SUCCESS',
  INFORMANT_REPORT_HAS_BEEN_CLOSED = 'INFORMANT_REPORT_HAS_BEEN_CLOSED',
  INFORMANT_REPORT_HAS_BEEN_REOPENED = 'INFORMANT_REPORT_HAS_BEEN_REOPENED',
  INFORMANT_REPORT_REDACTION_CANCELED = 'INFORMANT_REPORT_REDACTION_CANCELED',
  INFORMANT_REPORT_REDACTION_DUE_IN_ONE_WEEK = 'INFORMANT_REPORT_REDACTION_DUE_IN_ONE_WEEK',
  INFORMANT_REPORT_REDACTION_EXECUTED = 'INFORMANT_REPORT_REDACTION_EXECUTED',
  INFORMANT_REPORT_REDACTION_SCHEDULED = 'INFORMANT_REPORT_REDACTION_SCHEDULED',
  ACTIVITY_PARTICIPANT_ADDED = 'ACTIVITY_PARTICIPANT_ADDED',
  ACTIVITY_PARTICIPANT_REMOVED = 'ACTIVITY_PARTICIPANT_REMOVED',
  ACTIVITY_USER_ACTION_ACTIVATED = 'ACTIVITY_USER_ACTION_ACTIVATED',
  ACTIVITY_USER_ACTION_UPDATED = 'ACTIVITY_USER_ACTION_UPDATED',
  ACTIVITY_USER_ACTION_REMOVED = 'ACTIVITY_USER_ACTION_REMOVED',
  ACTIVITY_USER_ACTION_DEACTIVATED = 'ACTIVITY_USER_ACTION_DEACTIVATED'
}

export enum GetReportsReportNotesParamsDataCategoryEnum {
  ACTIVITY_MEMBER_ADDED = 'ACTIVITY_MEMBER_ADDED',
  ACTIVITY_MEMBER_REMOVED = 'ACTIVITY_MEMBER_REMOVED',
  ACTIVITY_NEW_REPORT_VERSION = 'ACTIVITY_NEW_REPORT_VERSION',
  ACTIVITY_OWNER_CHANGED = 'ACTIVITY_OWNER_CHANGED',
  ACTIVITY_PROJECT_ATTACHED_TO_REPORT = 'ACTIVITY_PROJECT_ATTACHED_TO_REPORT',
  ACTIVITY_PROJECT_DETACHED_TO_REPORT = 'ACTIVITY_PROJECT_DETACHED_TO_REPORT',
  ACTIVITY_REPORT_ARCHIVED = 'ACTIVITY_REPORT_ARCHIVED',
  ACTIVITY_REPORT_LOCKED = 'ACTIVITY_REPORT_LOCKED',
  ACTIVITY_REPORT_UNARCHIVED = 'ACTIVITY_REPORT_UNARCHIVED',
  ACTIVITY_REPORT_UNLOCKED = 'ACTIVITY_REPORT_UNLOCKED',
  ACTIVITY_STATUS_CHANGED = 'ACTIVITY_STATUS_CHANGED',
  ACTIVITY_TAG_ADDED = 'ACTIVITY_TAG_ADDED',
  ACTIVITY_TAG_REMOVED = 'ACTIVITY_TAG_REMOVED',
  ACTIVITY_WORKFLOW_ASSIGNED = 'ACTIVITY_WORKFLOW_ASSIGNED',
  ACTIVITY_WORKFLOW_DEACTIVATED = 'ACTIVITY_WORKFLOW_DEACTIVATED',
  CONVERSATION_FILE_DELETED = 'CONVERSATION_FILE_DELETED',
  CONVERSATION_FILE_UPLOADED = 'CONVERSATION_FILE_UPLOADED',
  INFORMANT_REPORT_DOWNLOAD_SUCCESS = 'INFORMANT_REPORT_DOWNLOAD_SUCCESS',
  INFORMANT_REPORT_HAS_BEEN_CLOSED = 'INFORMANT_REPORT_HAS_BEEN_CLOSED',
  INFORMANT_REPORT_HAS_BEEN_REOPENED = 'INFORMANT_REPORT_HAS_BEEN_REOPENED',
  INFORMANT_REPORT_REDACTION_CANCELED = 'INFORMANT_REPORT_REDACTION_CANCELED',
  INFORMANT_REPORT_REDACTION_DUE_IN_ONE_WEEK = 'INFORMANT_REPORT_REDACTION_DUE_IN_ONE_WEEK',
  INFORMANT_REPORT_REDACTION_EXECUTED = 'INFORMANT_REPORT_REDACTION_EXECUTED',
  INFORMANT_REPORT_REDACTION_SCHEDULED = 'INFORMANT_REPORT_REDACTION_SCHEDULED'
}

export enum GetReportsReportNotesParamsDataMentionableTypeEnum {
  USER = 'user',
  USER_GROUP = 'user-group',
  EXTERNAL_USER = 'external-user'
}

export enum GetReportsReportFilesParamsDataFilterEnum {
  REPORT = 'report',
  CHAT = 'chat',
  WORKFLOW = 'workflow'
}

export enum GetReportsReportMembershipsParamsDataMembershipableTypeEnum {
  USER = 'user',
  USER_GROUP = 'user-group',
  EXTERNAL_USER = 'external-user'
}

export enum PolymorphicCreatorCreatedByTypeEnum {
  USER = 'user',
  APP_USER = 'App\\User',
  SYSTEM_USER = 'system-user',
  APP_SYSTEM_USER = 'App\\SystemUser',
  AUTOMATION = 'automation',
  APP_AUTOMATION = 'App\\Automation',
  /** @deprecated Use PolymorphicCreatorCreatedByTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer',
  /** @deprecated Use PolymorphicCreatorCreatedByTypeEnum.APP_INFORMANT */
  APP_WHISPERER = 'App\\Whisperer',
  APP_INFORMANT = 'App\\Whisperer'
}

export enum PolymorphicUpdaterUpdatedByTypeEnum {
  USER = 'user',
  APP_USER = 'App\\User',
  SYSTEM_USER = 'system-user',
  APP_SYSTEM_USER = 'App\\SystemUser',
  AUTOMATION = 'automation',
  APP_AUTOMATION = 'App\\Automation',
  /** @deprecated Use PolymorphicUpdaterUpdatedByTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer',
  /** @deprecated Use PolymorphicUpdaterUpdatedByTypeEnum.APP_INFORMANT */
  APP_WHISPERER = 'App\\Whisperer',
  APP_INFORMANT = 'App\\Whisperer'
}

export enum ReportListDataCreatedByTypeEnum {
  /** @deprecated Use ReportListDataCreatedByTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer',
  USER = 'user'
}

export enum ReportListDataSourceEnum {
  WHISPLI_WEB = 'WHISPLI_WEB',
  WHISPLI_MOBILE = 'WHISPLI_MOBILE'
}

export enum ReportListSortColumnEnum {
  CREATED_AT = 'created_at',
  ANONYMOUS_NAME = 'anonymous_name',
  INTERNAL_TITLE = 'internal_title',
  /** @deprecated Use ReportListSortColumnEnum.REPORT_LABEL */
  WHISPER_LABEL = 'whisper_label',
  REPORT_LABEL = 'whisper_label',
  STATUS_NAME = 'status_name',
  OWNER_NAME = 'owner_name',
  CHANNEL_NAME = 'channel_name',
  UPDATED_AT = 'updated_at',
  UPDATED_BY_NAME = 'updated_by_name',
  SUBMITTED_AT = 'submitted_at',
  CREATED_BY_TYPE = 'created_by_type',
  LOCALE = 'locale',
  SOURCE = 'source',
  TAG_NAME = 'tag_name',
  ARCHIVED_AT = 'archived_at',
  DELETED_AT = 'deleted_at'
}

export enum PostInformantSubmittedReportRequestBodySourceEnum {
  WHISPLI_WEB = 'WHISPLI_WEB',
  WHISPLI_MOBILE = 'WHISPLI_MOBILE'
}

export enum ConversationMessageRequestBodySourceEnum {
  WHISPLI_WEB = 'WHISPLI_WEB',
  WHISPLI_MOBILE = 'WHISPLI_MOBILE'
}

export enum ParticipantRequestBodyCreatedViaEnum {
  MANUAL_INPUT = 'manual_input',
  ACTIVE_DIRECTORY = 'active_directory',
  FILE_IMPORT = 'file_import'
}

export enum ReportParticipantStoreRequestBodyCreatedViaEnum {
  MANUAL_INPUT = 'manual_input',
  ACTIVE_DIRECTORY = 'active_directory',
  FILE_IMPORT = 'file_import'
}

export enum InformantStoreRequestBodySourceEnum {
  WHISPLI_WEB = 'WHISPLI_WEB',
  WHISPLI_MOBILE = 'WHISPLI_MOBILE'
}

export enum AnswerAnswerableTypeEnum {
  ANSWER_COUNTRY = 'answer-country',
  ANSWER_DATE = 'answer-date',
  ANSWER_ELEMENT_BOOLEAN_OPTION = 'answer-element-boolean-option',
  ANSWER_ELEMENT_DYNAMIC_OPTION = 'answer-element-dynamic-option',
  ANSWER_ELEMENT_SCALE_OPTION = 'answer-element-scale-option',
  ANSWER_MEDIA = 'answer-media',
  ANSWER_NUMBER = 'answer-number',
  ANSWER_TEXT = 'answer-text',
  ELEMENT_OPTION = 'element-option',
  USER = 'user'
}

export enum AuditAuditableTypeEnum {
  ACTIONS = 'actions',
  AUTOMATIONS = 'automations',
  CHANNELS = 'channels',
  COMMENTS = 'comments',
  CONDITIONS = 'conditions',
  ELEMENT_OPTIONS = 'elementOptions',
  ELEMENTS = 'elements',
  INVITATIONS = 'invitations',
  LANDING_PAGE_BLOCKS = 'landingPageBlocks',
  LANDING_PAGES = 'landingPages',
  MEDIA = 'media',
  MEMBERSHIPS = 'memberships',
  MESSAGES = 'messages',
  PROJECT_STATUSES = 'projectStatuses',
  PROJECT_WORKFLOWS = 'projectWorkflows',
  PROJECTS = 'projects',
  REDACTIONS = 'redactions',
  RETENTION_POLICIES = 'retentionPolicies',
  STEPS = 'steps',
  SUBMISSIONS = 'submissions',
  TAGS = 'tags',
  USER_GROUPS = 'userGroups',
  USERS = 'users',
  /** @deprecated Use AuditAuditableTypeEnum.REPORT_STATUSES */
  WHISPER_STATUSES = 'whisperStatuses',
  REPORT_STATUSES = 'whisperStatuses',
  /** @deprecated Use AuditAuditableTypeEnum.REPORT_WORKFLOWS */
  WHISPER_WORKFLOWS = 'whisperWorkflows',
  REPORT_WORKFLOWS = 'whisperWorkflows',
  /** @deprecated Use AuditAuditableTypeEnum.INFORMANT_LINKS */
  WHISPERER_LINKS = 'whispererLinks',
  INFORMANT_LINKS = 'whispererLinks',
  /** @deprecated Use AuditAuditableTypeEnum.REPORTS */
  WHISPERS = 'whispers',
  REPORTS = 'whispers',
  WORKFLOWS = 'workflows'
}

export enum AuditEntityTypeEnum {
  AUTOMATIONS = 'automations',
  CHANNELS = 'channels',
  ELEMENTS = 'elements',
  INVITATIONS = 'invitations',
  LANDING_PAGES = 'landingPages',
  PROJECT_STATUSES = 'projectStatuses',
  PROJECTS = 'projects',
  RETENTION_POLICIES = 'retentionPolicies',
  STEPS = 'steps',
  TAGS = 'tags',
  USER_GROUPS = 'userGroups',
  USERS = 'users',
  /** @deprecated Use AuditEntityTypeEnum.REPORT_STATUSES */
  WHISPER_STATUSES = 'whisperStatuses',
  REPORT_STATUSES = 'whisperStatuses',
  /** @deprecated Use AuditEntityTypeEnum.INFORMANT_LINKS */
  WHISPERER_LINKS = 'whispererLinks',
  INFORMANT_LINKS = 'whispererLinks',
  /** @deprecated Use AuditEntityTypeEnum.REPORTS */
  WHISPERS = 'whispers',
  REPORTS = 'whispers',
  WORKFLOWS = 'workflows'
}

export enum AuditUserTypeEnum {
  AUTOMATION = 'automation',
  EXTERNAL_USER = 'external-user',
  USER = 'user',
  /** @deprecated Use AuditUserTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer'
}

export enum AuditEventEnum {
  ARCHIVED = 'archived',
  AUTOMATION_ACTION_CREATED = 'automation-action-created',
  AUTOMATION_ACTION_DELETED = 'automation-action-deleted',
  AUTOMATION_ACTION_UPDATED = 'automation-action-updated',
  AUTOMATION_CONDITION_CREATED = 'automation-condition-created',
  AUTOMATION_CONDITION_DELETED = 'automation-condition-deleted',
  AUTOMATION_CONDITION_UPDATED = 'automation-condition-updated',
  AUTOMATION_CREATED = 'automation-created',
  AUTOMATION_DELETED = 'automation-deleted',
  AUTOMATION_GROUP_ACTION_CREATED = 'automation-group-action-created',
  AUTOMATION_GROUP_ACTION_DELETED = 'automation-group-action-deleted',
  AUTOMATION_GROUP_ACTION_UPDATED = 'automation-group-action-updated',
  AUTOMATION_GROUP_CONDITION_CREATED = 'automation-group-condition-created',
  AUTOMATION_GROUP_CONDITION_DELETED = 'automation-group-condition-deleted',
  AUTOMATION_GROUP_CONDITION_UPDATED = 'automation-group-condition-updated',
  AUTOMATION_GROUP_CREATED = 'automation-group-created',
  AUTOMATION_GROUP_DELETED = 'automation-group-deleted',
  AUTOMATION_GROUP_UPDATED = 'automation-group-updated',
  AUTOMATION_UPDATED = 'automation-updated',
  CREATED = 'created',
  DELETED = 'deleted',
  EXPORTED = 'exported',
  EXTERNAL_USER_ACCESSED = 'external-user-accessed',
  EXTERNAL_USER_CREATED = 'external-user-created',
  EXTERNAL_USER_INVITED = 'external-user-invited',
  EXTERNAL_USER_REVOKED = 'external-user-revoked',
  EXTERNAL_USER_UPDATED = 'external-user-updated',
  READ = 'read',
  REDACTION_CANCELED = 'redaction-canceled',
  REDACTION_EXECUTED = 'redaction-executed',
  REDACTION_REMINDER_SENT = 'redaction-reminder-sent',
  REDACTION_SCHEDULED = 'redaction-scheduled',
  RESTORED = 'restored',
  RETENTION_POLICY_EXECUTED = 'retention-policy-executed',
  SEARCHED = 'searched',
  SUBMITTED = 'submitted',
  UPDATED = 'updated',
  USER_ROLES_UPDATED = 'user-roles-updated'
}

export enum ChannelChannelableTypeEnum {
  FORM = 'form',
  INTERNAL_FORM = 'internal-form',
  HOTLINE = 'hotline',
  CHAT = 'chat',
  PULSE = 'pulse'
}

export enum ChannelSimpleChannelableTypeEnum {
  FORM = 'form',
  INTERNAL_FORM = 'internal-form',
  HOTLINE = 'hotline',
  CHAT = 'chat',
  PULSE = 'pulse'
}

export enum CommentCommentableTypeEnum {
  PROJECT = 'project'
}

export enum ExternalUserReportSimpleStatusEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  REVOKED = 'REVOKED'
}

export enum MediaFilterableTypeEnum {
  REPORT = 'report',
  CHAT = 'chat',
  WORKFLOW = 'workflow',
  PARTICIPANT = 'participant'
}

export enum MediaModelTypeEnum {
  ANSWER_MEDIA = 'answer-media',
  ELEMENT = 'element',
  LANDING_PAGE_BLOCK = 'landing-page-block',
  ORGANIZATION_SETTING = 'organization-setting',
  PROJECT = 'project',
  USER = 'user',
  /** @deprecated Use MediaModelTypeEnum.REPORT */
  WHISPER = 'whisper',
  REPORT = 'whisper',
  /** @deprecated Use MediaModelTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer',
  /** @deprecated Use MediaModelTypeEnum.PARTICIPANT_REPORT */
  PARTICIPANT_WHISPER = 'participant-whisper',
  PARTICIPANT_REPORT = 'participant-whisper'
}

export enum MediaScanStatusEnum {
  CLEAN = 'clean',
  INFECTED = 'infected',
  IN_PROGRESS = 'in_progress'
}

export enum MediaSimpleScanStatusEnum {
  CLEAN = 'clean',
  INFECTED = 'infected',
  IN_PROGRESS = 'in_progress'
}

export enum MembershipExternalUserStatusEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  REVOKED = 'REVOKED'
}

export enum MembershipMembershipableTypeEnum {
  EXTERNAL_USER = 'external-user',
  USER = 'user',
  USER_GROUP = 'user-group'
}

export enum MembershipUserGroupWithUsersSourceTypeEnum {
  USER = 'user',
  AUTOMATION = 'automation'
}

export enum MessageMetaSeenByTypeEnum {
  USER = 'user',
  /** @deprecated Use MessageMetaSeenByTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer'
}

export enum MessageTypeEnum {
  GENERAL = 'general',
  NOTE = 'note'
}

export enum MessageRetractedByTypeEnum {
  USER = 'user',
  /** @deprecated Use MessageRetractedByTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer',
  SYSTEM_USER = 'system-user'
}

export enum MessageSimpleCreatedByTypeEnum {
  USER = 'user',
  /** @deprecated Use MessageSimpleCreatedByTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer',
  AUTOMATION = 'automation',
  SYSTEM_USER = 'system-user'
}

export enum NoteTypeEnum {
  GENERAL = 'general',
  NOTE = 'note'
}

export enum NoteRetractedByTypeEnum {
  USER = 'user',
  /** @deprecated Use NoteRetractedByTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer',
  SYSTEM_USER = 'system-user'
}

export enum NotificationEntityTypeEnum {
  /** @deprecated Use NotificationEntityTypeEnum.REPORT */
  WHISPER = 'whisper',
  REPORT = 'whisper',
  GROUP = 'group',
  PROJECT = 'project',
  CHANNEL = 'channel',
  WORKFLOW = 'workflow',
  DOWNLOAD = 'download'
}

export enum NotificationTypeEnum {
  DATA_EXPORT_READY_TO_DOWNLOAD = 'DataExportReadyToDownload',
  FILE_READY_TO_DOWNLOAD = 'FileReadyToDownload',
  MEMBERSHIP_CREATED = 'MembershipCreated',
  MEMBERSHIP_DELETED = 'MembershipDeleted',
  MESSAGE_CREATED = 'MessageCreated',
  NOTIFY_USER_AUTOMATION_TRIGGERED = 'NotifyUserAutomationTriggered',
  REDACTION_CANCELED_NOTIFICATION = 'RedactionCanceledNotification',
  REDACTION_EXECUTED_NOTIFICATION = 'RedactionExecutedNotification',
  REDACTION_REMINDER_SENT_NOTIFICATION = 'RedactionReminderSentNotification',
  REDACTION_SCHEDULED_NOTIFICATION = 'RedactionScheduledNotification',
  USER_ADDED_TO_USER_GROUP = 'UserAddedToUserGroup',
  /** @deprecated Use NotificationTypeEnum.USER_LOCKED_REPORT */
  USER_LOCKED_WHISPER = 'UserLockedWhisper',
  USER_LOCKED_REPORT = 'UserLockedWhisper',
  USER_MENTIONED = 'UserMentioned',
  USER_REMOVED_FROM_USER_GROUP = 'UserRemovedFromUserGroup',
  /** @deprecated Use NotificationTypeEnum.USER_UNLOCKED_REPORT */
  USER_UNLOCKED_WHISPER = 'UserUnlockedWhisper',
  USER_UNLOCKED_REPORT = 'UserUnlockedWhisper',
  /** @deprecated Use NotificationTypeEnum.SNOOZED_REPORT_DEACTIVATED */
  SNOOZED_WHISPER_DEACTIVATED = 'SnoozedWhisperDeactivated',
  SNOOZED_REPORT_DEACTIVATED = 'SnoozedWhisperDeactivated'
}

export enum NotificationUserTypeEnum {
  USER = 'user',
  AUTOMATION = 'automation'
}

export enum SubmissionSubmissibleTypeEnum {
  /** @deprecated Use SubmissionSubmissibleTypeEnum.REPORT */
  WHISPER = 'whisper',
  REPORT = 'whisper',
  /** @deprecated Use SubmissionSubmissibleTypeEnum.REPORT_WORKFLOW */
  WHISPER_WORKFLOW = 'whisper-workflow',
  REPORT_WORKFLOW = 'whisper-workflow',
  PROJECT_WORKFLOW = 'project-workflow'
}

export enum UserReportActionActionEnum {
  SNOOZE = 'snooze'
}

export enum ReportActivityAttributeCreatorTypeEnum {
  USER = 'user',
  /** @deprecated Use ReportActivityAttributeCreatorTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer',
  AUTOMATION = 'automation'
}

export enum ReportActivityAttributeMemberTypeEnum {
  USER = 'user',
  USER_GROUP = 'user-group'
}

export enum ReportActivityCategoryEnum {
  ACTIVITY_AUTOMATION_TRIGGERED = 'ACTIVITY_AUTOMATION_TRIGGERED',
  INFORMANT_REPORT_DOWNLOAD_SUCCESS = 'INFORMANT_REPORT_DOWNLOAD_SUCCESS',
  INFORMANT_REPORT_DOWNLOAD_ERROR = 'INFORMANT_REPORT_DOWNLOAD_ERROR',
  INFORMANT_REPORT_REDACTION_SCHEDULED = 'INFORMANT_REPORT_REDACTION_SCHEDULED',
  INFORMANT_REPORT_REDACTION_DUE_IN_ONE_WEEK = 'INFORMANT_REPORT_REDACTION_DUE_IN_ONE_WEEK',
  INFORMANT_REPORT_REDACTION_EXECUTED = 'INFORMANT_REPORT_REDACTION_EXECUTED',
  INFORMANT_REPORT_REDACTION_CANCELED = 'INFORMANT_REPORT_REDACTION_CANCELED',
  INFORMANT_REPORT_HAS_BEEN_CLOSED = 'INFORMANT_REPORT_HAS_BEEN_CLOSED',
  INFORMANT_REPORT_HAS_BEEN_REOPENED = 'INFORMANT_REPORT_HAS_BEEN_REOPENED',
  CONVERSATION_FILE_UPLOADED = 'CONVERSATION_FILE_UPLOADED',
  CONVERSATION_FILE_DELETED = 'CONVERSATION_FILE_DELETED',
  ACTIVITY_TAG_ADDED = 'ACTIVITY_TAG_ADDED',
  ACTIVITY_TAG_REMOVED = 'ACTIVITY_TAG_REMOVED',
  ACTIVITY_STATUS_CHANGED = 'ACTIVITY_STATUS_CHANGED',
  ACTIVITY_REPORT_ARCHIVED = 'ACTIVITY_REPORT_ARCHIVED',
  ACTIVITY_REPORT_UNARCHIVED = 'ACTIVITY_REPORT_UNARCHIVED',
  ACTIVITY_REPORT_LOCKED = 'ACTIVITY_REPORT_LOCKED',
  ACTIVITY_REPORT_UNLOCKED = 'ACTIVITY_REPORT_UNLOCKED',
  ACTIVITY_WORKFLOW_ASSIGNED = 'ACTIVITY_WORKFLOW_ASSIGNED',
  ACTIVITY_WORKFLOW_DEACTIVATED = 'ACTIVITY_WORKFLOW_DEACTIVATED',
  ACTIVITY_MEMBER_ADDED = 'ACTIVITY_MEMBER_ADDED',
  ACTIVITY_MEMBER_REMOVED = 'ACTIVITY_MEMBER_REMOVED',
  ACTIVITY_OWNER_CHANGED = 'ACTIVITY_OWNER_CHANGED',
  ACTIVITY_PROJECT_ATTACHED_TO_REPORT = 'ACTIVITY_PROJECT_ATTACHED_TO_REPORT',
  ACTIVITY_PROJECT_DETACHED_TO_REPORT = 'ACTIVITY_PROJECT_DETACHED_TO_REPORT',
  ACTIVITY_NEW_REPORT_VERSION = 'ACTIVITY_NEW_REPORT_VERSION',
  ACTIVITY_PARTICIPANT_ADDED = 'ACTIVITY_PARTICIPANT_ADDED',
  ACTIVITY_PARTICIPANT_REMOVED = 'ACTIVITY_PARTICIPANT_REMOVED',
  ACTIVITY_USER_ACTION_ACTIVATED = 'ACTIVITY_USER_ACTION_ACTIVATED',
  ACTIVITY_USER_ACTION_UPDATED = 'ACTIVITY_USER_ACTION_UPDATED',
  ACTIVITY_USER_ACTION_REMOVED = 'ACTIVITY_USER_ACTION_REMOVED',
  ACTIVITY_USER_ACTION_DEACTIVATED = 'ACTIVITY_USER_ACTION_DEACTIVATED'
}

export enum ReportMembershipMemberableTypeEnum {
  /** @deprecated Use ReportMembershipMemberableTypeEnum.REPORT */
  WHISPER = 'whisper',
  REPORT = 'whisper'
}

export enum ReportMembershipMembershipableTypeEnum {
  USER = 'user',
  USER_GROUP = 'user-group',
  EXTERNAL_USER = 'external-user'
}

export enum ReportMembershipSourceTypeEnum {
  USER = 'user',
  AUTOMATION = 'automation'
}

export enum ReportMembershipTypeTypeEnum {
  OWNER = 'owner',
  USERS = 'users',
  USER_GROUP_USERS = 'user_group_users',
  EXTERNAL_USERS = 'external_users'
}

export enum InformantReportCreatedByTypeEnum {
  USER = 'user',
  /** @deprecated Use InformantReportCreatedByTypeEnum.INFORMANT */
  WHISPERER = 'whisperer',
  INFORMANT = 'whisperer'
}
