import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import { ExternalUserReportSimple } from './../../overrides/model/ExternalUserReportSimple.js';
import { UserRoleSimple } from './../../overrides/model/UserRoleSimple.js';
import { UserAbility } from './../../overrides/model/UserAbility.js';
import type { Constructor } from '@whispli/types';

export class __ExternalUser extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  /**
   * @deprecated For backwards-compatibility only. Use firstName
   */
  @Expose()
  @Type(() => String)
  private readonly first_name!: string;

  public get firstName() {
    return this.first_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use lastName
   */
  @Expose()
  @Type(() => String)
  private readonly last_name!: string;

  public get lastName() {
    return this.last_name
  }

  @Expose()
  @Type(() => Object)
  public readonly email!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use externalUserReportsCount
   */
  @Expose()
  @Type(() => Number)
  private readonly external_user_whispers_count?: number;

  public get externalUserReportsCount() {
    return this.external_user_whispers_count
  }

  /**
   * @deprecated For backwards-compatibility only. Use externalUserReports
   */
  @Type(() => ExternalUserReportSimple)
  @Expose()
  private readonly external_user_whispers?: (ExternalUserReportSimple)[];

  public get externalUserReports() {
    return this.external_user_whispers
  }

  @Type(() => UserRoleSimple)
  @Expose()
  public readonly roles?: (UserRoleSimple)[];
  @Type(() => UserAbility)
  @Expose()
  public readonly abilities?: (UserAbility)[];
  /**
   * @deprecated For backwards-compatibility only. Use forbiddenAbilities
   */
  @Type(() => UserAbility)
  @Expose()
  private readonly forbidden_abilities?: (UserAbility)[];

  public get forbiddenAbilities() {
    return this.forbidden_abilities
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => Object)
  private readonly created_at!: string | null;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly updated_at!: string | null;

  public get updatedAt() {
    return this.updated_at
  }
}

interface OptionalAlias {
  externalUserReportsCount?: number | undefined;
  externalUserReports?: import("/builds/whispli/tenant-api-v2/whispli/packages/client/src/tenant/openapi/client/model/ExternalUserReportSimple").ExternalUserReportSimple[] | undefined;
  forbiddenAbilities?: import("/builds/whispli/tenant-api-v2/whispli/packages/client/src/tenant/openapi/overrides/model/UserAbility").UserAbility[] | undefined;
}
export type ExternalUser = Omit<__ExternalUser, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'first_name' | 'last_name' | 'external_user_whispers_count' | 'external_user_whispers' | 'forbidden_abilities' | 'created_at' | 'updated_at' | 'externalUserReportsCount' | 'externalUserReports' | 'forbiddenAbilities'> & OptionalAlias
export const ExternalUser = __ExternalUser as any as Constructor<ExternalUser>
