import { JSON_API_MIME_TYPE } from '@whispli/client/jsonapi'
import type { AxiosResponse } from 'axios'

export const getDataFromResponse = (
  response: AxiosResponse | { data: any, url: string, headers: Response['headers'] }
): any => {
  if (typeof response.data === 'string') {
    return response.data
  }

  // Handle paginated/sorted response
  if (response.data && ('page' in response.data || 'sort' in response.data)) {
    return response.data
  } else if (
    // Handle malformed Response data
    !response.data?.data
      // Handle JSON API response
      || (
        response.headers instanceof Headers
          ? response.headers.get('content-type')
          : response.headers?.['content-type']
      ) === JSON_API_MIME_TYPE
  ) {
    return response.data
  }

  return response.data.data
}
