import { withNode } from '@whispli/client/tenant/openapi/overrides/mixins/withNode'
import { withLocale } from '@whispli/client/tenant/openapi/overrides/mixins/withLocale'
import type {
  FilterSort, Item, Node, PaginationPage, Collection,
} from '@whispli/client/tenant/openapi/types'

/**
 * Tenant API response body deserializers.
 * Implements the VuexORM Model interface to facilitate migration from Vuex.
 */
export class ResponseBody extends withLocale(withNode(class {})) {
  // declare data: Item | Collection
  // declare page?: PaginationPage
  // declare sort?: FilterSort

  /**
   * The canonical cache key representing the node.
   * A composite key ensures the cache entry is unique by type, id and locale code.
   * @todo `page` and `sort` paramaters should be added to the query key
   * @example
   *   const data = {
   *     data: {
   *       id: '9f74adba-065f-11ea-8f0c-0242ac110002',
   *     },
   *   }
   *   const node = new ReportSimple(data)
   *   node.__locale = 'fr'
   *   const key = node.__key // 'ReportSimple:9f74adba-065f-11ea-8f0c-0242ac110002:fr'
   */
  get __key(): string {
    return [
      this.__typename,
      'id' in this && this.id,
      this.__locale,
    ].filter(Boolean).join(':')
  }

  /**
   * Object equality is predicated on `__key` only.
   * This maximizes reuse of mutation response bodies. Normalization of response data should be avoided.
   * Mutation callbacks `onSuccess` should not need to delve deeper than `response.data`
   * to determine which query cache entries to replace.
   */
  isEqual(obj: Node) {
    return this.__key === obj.__key
  }
}
