var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { USER_TYPE_ENUM } from '@whispli/client/tenant/constants';
import { User as Base } from '@whispli/client/tenant/openapi/client/model/User';
import { Expose } from 'class-transformer';
import { withVuexORM } from '@whispli/client/tenant/openapi/overrides/mixins/withVuexORM';
/**
 * Must implement VuexORM interface to support usage in core-web legacy.
 */
export class User extends withVuexORM(Base) {
    /** @todo Missing in OpenAPI annotation */
    locale = 'en';
    get fullName() {
        return [this.firstName, this.lastName]
            .filter(Boolean)
            .join(' ');
    }
    get avatar() {
        return (Array.isArray(this.media) ? this.media[0] : this.media) || null;
    }
    get avatarUrl() {
        return this.avatar?.url ?? null;
    }
    get type() {
        return USER_TYPE_ENUM.USER;
    }
    get isActive() {
        return !this.deletedAt;
    }
}
__decorate([
    Expose(),
    __metadata("design:type", String)
], User.prototype, "locale", void 0);
