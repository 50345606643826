import type { TENANT_API_TYPES_ENUM } from '@whispli/client/tenant/openapi/client/constants'
import type { Node } from '@whispli/client/tenant/openapi/types'
import type { Constructor } from '@whispli/types'

export const withNode = <T extends Constructor>(
  /** The super class to extend */
  SuperClass: T
) => class WithNode extends SuperClass implements Node {
  /**
   * Class names are derived from schema declared in OpenAPI document #/components/schemas.
   * Returns the constructor name.
   */
  __typename: TENANT_API_TYPES_ENUM = <TENANT_API_TYPES_ENUM> this.constructor.name
    .replace('__', '') // Remove private prefix
}
