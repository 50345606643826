import type { ERROR_STATUS_CODE_ENUM, ERROR_STATUS_CUSTOM_CODE_ENUM } from '@whispli/error'
import { GraphQLError } from 'graphql'

export class ResponseError extends Error {
  public readonly response!: Response & {
    data?: {
      errors?: string[]
      message?: string
      code?: ERROR_STATUS_CODE_ENUM
    }
  } | null

  constructor(
    public message: string = 'Failed to fetch',
    options: ErrorOptions & { response: Response | null }
  ) {
    super(message, options)
    this.response = options?.response ?? null
  }
}

interface SearchErrorData {
  terms?: string[]
}

export class SearchError extends Error {
  constructor(
    public status: ERROR_STATUS_CODE_ENUM,
    private data: SearchErrorData = Object.create(null)
  ) {
    super('SearchError')
  }

  get terms() {
    return (this.data?.terms ?? []).join(', ')
  }

  get termsCount() {
    return this.data?.terms?.length ?? 0
  }
}

export const isError = (error: unknown): error is Error =>
  !!(error && error instanceof Error)

export const isGraphQLError = (error: unknown): error is GraphQLError =>
  !!(error && error instanceof GraphQLError)

/**
 * @note avoid import from deprecated apollo-client
 * @see https://whispli-fraudsec.sentry.io/issues/4953306718/?project=1840865&query=firstRelease%3A18ce176a+is%3Aunresolved&referrer=issue-stream&sort=freq&statsPeriod=7d&stream_index=0
 */
export const isApolloError = (err: unknown): err is Error & { graphQLErrors?: GraphQLError[] } =>
  !!(err && err instanceof Error && 'graphQLErrors' in err)

export const isResponseError = (error: unknown): error is ResponseError =>
  !!(error && error instanceof ResponseError)

export const getResponse = (error: unknown): ResponseError['response'] | null => isResponseError(error)
  ? error.response
  : isError(error) && isApolloError(error)
    ? error.graphQLErrors?.[0]?.extensions?.response ?? null
    : isGraphQLError(error)
      ? error.extensions?.response ?? null
      : null

export const getStatus = (error: unknown): ERROR_STATUS_CODE_ENUM | null =>
  getResponse(error)?.status || null

/**
 * @note Error response contains trace when Tenant API `$APP_DEBUG` enabled
 * @see https://gitlab.com/whispli/tenant-api-v2/whispli-api/blob/d914e85ec6d2161542199c86562de4fce438467a/.env.docker#L4
 */
export const isErrorCodeEqual = async (
  response: ResponseError['response'] | null,
  code: ERROR_STATUS_CUSTOM_CODE_ENUM,
) => (await getCodeFromResponseBody(response)) === code

/**
 * @note Error response contains trace when Tenant API `$APP_DEBUG` enabled
 * @see https://gitlab.com/whispli/tenant-api-v2/whispli-api/blob/d914e85ec6d2161542199c86562de4fce438467a/.env.docker#L4
 */
export const getCodeFromResponseBody = async (
  response: ResponseError['response'] | null,
): Promise<ERROR_STATUS_CUSTOM_CODE_ENUM | null> => {
  try {
    if (!response || response.bodyUsed) {
      return null
    }

    const json = await response.json()

    return json?.data?.code ?? json?.code ?? null
  } catch (err) {
    return null
  }
}

