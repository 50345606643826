import Cookies from 'js-cookie'

export const getExp = (jwt: string): number => {
  try {
    const exp = parseInt(JSON.parse(atob(jwt.split('.')[1])).exp)
    return exp ? exp * 1000 : 0
  } catch (err) {
    return 0
  }
}

export const verifyExp = (jwt: string): boolean => getExp(jwt) > Date.now()

export const getTokenFromCookie = (cookie: string): string | null => {
  const jwt = Cookies.get(cookie)
  return jwt && verifyExp(jwt) ? jwt : null
}

const timers = Object.create(null)

export const getToken = async (
  request: RequestInfo | Pick<Request, 'method' | 'url'>,
  cookie: string | null,
  /** If true, get new token and set timer to refresh 30 secs before expiry */
  refresh: boolean = false,
  options?: Cookies.CookieAttributes,
): Promise<string | null | never> => {
  const url = typeof request === 'object' ? request.url : request
  const method = typeof request === 'object' && request.method || 'POST'

  if (!url) {
    return null
  }

  if (!cookie) {
    const data = await fetchToken(url, { method })
    return data?.jwt ?? null
  }

  let jwt: string | null = null

  if (
    !refresh                                // If new token not required
      && timers[cookie]                     // and refresh timer set
      && (jwt = getTokenFromCookie(cookie)) // and token in cookie
  ) {
    return jwt!
  }

  const data = await fetchToken(url, { method })
  jwt = data?.jwt ?? null

  if (!jwt) {
    Cookies.remove(cookie, options)
    return null
  }

  const exp = data?.exp
    ? data.exp && data.exp < Date.now()
      ? data.exp * 1000 // Convert to ms
      : data.exp
    : getExp(jwt!)

  Cookies.set(cookie, jwt!, { ...options, expires: new Date(exp) })

  // Set timer to refresh token
  const ttl = exp - Date.now()
  timers[cookie] = setTimeout(
    () => getToken(request, cookie, true, options),
    Math.max(ttl - (30 * 1_000), 0)
  )

  return jwt!
}

export const fetchToken = async (
  request: RequestInfo,
  { headers, method = 'POST' }: RequestInit = {}
): Promise<{ jwt: string; exp: number } | null> => {
  try {
    const response = await fetch(request, {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      method: method || 'POST',
      mode: 'same-origin',
    })

    if (response?.status === 200) {
      const body = await response.json()

      return 'data' in body ? body.data : body
    }

    return null
  } catch (err) {
    return null
  }
}
