import { DEFAULT_LOCALE_CODE } from '@whispli/i18n/constants'
import { CACHED_BY_X_APP_TIMESTAMP, getXAppTimestamp } from './'
import { removeApiPrefix } from '@whispli/client/tenant/openapi/overrides/utils'
import { getAbsoluteURL } from '@whispli/client/utils'

const CACHE_PARAM = 't' as const

/**
 * Append cache param comprised of X-App-Timestamp and locale
 */
export function appendCacheParam(
  init: {
    url: string | URL
    baseURL: string | URL
    method?: string
    locale?: string | null
  },
): string {
  const url = getAbsoluteURL(init.url, init.baseURL)

  // Append cache param comprised of X-App-Timestamp and locale
  if (
    (!init.method || init.method?.toUpperCase() === 'GET')
      && CACHED_BY_X_APP_TIMESTAMP.has(removeApiPrefix(url.pathname.slice(1)))
      && !url.searchParams.has(CACHE_PARAM)
  ) {
    url.searchParams.append(
      CACHE_PARAM,
      encodeURIComponent([
        getXAppTimestamp(),
        init?.locale ?? DEFAULT_LOCALE_CODE,
      ].join())
    )
  }

  return url.toString()
}
