import { ERROR_STATUS_CODE_ENUM } from './constants'

export {
  ERROR_ENUM,
  ERROR_STATUS_CODE_ENUM,
  ERROR_STATUS_CUSTOM_CODE_ENUM,
  ERRORS,
  ERROR_STATUS_CODES,
  ERROR_MESSAGES,
} from  './constants'
export { AppError } from './app-error'
export * from './normalize'
export * from './types'

export class TokenError extends Error {
  constructor() {
    super('TokenError: Could not authenticate')
  }
}

export class ServiceUnavailableError extends Error {
  public code: ERROR_STATUS_CODE_ENUM = ERROR_STATUS_CODE_ENUM.SERVICE_UNAVAILABLE

  constructor(
    public message: string,
    public service: string,
  ) {
    super(`ServiceUnavailableError: Could not connect to ${service} service.`)
  }
}

if (import.meta.hot) {
  import.meta.hot.accept()
}
