import { DEFAULT_LOCALE_CODE } from '@whispli/i18n/constants'
import type { LocaleCode } from '@whispli/i18n/types'
import type { Constructor } from '@whispli/types'

const __locale = '__locale'

export const withLocale = <T extends Constructor>(
  /** The super class to extend */
  SuperClass: T
) => class WithLocale extends SuperClass {
  /** Set locale recursively */
  static setLocale(obj: any, value: LocaleCode = DEFAULT_LOCALE_CODE) {
    Object.keys(obj).forEach(key => {
      const property = obj[key]

      if (property instanceof WithLocale) {
        property[__locale] = value
        WithLocale.setLocale(property, value)
      }
    })
  }

  static isInstance(obj: any) {
    return __locale in obj
  }

  [__locale]: LocaleCode = DEFAULT_LOCALE_CODE
}
