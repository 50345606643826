import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import type { ExternalUserReportSimpleStatusEnum } from './../constants.js';
import type { Constructor } from '@whispli/types';

export class __ExternalUserReportSimple extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Type(() => String)
  @Expose()
  public readonly status!: ExternalUserReportSimpleStatusEnum;
  /**
   * @deprecated For backwards-compatibility only. Use reportId
   */
  @Expose()
  @Type(() => String)
  private readonly whisper_id?: string;

  public get reportId() {
    return this.whisper_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use reportLabel
   */
  @Expose()
  @Type(() => Object)
  private readonly whisper_label!: string | null;

  public get reportLabel() {
    return this.whisper_label
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => Object)
  private readonly created_at!: string | null;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use expiresAt
   */
  @Expose()
  @Type(() => Object)
  private readonly expires_at!: string | null;

  public get expiresAt() {
    return this.expires_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly updated_at!: string | null;

  public get updatedAt() {
    return this.updated_at
  }
}

interface OptionalAlias {
  reportId?: string | undefined;
}
export type ExternalUserReportSimple = Omit<__ExternalUserReportSimple, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'whisper_id' | 'whisper_label' | 'created_at' | 'expires_at' | 'updated_at' | 'reportId'> & OptionalAlias
export const ExternalUserReportSimple = __ExternalUserReportSimple as any as Constructor<ExternalUserReportSimple>
