import type {
  Ability, RequiredAbilities, RequiredAbility,
} from '@whispli/client/tenant/auth/types'
import type { AUTH_STRATEGY_ENUM } from '@whispli/client/tenant/auth/constants'
import { USER_ROLE_ENUM } from '@whispli/client/tenant/auth/constants'
import type { Route } from 'vue-router'
import type { Component, ComponentOptions } from 'vue'
import type Strategy from '@whispli/client/tenant/auth/strategies/strategy'
import { __debug__ } from '@whispli/utils/debug'
import type { User } from '@whispli/client/tenant/openapi/overrides/model/User'
import type { Membership } from '@whispli/client/tenant/openapi/overrides/model/Membership'
import type { UserAbility } from '@whispli/client/tenant/openapi/overrides/model/UserAbility'
import { MembershipMembershipableTypeEnum } from '@whispli/client/tenant/openapi/client/constants'
import type { UserGroup } from '@whispli/client/tenant/openapi/overrides/model/UserGroup'
import { MODEL_TYPE_ENUM } from '@whispli/client/tenant/constants'

export const hasAbility = (
  abilities: ReadonlyArray<Ability>,
  requiredAbility: RequiredAbility
): boolean => {
  return !!abilities
    .filter((e) => (!e.entityType || e.entityType === '*') || e.entityType === requiredAbility.entityType)
    .filter((e) => (!e.name || e.name === '*') || e.name === requiredAbility.name)
    .length
}

export const hasForbiddenAbility = (
  forbiddenAbilities: ReadonlyArray<Ability>,
  requiredAbilities: RequiredAbilities
): boolean =>
  requiredAbilities.some((e) => hasAbility(forbiddenAbilities, e))

export const isAdmin = (user?: Pick<User, 'abilities'> | null): boolean =>
  Array.isArray(user?.abilities) && user!.abilities.some(e => hasAdminAbilities(e))

export const isUnrestrictedAdmin = (user?: Pick<User, 'roles'> | null): boolean =>
  (user?.roles ?? [])[0]?.name === USER_ROLE_ENUM.ADMIN

export const isRestrictedAdmin = (user?: Pick<User, 'roles'> | null): boolean =>
  (user?.roles ?? [])[0]?.name === USER_ROLE_ENUM.ADMIN_RESTRICTED

export const isBusinessAdmin = (user?: Pick<User, 'roles'> | null): boolean =>
  !!(user?.roles ?? []).find((e) => e.name === USER_ROLE_ENUM.BUSINESS_ADMIN)

export const isMember = (user?: Pick<User, 'roles'> | null): boolean =>
  !!(user?.roles ?? []).find((e) => e.name === USER_ROLE_ENUM.MEMBER)

export const isConfigurator = (user?: Pick<User, 'roles'> | null): boolean =>
  !!(user?.roles ?? []).find((e) => e.name === USER_ROLE_ENUM.CONFIGURATOR)

export const isAuthorizedProject = ({ name, entityType }) =>
  name === 'access' && entityType === 'project'

export const isAuthorizedWhisper = ({ name, entityType }) =>
  name === 'access' && entityType === 'whisper'

export const hasAdminAbilities = ({ name, entityType }) =>
  name === '*' && entityType === '*'

export const hasMemberAbilities = (ability) =>
  hasAdminAbilities(ability) ||
    isAuthorizedProject(ability) ||
    isAuthorizedWhisper(ability)

/** @return true when currentUser `isMember` and membership revoked */
export const shouldDeleteMemberable = (
  user: Pick<User, 'id' | 'roles' | 'abilities'>,
  memberships: Membership[],
  memberableType: MODEL_TYPE_ENUM | string = MODEL_TYPE_ENUM.REPORT) => {
  if (isMember(user) &&
      user.abilities.some((a): boolean => {
        switch (memberableType) {
          case MODEL_TYPE_ENUM.REPORT:
            return isAuthorizedWhisper(a)
          case MODEL_TYPE_ENUM.PROJECT:
            return isAuthorizedProject(a)
          default:
            return false
        }
      })
  ) {
    /** @note allow members with access ability */
    return false
  }

  return isMember(user) && !memberships.some((a) => {
    if ((a.membershipableType || a.membershipable_type) === MembershipMembershipableTypeEnum.USER_GROUP) {
      const membershiable = <UserGroup>a.membershipable
      return membershiable.users?.some((b) => b.id === user.id)
    }

    return (a.membershipableId || a.membershipable_id) === user.id
  })
}

/**
 * A locked Report is only accessible when:
 * - User `isAdmin`
 * - User IS NOT "Business Admin"
 * - User DOES NOT have `forbidden_ability` `manage-locked-whisper` for `entityType` `whisper`
 *
 * @deprecated use `isAuthorized` with `ADMIN_LOCKED_WHISPER_PERMISSIONS`
 */
export const hasAccessToLockedWhisper = (
  user: Pick<User, 'abilities' | 'forbiddenAbilities'> | null
): boolean => {
  if (!user) {
    return false
  }

  if (!isAdmin(user)) {
    return false
  }

  if (!user.forbiddenAbilities?.length) {
    return true
  }

  return !user.forbiddenAbilities.filter(
    (ability) =>
      ability.name === 'manage-locked-whisper' &&
      ability.entityType === 'whisper'
  ).length
}

export const isAuthorized = (
  authStore: { user: User | null },
  requiredAbilities: RequiredAbilities = []
): boolean => {
  const user = authStore.user

  if (!user || !Array.isArray(requiredAbilities)) {
    return false
  }

  const abilities = user.abilities || []
  const forbiddenAbilities = user.forbiddenAbilities || []

  return requiredAbilities.reduce(
    (authorized, permission) => authorized && hasAbility(abilities, permission),
    !hasForbiddenAbility(forbiddenAbilities, requiredAbilities)
  )
}

export const isAnonymous = (routeOrComponentOptions?: Route['matched'][number] | Route): boolean =>
  routeOrComponentOptions?.meta?.auth?.anonymous ?? false

/**
 * Memoize resolved Strategy
 */
export const setStrategy = (
  to: Route,
  routeOrComponentOptions: Route | ComponentOptions<any> | Component | undefined,
  authStore,
) => {
  try {
    const resolvedStrategies = authStore.strategies ?? []
    // @ts-ignore
    const strategyId = resolveStrategyId(routeOrComponentOptions, resolvedStrategies, to)

    // If resolved strategy matches current strategy return it
    if (strategyId === authStore.strategyId) {
      return authStore.strategy
    }

    const strategy = resolvedStrategies.find((e) => strategyId === e.id)
    authStore.strategy = strategy

    return authStore.strategy
  } catch (err) {
    __debug__(err)

    if (import.meta.env.MODE === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        'Did you set `meta.auth.strategy` within your Layout or View component?'
      )
    }
  }
}

const fromOptions = (
  routeOrComponentOptions?: Route
): AUTH_STRATEGY_ENUM | null =>
  routeOrComponentOptions?.meta?.auth?.strategy ?? null

const fromPath = (strategies: Strategy[], path: string): AUTH_STRATEGY_ENUM | null =>
  (Array.isArray(strategies) ? strategies : []).find((e) => e.isRequired(path))?.id ?? null

/**
 * @return The default Strategy id (assumes first registered strategy is the default)
 */
export const defaultStrategy = (strategies: Strategy[]): Strategy | null =>
  Array.isArray(strategies) ? strategies[0] : null

export const resolveStrategyId = (
  routeOrComponentOptions: Route | undefined,
  strategies: Strategy[],
  to: Route
): AUTH_STRATEGY_ENUM | null => fromOptions(routeOrComponentOptions)
  || fromOptions(to)
  || fromPath(strategies, to.fullPath)
  || defaultStrategy(strategies)?.id
  || null

/**
 * @return The route namespace
 */
export const getNamespace = (
  path: string
): string =>
  ((typeof path === 'string' ? path : window.location.pathname) || '').split('/').shift() || ''

export const getMatchedComponents = (route?: Route): Route['matched'][number]['components'][string][] => {
  return Array.isArray(route?.matched)
    ? route!.matched.reduce((arr, matched) => {
      if (matched.components) {
        return [
          ...arr,
          ...Object.values(matched.components),
        ]
      }

      return arr
    }, [] as Route['matched'][number]['components'][string][])
    : []
}
