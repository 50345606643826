import { UserAbility as Base } from '@whispli/client/tenant/openapi/client/model/UserAbility'
import { Expose, Transform } from 'class-transformer'

export type EntityType =
  | '*'
  | 'audit'
  | 'automation'
  | 'channel'
  | 'custom-lookup'
  | 'invitation'
  | 'landing-page'
  | 'organization-setting'
  | 'project'
  | 'project-status'
  | 'retention-policy'
  | 'user-group'
  | 'whisper'
  | 'whisper-status'
  | 'workflow'

export class UserAbility extends Base {
  @Expose()
  @Transform(({ obj }) => obj.entity_type)
  readonly entityType: EntityType | string = '*'
}
