import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import { UserSimple } from './../../overrides/model/UserSimple.js';
import { ReportSimple } from './../../overrides/model/ReportSimple.js';
import { ProjectSimple } from './../../overrides/model/ProjectSimple.js';
import type { Constructor } from '@whispli/types';

export class __UserGroup extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  @Type(() => UserSimple)
  @Expose()
  public readonly users!: (UserSimple)[];
  /**
   * @deprecated For backwards-compatibility only. Use reports
   */
  @Type(() => ReportSimple)
  @Expose()
  private readonly whispers!: (ReportSimple)[];

  public get reports() {
    return this.whispers
  }

  @Type(() => ProjectSimple)
  @Expose()
  public readonly projects!: (ProjectSimple)[];
  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }
}
export type UserGroup = Omit<__UserGroup, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'whispers' | 'created_at' | 'updated_at'>
export const UserGroup = __UserGroup as any as Constructor<UserGroup>
