import { USER_TYPE_ENUM } from '@whispli/client/tenant/constants'
import { UserSimple as Base } from '@whispli/client/tenant/openapi/client/model/UserSimple'
import { Expose } from 'class-transformer'
import { withVuexORM } from '@whispli/client/tenant/openapi/overrides/mixins/withVuexORM'

/**
 * Must implement VuexORM interface to support usage in core-web legacy.
 */
export class UserSimple extends withVuexORM(Base) {
  /** @todo Missing in OpenAPI annotation */
  @Expose()
  readonly locale: string = 'en'

  public get fullName() {
    return [ this.firstName, this.lastName ]
      .filter(Boolean)
      .join(' ')
  }

  public get avatarUrl(): string | null {
    return this.avatar && typeof this.avatar === 'object' && this.avatar.url || null
  }

  get type(): USER_TYPE_ENUM {
    return USER_TYPE_ENUM.USER
  }

  get isActive() {
    return !this.deletedAt
  }
}
